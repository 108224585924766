import { Modal, Typography } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { CombinedTableInsight } from "components/organisms/PaginatedTable/types";
import { FEED_TYPES, INSIGHT_VIEWS, LEGACY_FEED_TYPES } from "constants/feedTypes";
import AllFeedbackImage from "images/all_feedback.png";
import { FeedbackWrite } from "reactQuery/hooks/apiTypes";
import { useGetAllInsights } from "reactQuery/hooks/pages/useAllInsights";
import { useGetIndicatorFeed } from "reactQuery/hooks/pages/useIndicatorFeed";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetSingleReport } from "reactQuery/hooks/pages/useSingleReport";
import { useGetOpportunity } from "reactQuery/hooks/useOpportunity";
import { usePostFeedback } from "reactQuery/hooks/useOpportunityFeedback";
import { useGetTableData } from "reactQuery/hooks/useTableData";
import { INSIGHT_HIDDEN_BY_USER, useUserEventsStore } from "stores/zustandStore";
import { isIntelligenceFeedsRoute } from "utils/redirect";

import { useStyles } from "./styles.js";
import AddToActionList from "../../atoms/AddToActionList";
import AddToCRM from "../../atoms/AddToCRM";
import AddToFutureList from "../../atoms/AddToFutureList";
import Bullseye from "../../atoms/Bullseye";
import Dislike from "../../atoms/Dislike";
import Hide from "../../atoms/Hide";
import Like from "../../atoms/Like";

const OpportunityFeedback = ({
    showAddToCRM,
    allowFeedback,
    opportunityId,
    teamId,
    viewType,
}: {
    showAddToCRM: boolean;
    allowFeedback: boolean;
    opportunityId?: number;
    teamId: number;
    viewType: string;
}) => {
    const styles = useStyles();
    const [retrieveFeedbackUsers, setRetrieveFeedbackUsers] = useState<boolean>(false);

    const userEventsStore = useUserEventsStore();
    const whoamiQuery = useGetWhoami();

    const usePostFeedbackQuery = usePostFeedback({
        viewType,
    });

    const singleReportQuery = useGetSingleReport({
        enabled: viewType === FEED_TYPES.SINGLE_REPORT,
    });

    const indicatorFeedQuery = useGetIndicatorFeed({
        enabled: viewType === FEED_TYPES.INDICATOR_FEED,
    });
    const allInsightsQuery = useGetAllInsights({
        enabled: viewType === FEED_TYPES.ALL_INSIGHTS,
    });
    const paginatedTableInsightsQuery = useGetTableData(
        viewType,
        !LEGACY_FEED_TYPES.includes(viewType) && INSIGHT_VIEWS.includes(viewType)
    );

    const insightsQuery =
        viewType === FEED_TYPES.SINGLE_REPORT
            ? singleReportQuery
            : viewType === FEED_TYPES.INDICATOR_FEED
            ? indicatorFeedQuery
            : viewType === FEED_TYPES.ALL_INSIGHTS
            ? allInsightsQuery
            : paginatedTableInsightsQuery;

    const insights: CombinedTableInsight[] = insightsQuery?.data?.results ?? [];
    const feedData = insights.find(
        (insight) => insight.opportunity_id === opportunityId
    );
    const { search } = useLocation();
    const indicatorGroupId = new URLSearchParams(search).get("indicator_group_id");

    const getOpportunity = useGetOpportunity({
        opportunityId,
        indicatorGroupId: indicatorGroupId ? parseInt(indicatorGroupId) : null,
        teamId,
        enabled: ["indicatorOpportunity", "reportOpportunity"].includes(viewType)
            ? true
            : false,
    });

    const opportunityData = getOpportunity.data;

    const currentFeedback: any = ["indicatorOpportunity", "reportOpportunity"].includes(
        viewType
    )
        ? opportunityData?.feedback || {}
        : feedData?.feedback || {};

    const handleFeedbackClicked = (event, reaction) => {
        if (allowFeedback && (getOpportunity?.isSuccess || insightsQuery?.isSuccess)) {
            let postBody = {
                checkbox_status: event.target.checked || false,
                opportunity: opportunityId,
                reaction,
                team: teamId,
            };

            if (reaction === "add_to_future_list") {
                postBody = {
                    ...postBody,
                    checkbox_status: !currentFeedback?.add_to_future_list,
                };
            }

            if (reaction === "hide") {
                postBody = {
                    ...postBody,
                    checkbox_status: !currentFeedback?.hide,
                };

                if (
                    !userEventsStore.getEventForUser({
                        userId: whoamiQuery.data?.user?.id,
                        eventName: INSIGHT_HIDDEN_BY_USER,
                    })
                ) {
                    Modal.warning({
                        title: "Hidden insights can be accessed via All Feedback",
                        centered: true,
                        content: (
                            <div>
                                <Typography.Paragraph>
                                    In order to show you more relevant insights, your
                                    hidden insights will appear only in the All Feedback
                                    "Hide" reaction view.
                                </Typography.Paragraph>
                                <img style={{ width: "100%" }} src={AllFeedbackImage} />
                            </div>
                        ),
                        onOk: () => {
                            userEventsStore.setEventForUser({
                                userId: whoamiQuery.data?.user?.id,
                                eventName: INSIGHT_HIDDEN_BY_USER,
                            });
                        },
                    });
                }
            }

            postBody =
                event.target.type === "checkbox"
                    ? { ...postBody, checkbox_status: event.target.checked }
                    : postBody;

            usePostFeedbackQuery.mutate(postBody as FeedbackWrite);
        }
    };

    const showHideReaction = isIntelligenceFeedsRoute();

    return (
        <>
            <div className={styles.rightContainer}>
                {showAddToCRM ? (
                    <div className={styles.itemContainer}>
                        <AddToCRM
                            currentFeedback={currentFeedback}
                            allowFeedback={allowFeedback}
                            handleFeedbackClicked={handleFeedbackClicked}
                        />
                    </div>
                ) : null}
                <div className={styles.itemContainer}>
                    <Like
                        teamId={teamId}
                        opportunityId={opportunityId}
                        retrieveFeedbackUsers={retrieveFeedbackUsers}
                        setRetrieveFeedbackUsers={setRetrieveFeedbackUsers}
                        currentFeedback={currentFeedback}
                        allowFeedback={allowFeedback}
                        handleFeedbackClicked={handleFeedbackClicked}
                    />
                </div>
                <div className={styles.itemContainer}>
                    <Dislike
                        teamId={teamId}
                        opportunityId={opportunityId}
                        retrieveFeedbackUsers={retrieveFeedbackUsers}
                        setRetrieveFeedbackUsers={setRetrieveFeedbackUsers}
                        currentFeedback={currentFeedback}
                        allowFeedback={allowFeedback}
                        handleFeedbackClicked={handleFeedbackClicked}
                    />
                </div>
                <div className={styles.itemContainer}>
                    <Bullseye
                        teamId={teamId}
                        opportunityId={opportunityId}
                        retrieveFeedbackUsers={retrieveFeedbackUsers}
                        setRetrieveFeedbackUsers={setRetrieveFeedbackUsers}
                        currentFeedback={currentFeedback}
                        allowFeedback={allowFeedback}
                        handleFeedbackClicked={handleFeedbackClicked}
                    />
                </div>
                <div className={styles.itemContainer}>
                    <AddToFutureList
                        currentFeedback={currentFeedback}
                        allowFeedback={allowFeedback}
                        handleFeedbackClicked={handleFeedbackClicked}
                    />
                </div>
                <div className={styles.itemContainer}>
                    <AddToActionList
                        currentFeedback={currentFeedback}
                        allowFeedback={allowFeedback}
                        handleFeedbackClicked={handleFeedbackClicked}
                    />
                </div>
                {showHideReaction ? (
                    <div>
                        <Hide
                            currentFeedback={currentFeedback}
                            allowFeedback={allowFeedback}
                            handleFeedbackClicked={handleFeedbackClicked}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default OpportunityFeedback;
