import { DownOutlined, ShareAltOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";

import StyledButton from "components/atoms/StyledButton";
import {
    SYNC_TO_CRM_ENABLED_TIER_NAME,
    useGetIsTierEnabledForTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";

const getMenuItems = (selectedCount: number, teamId: number) => {
    const items: MenuProps["items"] = [
        {
            label: selectedCount > 1 ? "Share Opportunities" : "Share Opportunity",
            key: "share_opportunities",
            icon: <ShareAltOutlined />,
        },
    ];

    const syncOpportunitiesEnabled = useGetIsTierEnabledForTeam({
        teamId: teamId,
        tierName: SYNC_TO_CRM_ENABLED_TIER_NAME,
    });

    if (syncOpportunitiesEnabled)
        items.push({
            label:
                selectedCount > 1
                    ? "Sync Opportunities to CRM"
                    : "Sync Opportunity to CRM",
            key: "sync_opportunities_crm",
            icon: <SyncOutlined />,
        });

    return items;
};

export interface SelectedInsightsButtonProps {
    selectedCount?: number;
    disabled?: boolean;
    shareOpportunitiesOnClick: () => void;
    syncOpportunitiesToCRMOnClick: () => void;
    MUIButtonStyle?: boolean;
    teamId: number;
}

export const SelectedInsightsButton = ({
    selectedCount,
    disabled = false,
    shareOpportunitiesOnClick,
    syncOpportunitiesToCRMOnClick,
    MUIButtonStyle = false,
    teamId,
}: SelectedInsightsButtonProps) => {
    const handleMenuClick: MenuProps["onClick"] = (event) => {
        if (event.key === "share_opportunities") {
            shareOpportunitiesOnClick();
        } else if (event.key === "sync_opportunities_crm") {
            syncOpportunitiesToCRMOnClick();
        }
    };

    const items = getMenuItems(selectedCount, teamId);

    return (
        <div style={{ float: "right", marginLeft: 5 }}>
            <Dropdown
                menu={{
                    items,
                    onClick: handleMenuClick,
                }}
                disabled={disabled}
            >
                {MUIButtonStyle ? (
                    <StyledButton variant="page-action" icon={<ShareAltOutlined />}>
                        Actions
                    </StyledButton>
                ) : (
                    <Button disabled={disabled}>
                        <Space>
                            {selectedCount > 1
                                ? `${selectedCount} Insights Selected`
                                : selectedCount === 1
                                ? `${selectedCount} Insight Selected`
                                : "Bulk Actions"}
                            <DownOutlined />
                        </Space>
                    </Button>
                )}
            </Dropdown>
        </div>
    );
};
