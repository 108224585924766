import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";

import ModalButton from "components/molecules/ModalButton";

interface ModalStylesProps {
    width?: number | null;
}

const modalStyles = makeStyles<Theme, ModalStylesProps>((theme) => ({
    dialogActions: {
        padding: theme.spacing(0, 4, 4, 4),
        justifyContent: "flex-start",
        "& button:last-child": {
            marginLeft: "auto",
        },
    },
    dialogContent: (props) => ({
        padding: theme.spacing(4),
        width: props.width ?? "auto",
    }),
    dialogTitle: {
        padding: theme.spacing(3, 4, 0, 4),
        fontSize: "24px",
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    moreButton: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(8),
    },
    row: {
        display: "flex",
        justifyContent: "center",
    },
}));

export const testIds = {
    actions: "actions",
};

export const secondaryButtonTestId = "secondary";
export const submitButtonTestId = "submit";

const MoreActionsMenu = ({ extraActions }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = modalStyles({ width: null });
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className={classes.moreButton}
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
            >
                {extraActions &&
                    extraActions.map((action) => (
                        <MenuItem
                            disabled={action.isDisabled}
                            key={action.label}
                            onClick={(e) => {
                                action.onSelect();
                                handleMenuClose();
                            }}
                        >
                            {action.label}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export const Modal = ({
    actions,
    children,
    extraActions,
    isVisible,
    handleClose,
    title,
    width,
}) => {
    /* A basic modal */

    const classes = modalStyles({ width: width });
    const hasCustomWidth = width !== 600;
    if (!isVisible) {
        return null;
    }

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            fullWidth={hasCustomWidth}
            maxWidth={hasCustomWidth ? "md" : false}
        >
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Typography variant="h5" style={{ display: "inline-block" }}>
                    {title}
                </Typography>
                {extraActions && <MoreActionsMenu extraActions={extraActions} />}
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div className={classes.row}>
                <DialogContent className={classes.dialogContent}>
                    {children}
                </DialogContent>
            </div>
            {actions && (
                <DialogActions
                    className={classes.dialogActions}
                    data-testid={testIds.actions}
                >
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export const FormModal = ({
    children,
    extraActions = null,
    handleClose,
    handleSubmit,
    isSecondaryDisabled = false,
    isSubmitDisabled = false,
    isSubmitLoading = false,
    isVisible,
    hasSubmitButton = true,
    hasSecondaryButton = true,
    handleSecondaryClick = null,
    secondaryLabel = null,
    submitLabel = null,
    title,
    width = 600,
    isRemoveModeActive = false,
    isEditModeActive = false,
}) => {
    return (
        <Modal
            actions={
                <>
                    <ModalButton
                        color="primary"
                        disabled={isSecondaryDisabled}
                        isVisible={hasSecondaryButton}
                        colorScheme={
                            !isRemoveModeActive && isEditModeActive && !secondaryLabel
                                ? "danger"
                                : ""
                        }
                        onClick={handleSecondaryClick}
                        variant="outlined"
                        isLoading={false}
                        data-testid={secondaryButtonTestId}
                    >
                        {secondaryLabel
                            ? secondaryLabel
                            : !isRemoveModeActive && isEditModeActive
                            ? "Remove"
                            : "Cancel"}
                    </ModalButton>
                    <ModalButton
                        color="primary"
                        disabled={isSubmitDisabled}
                        isVisible={hasSubmitButton}
                        colorScheme={
                            isRemoveModeActive && isEditModeActive ? "danger" : ""
                        }
                        onClick={handleSubmit}
                        isLoading={isSubmitLoading}
                        variant="contained"
                        data-testid={submitButtonTestId}
                    >
                        {submitLabel
                            ? submitLabel
                            : isRemoveModeActive
                            ? "Remove"
                            : "Submit"}
                    </ModalButton>
                </>
            }
            isVisible={isVisible}
            extraActions={extraActions}
            handleClose={() => {
                if (isSubmitLoading) {
                    return;
                }
                handleClose();
            }}
            title={title}
            width={width}
        >
            {children}
        </Modal>
    );
};

export default Modal;
