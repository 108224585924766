import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import Link from "@material-ui/core/Link";
import { Button, Layout, Space, Typography, Grid, MenuProps, Dropdown } from "antd";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { CUSTOMER_TYPES } from "constants/customerTypes";
import { subscriptionsPathname } from "pages/Subscriptions";
import { ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import {
    useGetWhoami,
    useGetSingleTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";
import { logOut, useAuthLogout } from "reactQuery/hooks/userAuth/useLogout";

import Sidebar from "./Sidebar";

const { Header, Content } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const CollapseSidebarButton = ({
    collapsed,
    setCollapsed,
}: {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
}) => (
    <Button
        data-testid="collapse-sidebar-button"
        type="text"
        onClick={() => setCollapsed(!collapsed)}
    >
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            style: {
                fontSize: "24px",
            },
        })}
    </Button>
);

const HeaderBar = ({
    collapsed,
    setCollapsed,
    selectedTeamId,
}: {
    collapsed: boolean;
    setCollapsed: (collapsed: boolean) => void;
    selectedTeamId: number;
}) => {
    const { xs: xsBreakpoint } = useBreakpoint();
    const logOutMutation = useAuthLogout();
    const whoamiQuery = useGetWhoami();
    const singleTeamQuery = useGetSingleTeam(selectedTeamId);
    const [hasSubscriptions, setHasSubscriptions] = useState(false);

    let greeting: string, hasFirstName: boolean;
    if (whoamiQuery.isSuccess) {
        hasFirstName = !!whoamiQuery.data.user.first_name;
        greeting = `Hi ${
            hasFirstName
                ? whoamiQuery.data.user.first_name
                : whoamiQuery.data.user.username
        }`;
    }

    useEffect(() => {
        if (whoamiQuery?.data && singleTeamQuery?.data) {
            const selectedTeam = singleTeamQuery.data;
            const hasSPDProduct = selectedTeam.products?.find((product) =>
                [
                    ProductTypeEnum.INTELLIGENCE_FEEDS,
                    ProductTypeEnum.INTELLIGENCE_REPORTS,
                ].includes(product.product_type)
            );
            const isSPDCustomer = [
                CUSTOMER_TYPES.CUSTOMER,
                CUSTOMER_TYPES.PROSPECT_ENGAGED,
            ].includes(selectedTeam.status);

            setHasSubscriptions(hasSPDProduct && isSPDCustomer);
        }
    }, [whoamiQuery.isSuccess, singleTeamQuery.isSuccess, selectedTeamId]);

    const items: MenuProps["items"] = [
        {
            label: (
                <Link
                    to={`${subscriptionsPathname}?team_id=${selectedTeamId}`}
                    component={RouterLink}
                >
                    Subscriptions
                </Link>
            ),
            key: "subscriptions",
        },
    ];

    return (
        <Header
            style={{
                padding: "0 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
        >
            <CollapseSidebarButton collapsed={collapsed} setCollapsed={setCollapsed} />
            <Space size="large">
                {!xsBreakpoint ? (
                    <div style={{ gap: "6px", display: "flex", alignItems: "center" }}>
                        <Text data-testid="greeting">{greeting}</Text>
                        {whoamiQuery.isSuccess && hasSubscriptions ? (
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                placement="bottomRight"
                            >
                                <SettingOutlined data-testid="subscriptions-dropdown" />
                            </Dropdown>
                        ) : null}
                    </div>
                ) : undefined}
                <Button
                    onClick={() =>
                        logOut(logOutMutation, () => (window.location.href = "/"))
                    }
                >
                    Sign out
                </Button>
            </Space>
        </Header>
    );
};

const SidebarLayout = ({ content }) => {
    const [collapsed, setCollapsed] = useState(false);
    let { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const [selectedTeamId, setSelectedTeamId] = useState(teamId ?? undefined);

    return (
        <Layout style={{ height: "100vh" }}>
            <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                selectedTeamId={selectedTeamId}
                setSelectedTeamId={setSelectedTeamId}
            />
            <Layout>
                <HeaderBar
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selectedTeamId={selectedTeamId}
                />
                <Content
                    style={{
                        overflow: "scroll",
                    }}
                >
                    {content}
                </Content>
            </Layout>
        </Layout>
    );
};

export default SidebarLayout;
