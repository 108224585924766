import { useQueryClient } from "@tanstack/react-query";
import { Modal, Button, Typography, Row, List, Space } from "antd";

import StatusTag, { CRM_SYNC_COMPLETED } from "components/atoms/StatusTag";
import {
    CombinedTableInsight,
    CombinedTableInsightRecord,
    CombinedInsightsList,
} from "components/organisms/PaginatedTable/types";
import { OpportunityRead } from "reactQuery/hooks/apiTypes";
import {
    SyncToCRMWriteResponse,
    usePostSyncToCRM,
} from "reactQuery/hooks/useSyncToCRM";
import { opportunityKeys, tableDataKeys } from "reactQuery/keys";
import { useSnackStore } from "stores/zustandStore";
import { queryParamBuilder } from "utils/useManagedQueryParams";

const { Text } = Typography;

interface singleOpportunityContext {
    opportunityOwner: string;
    stateCode: string;
    indicatorValue: string;
    crmSyncStatus: string;
    opportunityId: number;
}

export interface SyncOpportunitiesToCRMModalProps {
    selectedRows?: CombinedTableInsightRecord[];
    setSelectedRows?: (_: CombinedTableInsightRecord[]) => void;
    visible: boolean;
    handleClose: () => void;
    singleOpportunityContext?: singleOpportunityContext;
    teamId: number;
    indicatorGroupId?: number;
    feedType?: string;
}

const SingleOpportunityContent = ({
    singleOpportunityContext,
}: {
    singleOpportunityContext?: singleOpportunityContext;
}) => {
    const opportunityText = (
        <List
            bordered
            dataSource={[singleOpportunityContext]}
            size="small"
            renderItem={(singleOpportunityContext) => {
                return (
                    <List.Item>
                        <Text data-testid="single-opp-context">
                            {singleOpportunityContext.opportunityOwner},{" "}
                            {singleOpportunityContext.stateCode}
                            {singleOpportunityContext.indicatorValue
                                ? ` - ${singleOpportunityContext.indicatorValue}`
                                : null}
                        </Text>

                        {singleOpportunityContext.crmSyncStatus ===
                        CRM_SYNC_COMPLETED ? (
                            <StatusTag
                                status={singleOpportunityContext.crmSyncStatus}
                            />
                        ) : null}
                    </List.Item>
                );
            }}
        />
    );

    const unsyncedContent = (
        <Text>Please confirm your request to sync the following opportunity:</Text>
    );

    const syncedContent = (
        <Text>
            The following opportunity has previously been synced to your CRM and will
            not be re-synced:
        </Text>
    );

    return (
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
            {singleOpportunityContext.crmSyncStatus === CRM_SYNC_COMPLETED
                ? syncedContent
                : unsyncedContent}
            {opportunityText}
        </Space>
    );
};

const MultipleOpportunitiesContent = ({
    unsyncedInsights,
    syncedInsights,
}: {
    unsyncedInsights: CombinedTableInsightRecord[];
    syncedInsights: CombinedTableInsightRecord[];
}) => {
    const unsycedContent = (
        <>
            <Text>
                Please confirm your request to sync the following{" "}
                {unsyncedInsights.length === 1 ? "opportunity" : "opportunities"}:
            </Text>

            <List
                bordered
                dataSource={unsyncedInsights}
                size="small"
                renderItem={(row) => {
                    const indicatorValue = row.indicator_groups?.[0]?.indicator?.value;

                    return (
                        <List.Item>
                            <Text data-testid={`list-text-item-${row.key}`}>{`${
                                row.opportunity_owner?.legal_name
                            }, ${row.opportunity_owner?.state_code}${
                                indicatorValue ? ` - ${indicatorValue}` : ""
                            }`}</Text>
                        </List.Item>
                    );
                }}
            />
        </>
    );
    const syncedContent = (
        <>
            <Text>
                The following opportunities have been previously synced to your CRM and
                will not be resynced:
            </Text>

            <List
                bordered
                dataSource={syncedInsights}
                size="small"
                renderItem={(row) => {
                    const indicatorValue = row.indicator_groups?.[0]?.indicator?.value;

                    return (
                        <List.Item>
                            <Text>{`${row.opportunity_owner?.legal_name}, ${
                                row.opportunity_owner?.state_code
                            }${indicatorValue ? ` - ${indicatorValue}` : ""}`}</Text>
                            <StatusTag status={row.crm_sync_status} />
                        </List.Item>
                    );
                }}
            />
        </>
    );

    return (
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
            {unsyncedInsights.length > 0 ? unsycedContent : null}
            {syncedInsights.length > 0 ? syncedContent : null}
        </Space>
    );
};

export const updatePreviousData = (
    previousData: CombinedInsightsList,
    response: SyncToCRMWriteResponse
) => {
    if (!previousData?.results) return;

    const updatedData = { ...previousData };

    Object.keys(response).forEach((key) => {
        let rows = (updatedData.results as CombinedTableInsight[])?.filter(
            (result) => result.opportunity_id === Number(key)
        );

        // @ts-ignore
        rows.forEach((row) => (row.crm_sync_status = response[key]));
    });

    return updatedData;
};

export const SyncOpportunitiesToCRMModal = ({
    handleClose,
    selectedRows = [],
    setSelectedRows,
    visible,
    singleOpportunityContext,
    teamId,
    indicatorGroupId,
    feedType,
}: SyncOpportunitiesToCRMModalProps) => {
    const displaySuccessSnack = useSnackStore((state) => state.displaySuccessSnack);
    const displayErrorSnack = useSnackStore((state) => state.displayErrorSnack);

    const syncToCRMQuery = usePostSyncToCRM();
    const queryClient = useQueryClient();

    const onSuccess = (response: SyncToCRMWriteResponse) => {
        if (singleOpportunityContext) {
            const opportunityId = singleOpportunityContext.opportunityId;

            queryClient.setQueryData(
                opportunityKeys.single_opportunity(opportunityId),
                (previousData: OpportunityRead) => {
                    return {
                        ...previousData,
                        crm_sync_status: response[opportunityId],
                    };
                }
            );
        } else {
            const queryParams = queryParamBuilder({
                includeBaseParams: true,
                includeDefaultPagination: true,
            });
            const queryKey = tableDataKeys.createKey(feedType, queryParams);

            queryClient.setQueriesData(
                queryKey,
                (previousData: CombinedInsightsList | unknown) => {
                    const updatedData = updatePreviousData(previousData, response);
                    if (updatedData?.results) return updatedData;
                }
            );
            queryClient.invalidateQueries(
                tableDataKeys.createKey(feedType, queryParams)
            );
            setSelectedRows([]);
        }

        displaySuccessSnack({
            message: "Sync to CRM queued",
        });
        handleClose();
    };
    const onError = () => {
        displayErrorSnack({
            message: "Sync to CRM failed to queue",
        });
    };

    const syncedInsights = selectedRows.filter(
        (row) => row.crm_sync_status === CRM_SYNC_COMPLETED
    );
    const unsyncedInsights = selectedRows.filter(
        (row) => row.crm_sync_status !== CRM_SYNC_COMPLETED
    );

    const submitDisabled = singleOpportunityContext
        ? singleOpportunityContext.crmSyncStatus === CRM_SYNC_COMPLETED
        : !unsyncedInsights.length;

    const handleSubmit = () => {
        let opportunityIds = singleOpportunityContext
            ? [singleOpportunityContext.opportunityId]
            : [...new Set(unsyncedInsights.map((insight) => insight.opportunity_id))];
        syncToCRMQuery.mutate(
            { teamId, opportunityIds, indicatorGroupId },
            { onSuccess: onSuccess, onError: onError }
        );
    };

    return (
        <Modal
            title="Sync Opportunities to CRM"
            open={visible}
            onCancel={() => handleClose()}
            forceRender
            bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
            footer={
                <>
                    <Row justify="space-between">
                        <Button
                            key="cancel"
                            type="default"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>

                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => handleSubmit()}
                            disabled={submitDisabled}
                            loading={syncToCRMQuery.isLoading}
                        >
                            Submit
                        </Button>
                    </Row>
                </>
            }
        >
            {singleOpportunityContext ? (
                <SingleOpportunityContent
                    singleOpportunityContext={singleOpportunityContext}
                />
            ) : (
                <MultipleOpportunitiesContent
                    syncedInsights={syncedInsights}
                    unsyncedInsights={unsyncedInsights}
                />
            )}
        </Modal>
    );
};
