import { brandColors } from "constants/colors";
import { Indicator } from "reactQuery/hooks/apiTypes";

export const getColorForIndicator = (indicator: Indicator) => {
    let color: string;
    let industryOrCategory = indicator.industry ?? indicator.category;

    if (/storm/i.test(industryOrCategory)) {
        color = brandColors["darkBlue"];
    } else if (/waste|collection/i.test(industryOrCategory)) {
        color = brandColors["teal"];
    } else if (/drink|distribution/i.test(industryOrCategory)) {
        color = brandColors["lightBlue"];
    } else {
        let industryOrCategoryId = indicator.industry_id ?? indicator.category_id;
        color = anotherColor(industryOrCategoryId);
    }

    return color;
};

const anotherColor = (industryOrCategoryId: number) => {
    // all other indicator industries / categories are assigned
    // a consistent brand color that is not red or orange
    let permittedColorsObj = Object.keys(brandColors)
        .filter((color) => !["red", "orange"].includes(color))
        .reduce((obj, key) => {
            obj[key] = brandColors[key];
            return obj;
        }, {});
    let permittedColorsKeys = Object.keys(permittedColorsObj);

    let colorKey =
        permittedColorsKeys[industryOrCategoryId % permittedColorsKeys.length];

    let color = permittedColorsObj[colorKey];

    return color;
};
