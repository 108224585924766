import { useLocation } from "react-router-dom";

import GDSDashboardView from "components/cdv/GDSDashboardView";
import SidebarLayout from "components/organisms/SidebarLayout";
import { Product } from "reactQuery/hooks/apiTypes";

const Dashboard = ({ products }: { products: Product[] }) => {
    const { search } = useLocation();

    const teamId = Number(new URLSearchParams(search).get("team_id"));

    return (
        <SidebarLayout
            content={
                <GDSDashboardView
                    style={{ padding: "10px" }}
                    teamId={teamId}
                    productIds={products.map((product) => product.id)}
                />
            }
        />
    );
};

export default Dashboard;
