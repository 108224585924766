import { generatePath } from "react-router";

import { queryParamBuilder } from "utils/useManagedQueryParams";

interface pathBuilderProps {
    excludeQueryParams?: string[];
    id: string | number;
    includeAllQueryParams?: boolean;
    path: string;
    queryParam: string;
    setQueryParam?: boolean;
}

export const pathBuilder = ({
    excludeQueryParams,
    id,
    includeAllQueryParams = false,
    path,
    queryParam,
    setQueryParam = false,
}: pathBuilderProps) => {
    const queryParams = queryParamBuilder({
        excludeParams: excludeQueryParams || [queryParam],
        includeBaseParams: true,
        includeExistingParams: includeAllQueryParams,
        startChar: setQueryParam ? "&" : "?",
    });

    const fullPath = setQueryParam
        ? `${path}?${queryParam}=${id}${queryParams}`
        : `${generatePath(path, {
              [queryParam]: id,
          })}${queryParams}`;

    return fullPath;
};
