import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { useLocation } from "react-router-dom";

import Text from "components/atoms/Text";
import { OverviewCard } from "components/molecules/OverviewCard";
import SidebarLayout from "components/organisms/SidebarLayout";
import { useGetAllSeachPlans } from "reactQuery/hooks/useSearchPlansList";

export const subscriptionsPathname = "/subscriptions";

export const useStyles = makeStyles((theme) => ({
    subscriptionsBody: {
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: "25px",
    },
}));

export const Subscriptions = ({}) => {
    const styles = useStyles();
    let { search } = useLocation();
    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const subscriptionsQuery = useGetAllSeachPlans(teamId.toString());
    const subscriptionCards = subscriptionsQuery?.data?.results.map((subscription) => {
        const emailSubject = `${subscription.team_name} - ${subscription.product_name} `;

        const emailLink = `mailto:subscriptions@citylitics.com?subject=${encodeURIComponent(
            emailSubject
        )}`;

        return (
            <OverviewCard
                title={subscription.product_name}
                subtitle={
                    "Last Updated: " +
                    moment(subscription.last_updated).format("YYYY-MM-DD")
                }
                primaryActionText="View"
                primaryActionUrl={subscription.link}
                secondaryActionText="Request Update"
                secondaryActionUrl={emailLink}
            />
        );
    });
    const subscriptionsPage = (
        <div className={styles.subscriptionsBody}>
            <Text variant="pageHeader">Subscriptions</Text>
            <Grid container spacing={4} style={{ marginBottom: "50px" }}>
                {subscriptionsQuery?.isSuccess &&
                subscriptionsQuery?.data.results.length > 0 ? (
                    subscriptionCards
                ) : (
                    <Grid item>
                        <p>Subscriptions not found</p>
                    </Grid>
                )}
            </Grid>
        </div>
    );

    return <SidebarLayout content={subscriptionsPage} />;
};

export default Subscriptions;
