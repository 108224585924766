/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Actions {
    readonly id: number;
    /** @maxLength 100 */
    action_type?: string;
    /** @maxLength 100 */
    action_outcome?: string;
    readonly label: string;
}

/** Aggregated Feedback Read Serializer class for the Feedback model. */
export interface AggregateFeedbackRead {
    /** Total number of team likes for an opportunity */
    readonly total_likes: number;
    /** Total number of team dislikes for an opportunity */
    readonly total_dislikes: number;
    /** Total number of team bullseyes for an opportunity */
    readonly total_bullseye: number;
    /** Checkbox status for team of Add to CRM reaction */
    readonly add_to_crm: boolean;
    /** Checkbox status for team of Add to Action List reaction */
    readonly add_to_action_list: boolean;
    /** Checkbox status for team of Add to Future List reaction */
    readonly add_to_future_list: boolean;
    /** Active/inactive status for user of hide reaction */
    readonly hide: boolean;
    /** Active/inactive status for user of like reaction */
    readonly is_liked: boolean;
    /** Active/inactive status for user of dislike reaction */
    readonly is_disliked: boolean;
    /** Active/inactive status for user of bullseye reaction */
    readonly is_bullseyed: boolean;
    /** Active/inactive status for user of hide reaction */
    readonly is_hidden: boolean;
}

/** AllInsightsPageFiltersSerializer */
export interface AllInsightsPageFilters {
    readonly date_filter: string;
    readonly indicator_filter: string[];
    readonly opportunity_owner_filter: string[];
    readonly state_filter: string[];
    readonly status_filter: string[];
}

/** Serializer for Indicator instances. */
export interface AllInsightsPageIndicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
}

/** IndicatorGroup Serializer. */
export interface AllInsightsPageIndicatorGroups {
    readonly id: number;
    /** Serializer for Indicator instances. */
    indicator: AllInsightsPageIndicator;
    indicator_tags: AllInsightsPageIndicatorTags[];
    initiative_types: AllInsightsPageInitiativeTypes[];
}

/** Serializer for IndicatorTag instance. */
export interface AllInsightsPageIndicatorTags {
    /**
     * Indicator Tag
     * @maxLength 100
     */
    value: string;
}

/** Serializer for InitiativeType instance. */
export interface AllInsightsPageInitiativeTypes {
    /**
     * Initiative Type
     * @maxLength 100
     */
    value: string;
}

/** AllInsightsPageInsightsSerializer */
export interface AllInsightsPageInsights {
    readonly id: number;
    /** Report containing the insight */
    readonly report_id: number;
    readonly report_date: string;
    /** Project status within the project stage the insight is in */
    status?: Status54FEnum | NullEnum | null;
    readonly opportunity_owner: ShallowOwnerRead;
    indicator_groups: AllInsightsPageIndicatorGroups[];
    /** Relevant summary of the insight */
    summary?: string | null;
    /** Opportunity containing the insight */
    readonly opportunity_id: number;
    readonly opportunity_updated: boolean;
    /** List of Team Customer Tag Names */
    readonly team_customer_tags: string[];
    readonly feedback: AggregateFeedbackRead;
    readonly crm_sync_status: string;
}

export interface BaseSearch {
    query_text: string;
    /** @format date */
    from_date: string;
    /** @format date */
    to_date: string;
    indices: string;
}

export interface BaseTeamCustomerTag {
    readonly id: number;
    legacy?: boolean;
    /**
     * Customer Tag
     * @maxLength 100
     */
    name: string;
}

export interface BatchSearchRecord {
    /**
     * @min -2147483648
     * @max 2147483647
     */
    number_of_queries?: number;
    /** ID */
    readonly pk: number;
}

export enum BlankEnum {
    Value = "",
}

/** Serializer for Comment. */
export interface Comment {
    added_by: User;
    /** @format date-time */
    readonly date_created: string;
    /** @format date-time */
    readonly last_updated: string;
    text: string;
    updated_by: User;
}

export interface Company {
    readonly id: number;
    /** @maxLength 200 */
    name: string;
    /**
     * @format uri
     * @maxLength 200
     */
    url: string;
    segment?: number | null;
}

/** Response Serializer for Comparison model */
export interface ComparisonResponse {
    signed_viewer_url: string;
    identifier: string;
    /** @format date-time */
    expiry_time: string;
}

export enum ConstraintTypeEnum {
    Exclude = "Exclude",
    Include = "Include",
}

export interface Country {
    /** @maxLength 3 */
    code: string;
    /** @maxLength 200 */
    name: string;
}

/** Serializer for CountryConstraint. */
export interface CountryConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    countries: Country[];
}

/** Serializer for CountyConstraint. */
export interface CountyConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    demographics: DemographicInInsight[];
}

export enum CurrencyEnum {
    USD = "USD",
    CAD = "CAD",
}

/** Serializer for requesting a password reset e-mail. */
export interface CustomPasswordReset {
    /** @format email */
    email: string;
}

export interface DashboardLink {
    /**
     * Dashboard URL
     * @maxLength 1024
     */
    url?: string | null;
    product: Product;
}

/**
 * Serializer for reading of Demographic instances with more information.
 *
 * This serializer should only be used for read methods, not create/modify methods.
 *
 * The following many-to-many fields are currently excluded:
 *     - Postal codes
 *     - Phone area codes
 */
export interface DeepDemographicRead {
    readonly id: number;
    readonly name: string;
    readonly demographic_type: string;
    readonly country_code: string;
    readonly state_code: string;
    part_of: ShallowDemographicRead[];
    /**
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,5})?$
     */
    readonly latitude: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,5})?$
     */
    readonly longitude: string;
    readonly elevation: number;
    readonly population: number;
    readonly num_households: number;
    readonly median_income: number;
    readonly land_area: number;
    readonly water_area: number;
    /** @format date-time */
    readonly last_updated: string;
    readonly address: string;
    readonly zip: string;
    readonly county: string;
}

/**
 * Serializer class for IndicatorCategory instances. Linked indicators are
 * in the `indicators` list property
 */
export interface DeepIndicatorCategory {
    readonly id: number;
    /**
     * Category
     * @maxLength 100
     */
    value: string;
    indicators: IndicatorCategoryNestedIndicator[];
}

/** Deep Serializer class for the Insight model. */
export interface DeepInsight {
    readonly id: number;
    /** Serializer for the Opportunity model, to be nested within DeepInsightSerializer. */
    opportunity: OpportunityInInsight;
    /** Report containing the insight */
    report: number;
    readonly stage: string;
    readonly status: string;
    /** Internal delivery status for the insight */
    delivery_status?: DeliveryStatusEnum;
    demographics: DemographicInInsight[];
    drinking_water_systems: ShallowDrinkingWaterSystemRead[];
    facilities: ShallowWastewaterFacilityRead[];
    consultants: Company[];
    readonly report_date: string;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    readonly sources: string;
    readonly feedback: AggregateFeedbackRead;
    indicator_groups: IndicatorGroup[];
    /**
     * Custom date for the insight - this is intended to be used as a custom date to display to users to show when the insight was updated. This differs from the created_date which is automatically set by the system. This one can be changed by the user.
     * @format date
     */
    display_date?: string;
    /** Relevant summary of the insight */
    summary?: string | null;
    target_owners: Profile[];
    contacts: ShallowContactRead[];
    competitors: Company[];
    readonly insight_notes: string;
    readonly team_customer_tags: BaseTeamCustomerTag[];
    readonly spd_competitors: Company[];
    readonly spd_consultants: Company[];
}

/**
 * Serializer for reading of Report instances with more information.
 * This serializer should only be used for read methods, not create/modify methods.
 */
export interface DeepReportRead {
    readonly id: number;
    /**
     * Date the report was delivered
     * @format date
     */
    readonly date: string;
    /** Status of delivery for the report */
    readonly status: StatusC44Enum;
    /** Additional notes on the report */
    readonly notes: string;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    readonly insights: number[];
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    readonly next_report_id: number;
    readonly prev_report_id: number;
}

export enum DeliveryCadenceEnum {
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    Biannual = "Biannual",
}

export enum DeliveryStatusEnum {
    Wip = "wip",
    FreelancerSubmitted = "freelancer_submitted",
    ReviewRequired = "review_required",
    RevisionsNeeded = "revisions_needed",
    Ready = "ready",
}

/** Serializer for Demographic to be used in an insight, with just the essential fields. */
export interface DemographicInInsight {
    readonly id: number;
    /** @maxLength 200 */
    name: string;
    readonly demographic_type: string;
    /** @maxLength 2 */
    country_code: string;
    /** @maxLength 2 */
    state_code?: string | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    population?: number | null;
}

/** Response Serializer for EmailGenerationInput model for GET request */
export interface EmailGenerationInputPayload {
    /** User associated with the email generation input */
    user?: number | null;
    /** Contact intended for the AI email generation */
    contact?: number | null;
    case_study_description: string;
    products_services_and_expertise: string;
    primary_goal?: string | null;
    /** @maxLength 255 */
    call_to_action?: string | null;
    tone?: ToneEnum;
    saved?: boolean;
}

/** Response Serializer for EmailGenerationInput model for POST request */
export interface EmailGenerationInputPostResponse {
    readonly email_gen_input_object_id: number;
}

/** Request Serializer for EmailGenerationOutput model */
export interface EmailGenerationOutputPostRequest {
    /**
     * EmailGenerationInput
     * The inputs from the Email Generation Input Form used to generate these Outputs
     */
    readonly input_id: number | null;
    /** Team associated with the email generation output */
    readonly team_id: number | null;
    /** Opportunity associated with the email generation output */
    readonly opportunity_id: number | null;
}

/** Response Serializer for EmailGenerationOutput model */
export interface EmailGenerationOutputPostResponse {
    email_output_text?: string;
    /**
     * EmailGenerationInput
     * The inputs from the Email Generation Input Form used to generate these Outputs
     */
    readonly input_id: number | null;
    /**
     * Date and time the email output was generated
     * @format date-time
     */
    readonly date: string;
    /** Team associated with the email generation output */
    readonly team_id: number | null;
    /** Opportunity associated with the email generation output */
    readonly opportunity_id: number | null;
    readonly output_id: number;
    /**
     * EmailGenerationOutput
     * The output referenced that was used to regenerate new email_output_text
     */
    readonly regenerated_from_id: number | null;
    /**
     * EmailGenerationOutput
     * The output referenced where the 'Edit Input Fields' button was clicked to modify inputs and generate a new input object
     */
    readonly edited_from_id: number | null;
}

/** Write Serializer class for the Feedback model. */
export interface FeedbackWrite {
    /**
     * Opportunity Name
     * Opportunity associated with the Feedback
     */
    opportunity: number;
    /** Feedback reaction type provided by user */
    reaction: ReactionEnum;
    /** Status of all feedback updated after a user changes/removes a reaction */
    readonly feedback: AggregateFeedbackRead;
    /** Frontend's current checkbox status */
    checkbox_status?: boolean;
    /** Team associated with the Feedback */
    team: number | null;
}

export enum FilterTypeEnum {
    Requirement = "Requirement",
    PriorityHigh = "Priority - High",
    PriorityMedium = "Priority - Medium",
    PriorityLow = "Priority - Low",
}

/**
 * Full serializer for an individual Team instance. Includes nested
 * users (profiles) within the team.
 */
export interface FullTeam {
    readonly id: number;
    readonly whid: string;
    /**
     * Team name cannot contain the following characters: <, >, #, %, {, }, |, \, ^, ~, [, ], `
     * @maxLength 1024
     * @pattern ^[^<>"#%{}|\\^~\[\]`/]*$
     */
    name?: string | null;
    readonly profiles: TeamNestedProfile[];
}

export interface GCSLink {
    /** @maxLength 222 */
    bucket: string;
    /** @maxLength 1024 */
    path: string;
}

/** Serializer for Indicator instances. */
export interface Indicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
    readonly indicator_tags: IndicatorTag[];
}

/**
 * Serializer for Indicator to be used within DeepIndicatorCategorySerializer.
 * Does not show the indicator category.
 */
export interface IndicatorCategoryNestedIndicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
}

/** Serializer for Indicator to be used within IntelligenceScopeSerializer. */
export interface IndicatorDescription {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
    readonly indicator_tags: IndicatorTag[];
    /** Description for indicator value */
    description: string;
}

/** IndicatorFeedPageFiltersSerializer */
export interface IndicatorFeedPageFilters {
    readonly date_filter: string;
    readonly indicator_filter: string[];
    readonly opportunity_owner_filter: string[];
    readonly state_filter: string[];
    readonly status_filter: string[];
}

/** Serializer for Indicator instances. */
export interface IndicatorFeedPageIndicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
}

/** IndicatorGroup Serializer. */
export interface IndicatorFeedPageIndicatorGroups {
    readonly id: number;
    /** Serializer for Indicator instances. */
    indicator: IndicatorFeedPageIndicator;
    indicator_tags: IndicatorFeedPageIndicatorTags[];
    initiative_types: IndicatorFeedPageInitiativeTypes[];
}

/** Serializer for IndicatorTag instance. */
export interface IndicatorFeedPageIndicatorTags {
    /**
     * Indicator Tag
     * @maxLength 100
     */
    value: string;
}

/** Serializer for InitiativeType instance. */
export interface IndicatorFeedPageInitiativeTypes {
    /**
     * Initiative Type
     * @maxLength 100
     */
    value: string;
}

/** IndicatorFeedPageInsightsSerializer */
export interface IndicatorFeedPageInsights {
    readonly id: number;
    /**
     * Custom date for the insight - this is intended to be used as a custom date to display to users to show when the insight was updated. This differs from the created_date which is automatically set by the system. This one can be changed by the user.
     * @format date
     */
    display_date?: string;
    readonly report_date: string;
    /** Project status within the project stage the insight is in */
    status?: Status54FEnum | NullEnum | null;
    readonly opportunity_owner: ShallowOwnerRead;
    indicator_groups: IndicatorFeedPageIndicatorGroups[];
    readonly trigger_extract: string;
    /** Opportunity containing the insight */
    readonly opportunity_id: number;
    readonly opportunity_updated: boolean;
    readonly feedback: AggregateFeedbackRead;
    readonly crm_sync_status: string;
}

/** IndicatorGroup Serializer. */
export interface IndicatorGroup {
    readonly id: number;
    /** Serializer for Indicator instances. */
    indicator: Indicator;
    indicator_tags: IndicatorTag[];
    initiative_types: InitiativeType[];
    readonly team: ShallowTeam;
}

/** Write Serializer class for the IndicatorGroup model. */
export interface IndicatorGroupWrite {
    readonly id: number;
    indicator: number;
    indicator_tags?: number[];
    initiative_types?: number[];
    content_type: string;
    /**
     * @format int64
     * @min 0
     * @max 4294967295
     */
    object_id: number;
}

/** Serializer for IndicatorTag instance. */
export interface IndicatorTag {
    readonly id: number;
    /**
     * Indicator Tag
     * @maxLength 100
     */
    value: string;
}

/** Serializer for IndicatorTagConstraint. */
export interface IndicatorTagConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    indicator_tags: IndicatorTag[];
}

/** Serializer for InitiativeType instance. */
export interface InitiativeType {
    readonly id: number;
    /**
     * Initiative Type
     * @maxLength 100
     */
    value: string;
    legacy?: boolean;
}

/** Serializer for InitiativeTypeConstraint. */
export interface InitiativeTypeConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    initiative_types: InitiativeType[];
}

export interface InsightInOpportunity {
    readonly id: number;
    drinking_water_systems: ShallowDrinkingWaterSystemRead[];
    facilities: ShallowWastewaterFacilityRead[];
    /**
     * Serializer for reading of Report instances. Nested team is represented using
     * a nested serializer.
     * This serializer should be used only for read methods, not create/modify methods.
     */
    report: ShallowReportRead;
    consultants: Company[];
    readonly indicator_groups: IndicatorGroup[];
    readonly sources: Source[];
    demographics: DemographicInInsight[];
    /** Internal delivery status for the insight */
    delivery_status?: DeliveryStatusEnum;
    /** Relevant summary of the insight */
    summary?: string | null;
    readonly spd_competitors: Company[];
    readonly spd_consultants: Company[];
    contacts: ShallowContactRead[];
    /**
     * Custom date for the insight - this is intended to be used as a custom date to display to users to show when the insight was updated. This differs from the created_date which is automatically set by the system. This one can be changed by the user.
     * @format date
     */
    display_date?: string;
    readonly insight_notes: Notes[];
    competitors: Company[];
    readonly team_customer_tags: BaseTeamCustomerTag[];
    readonly stage: string;
    readonly status: string;
}

/** Serializer class for writing (create / update) Insight instances. */
export interface InsightWrite {
    readonly id: number;
    /** Opportunity containing the insight */
    opportunity: number;
    /** Report containing the insight */
    report: number;
    /** Relevant summary of the insight */
    summary?: string | null;
    /** stage of the insight visible to the user in the timeline */
    timing_status?: number | null;
    /** Internal delivery status for the insight */
    delivery_status?: DeliveryStatusEnum;
    /** Target owners associated to the insight */
    target_owners?: number[];
    /** Demographic grouping associated to the insight */
    demographics?: number[];
    /** Contacts associated with the opportunity owner of the insight */
    contacts?: number[];
    /** Drinking water system associated to the insight */
    drinking_water_systems?: number[];
    /** Facilities associated to the insight */
    facilities?: number[];
    /** Competitors associated to the insight */
    competitors?: number[];
    /** Consultants associated to the insight */
    consultants?: number[];
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    team_customer_tags?: number[];
}

/** Serializer class for an IntelligenceScope. */
export interface IntelligenceScope {
    added_by: User;
    /** Any additional comments, context, or information related to the indicator scope */
    additional_notes?: string;
    country_constraints: CountryConstraint[];
    county_constraints: CountyConstraint[];
    /** @format date-time */
    readonly date_created: string;
    readonly id: number;
    /** Serializer for Indicator to be used within IntelligenceScopeSerializer. */
    indicator: IndicatorDescription;
    indicatortag_constraints: IndicatorTagConstraint[];
    readonly industry: string;
    initiativetype_constraints: InitiativeTypeConstraint[];
    /** @format date-time */
    readonly last_updated: string;
    /** The facility size (MGD) constraint associated to the IntelligenceScope */
    mgd_constraints: MGDConstraint[];
    owner_constraints: OwnerConstraint[];
    owner_type_constraints: OwnerTypeConstraint[];
    population_constraints: PopulationConstraint[];
    project_budget_constraints: ProjectBudgetConstraint[];
    /** The client designated priority/feed rank for the indicator */
    rank?: RankEnum;
    source_timeframe_constraints: SourceTimeframeConstraint[];
    state_constraints: StateConstraint[];
    timing_status_constraints: TimingStatusConstraint[];
    updated_by: User;
}

export interface LegacyOwnerWhidCreate {
    /** @maxLength 11 */
    old_whid: string;
    /** Owner ID */
    owner: number;
}

export interface LegacyOwnerWhidNested {
    /** @maxLength 11 */
    old_whid: string;
}

export interface LegacyOwnerWhidRead {
    /** @maxLength 11 */
    old_whid: string;
    /** Serializer used for nested representation of Owners. */
    owner: ShallowOwnerRead;
}

export interface Login {
    username?: string;
    /** @format email */
    email?: string;
    password: string;
}

/** Serializer for MGDConstraint. */
export interface MGDConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    /**
     * Maximum Facility Size value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    maximum?: number | null;
    /**
     * Minimum Facility Size value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    minimum?: number | null;
}

/** Serializer class for the Notes model. */
export interface Notes {
    readonly id: number;
    notes?: string | null;
    insight?: number | null;
    source?: number | null;
}

export type NullEnum = null;

/** Read Serializer class for the Opportunity ActionsTaken model. */
export interface OpportunityActionsTakenRead {
    readonly id: number;
    opportunity?: number | null;
    actions: Actions;
    /** Serializer class for the Notes model. */
    notes: Notes;
    /**
     * Shallow serializer for Profile instances. Will not include team,
     * default_excludent_tags or permissions.
     */
    author: ShallowProfile;
    /**
     * Date the action was taken
     * @format date
     */
    action_date?: string | null;
    /** @format date-time */
    readonly last_updated: string;
    /**
     * Contact name or company
     * @maxLength 200
     */
    contact?: string | null;
    /** Team associated with Opportunity Actions Taken */
    team?: number | null;
}

/** Write Serializer class for the Opportunity ActionsTaken model. */
export interface OpportunityActionsTakenWrite {
    readonly id: number;
    opportunity?: number | null;
    actions?: number | null;
    /** Shallow Serializer class for the Notes model with just notes. */
    notes: ShallowNotes;
    /**
     * Date the action was taken
     * @format date
     */
    action_date?: string | null;
    /**
     * Contact name or company
     * @maxLength 200
     */
    contact?: string | null;
    /** Team associated with Opportunity Actions Taken */
    team?: number | null;
}

/**
 * Serializer for creating Opportunity instances. Foreign keys
 * are represented by a primary key int.
 */
export interface OpportunityCreate {
    /** The owner of the opportunity */
    owner?: number | null;
    /** @maxLength 200 */
    name: string;
    /** The team that the opportunity belongs to */
    team?: number | null;
    /**
     *
     *         Sales team members (assignees) manually assigned to an opportunity.
     *
     *         Entries in this relationship are set/unset by adding/removing assignees on an opportunity
     *         via the single opportunity page.
     *
     *         The logic to manage the auto assignees relationship also factors in the manual assignees
     *         set in this relationship.
     *
     */
    readonly assignees: number[];
    /** Opportunity importance status */
    important?: boolean;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    opportunity_value?: OpportunityValueWrite;
    /** Set to true if Rep/Distributor is marked as a third party assignee */
    other_assignee?: boolean;
}

/** Serializer that returns lists of users for each reaction where said users have left feedback for the given opportunity */
export interface OpportunityFeedbackUsers {
    readonly feedback: string;
}

/** Serializer for the Opportunity model, to be nested within DeepInsightSerializer. */
export interface OpportunityInInsight {
    readonly id: number;
    /**
     * Watrhub ID unique identifier ID
     * @maxLength 11
     */
    whid?: string;
    /** @maxLength 200 */
    name: string;
    owner: OwnerInOpportunity;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    readonly assignees: Profile[];
    /** Opportunity importance status */
    important?: boolean;
    /** Set to true if Rep/Distributor is marked as a third party assignee */
    other_assignee?: boolean;
}

export interface OpportunityRead {
    readonly id: number;
    /**
     * Watrhub ID unique identifier ID
     * @maxLength 11
     */
    whid?: string;
    readonly assignees: Profile[];
    readonly insights: InsightInOpportunity[];
    readonly report_date: string;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    readonly opportunity_value: OpportunityValueRead;
    /** Set to true if Rep/Distributor is marked as a third party assignee */
    other_assignee?: boolean;
    readonly feedback: AggregateFeedbackRead;
    readonly crm_sync_status: string;
    readonly is_intelligence_feeds: boolean;
    readonly contacts: ShallowContactRead[];
    /** @maxLength 200 */
    name: string;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    /** Opportunity importance status */
    important?: boolean;
    readonly sales_stage: string;
    readonly last_logged_activity: string;
    owner: OwnerInOpportunity;
    readonly opportunity_legacy_insight_type: string;
    readonly opportunity_rank: string;
}

/** Serializer class for the Opportunity Sales Stage model. */
export interface OpportunitySalesStageRead {
    readonly id: number;
    opportunity?: number | null;
    sales_stage: SalesStage;
    /** Serializer class for the Notes model. */
    notes: Notes;
    author: Profile;
    /** @format date-time */
    stage_date?: string | null;
    /** @format date-time */
    readonly last_updated: string;
}

/** Serializer class for the Opportunity Sales Stage model. */
export interface OpportunitySalesStageWrite {
    readonly id: number;
    opportunity?: number | null;
    sales_stage?: number | null;
    /** Serializer class for the Notes model. */
    notes: Notes;
    author?: number | null;
    /** @format date-time */
    stage_date?: string | null;
    /** @format date-time */
    readonly last_updated: string;
}

/** Serializer for OpportunityValue with only the essential fields */
export interface OpportunityValueRead {
    /**
     * @format int64
     * @min -9223372036854776000
     * @max 9223372036854776000
     */
    source_opportunity_value?: number | null;
    /** Currency of the source opportunity value */
    source_opportunity_value_currency?: CurrencyEnum | NullEnum | null;
    /**
     * @format int64
     * @min -9223372036854776000
     * @max 9223372036854776000
     */
    customer_opportunity_value?: number | null;
    /** Currency of the customer opportunity value */
    customer_opportunity_value_currency?: CurrencyEnum | NullEnum | null;
}

/** Serializer for OpportunityValue patch/create */
export interface OpportunityValueWrite {
    /** @format double */
    value: number;
    currency: CurrencyEnum;
}

export interface Owner {
    readonly id: number;
    /** The unique identifier of the owner */
    readonly whid: string;
    /** @maxLength 200 */
    legal_name: string;
    demographics?: ShallowDemographicRead[];
    dw_systems?: ShallowDrinkingWaterSystemRead[];
    facilities: number[];
    readonly parent: ShallowOwnerRead;
    /** @format date-time */
    readonly last_updated: string;
    /** @format date-time */
    last_verified?: string | null;
    /** @maxLength 2 */
    state_code: string;
    legacy_owner_whids?: LegacyOwnerWhidNested[];
    websites?: WebsiteInOwner[];
}

/** Serializer for OwnerConstraint. */
export interface OwnerConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    owners: ShallowOwnerRead[];
}

export interface OwnerInOpportunity {
    demographics: DeepDemographicRead[];
    readonly id: number;
    /**
     * The unique identifier of the owner
     * @maxLength 11
     */
    whid?: string;
    /** @maxLength 200 */
    legal_name: string;
    /** @maxLength 2 */
    state_code: string;
    readonly fiscal: string;
    dw_systems: number[];
    facilities: number[];
    contacts: ShallowContactRead[];
}

/** Serializer for OwnerTypeConstraint. */
export interface OwnerTypeConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    readonly owner_types: string[];
}

export interface PaginatedActionsList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Actions[];
}

export interface PaginatedAllInsightsPageInsightsList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: AllInsightsPageInsights[];
}

export interface PaginatedBaseTeamCustomerTagList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: BaseTeamCustomerTag[];
}

export interface PaginatedCompanyList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Company[];
}

export interface PaginatedDeepDemographicReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: DeepDemographicRead[];
}

export interface PaginatedDeepInsightList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: DeepInsight[];
}

export interface PaginatedIndicatorFeedPageInsightsList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: IndicatorFeedPageInsights[];
}

export interface PaginatedIndicatorGroupList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: IndicatorGroup[];
}

export interface PaginatedIndicatorList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Indicator[];
}

export interface PaginatedInitiativeTypeList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: InitiativeType[];
}

export interface PaginatedLegacyOwnerWhidReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: LegacyOwnerWhidRead[];
}

export interface PaginatedNotesList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Notes[];
}

export interface PaginatedOpportunityActionsTakenReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: OpportunityActionsTakenRead[];
}

export interface PaginatedOpportunityReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: OpportunityRead[];
}

export interface PaginatedOpportunitySalesStageReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: OpportunitySalesStageRead[];
}

export interface PaginatedOwnerList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Owner[];
}

export interface PaginatedPaginatedTableFeedbackList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableFeedback[];
}

export interface PaginatedPaginatedTableIndicatorList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableIndicator[];
}

export interface PaginatedPaginatedTableInsightList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableInsight[];
}

export interface PaginatedPaginatedTableOwnerList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableOwner[];
}

export interface PaginatedPaginatedTableTeamActivityList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableTeamActivity[];
}

export interface PaginatedPaginatedTableUserList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: PaginatedTableUser[];
}

export interface PaginatedProfileList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Profile[];
}

export interface PaginatedSearchPlanReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SearchPlanRead[];
}

export interface PaginatedSearchRecordReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SearchRecordRead[];
}

export interface PaginatedShallowContactReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ShallowContactRead[];
}

export interface PaginatedShallowDrinkingWaterSystemReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ShallowDrinkingWaterSystemRead[];
}

export interface PaginatedShallowIndicatorCategoryList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ShallowIndicatorCategory[];
}

export interface PaginatedShallowReportReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ShallowReportRead[];
}

export interface PaginatedShallowWastewaterFacilityList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: ShallowWastewaterFacility[];
}

export interface PaginatedSharedOpportunitiesInFeedList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SharedOpportunitiesInFeed[];
}

export interface PaginatedSingleReportPageInsightsList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: SingleReportPageInsights[];
}

export interface PaginatedSourceList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: Source[];
}

/** PaginatedTableFeedbackSerializer */
export interface PaginatedTableFeedback {
    readonly id: number;
    readonly feedback_name: string;
    readonly total_insights: string;
    readonly total_opportunity_value: string;
}

/** PaginatedTableIndicatorSerializer */
export interface PaginatedTableIndicator {
    readonly id: number;
    readonly indicator: Indicator[];
    readonly market: string[];
    readonly rank: string;
    readonly total_insights: string;
    readonly total_opportunity_value: string;
}

/** PaginatedTableInsightSerializer */
export interface PaginatedTableInsight {
    readonly id: number;
    readonly crm_sync_status: string;
    /**
     * Custom date for the insight - this is intended to be used as a custom date to display to users to show when the insight was updated. This differs from the created_date which is automatically set by the system. This one can be changed by the user.
     * @format date
     */
    display_date?: string;
    readonly feedback: AggregateFeedbackRead;
    indicator_groups: PaginatedTableInsightIndicatorGroups[];
    /** Opportunity containing the insight */
    readonly opportunity_id: number;
    readonly opportunity_owner: ShallowOwnerRead;
    readonly opportunity_updated: boolean;
    readonly priority_insight: boolean;
    readonly report_date: string;
    /** Project status within the project stage the insight is in */
    status?: Status54FEnum | NullEnum | null;
    /** Relevant summary of the insight */
    summary?: string | null;
}

/** Serializer for Indicator instances. */
export interface PaginatedTableInsightIndicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
}

/** IndicatorGroup Serializer. */
export interface PaginatedTableInsightIndicatorGroups {
    readonly id: number;
    /** Serializer for Indicator instances. */
    indicator: PaginatedTableInsightIndicator;
    indicator_tags: PaginatedTableInsightIndicatorTags[];
    initiative_types: PaginatedTableInsightInitiativeTypes[];
}

/** Serializer for IndicatorTag instance. */
export interface PaginatedTableInsightIndicatorTags {
    /**
     * Indicator Tag
     * @maxLength 100
     */
    value: string;
}

/** Serializer for InitiativeType instance. */
export interface PaginatedTableInsightInitiativeTypes {
    /**
     * Initiative Type
     * @maxLength 100
     */
    value: string;
}

/** PaginatedTableOwnerSerializer */
export interface PaginatedTableOwner {
    readonly fiscal_start: string;
    readonly id: number;
    readonly owner: string;
    readonly population: string;
    readonly rank: string;
    readonly opportunity_owner: ShallowOwnerRead;
    readonly total_insights: string;
    readonly total_opportunity_value: string;
}

/** PaginatedTableTeamActivitySerializer */
export interface PaginatedTableTeamActivity {
    readonly name: string;
    readonly email: string;
    readonly user_creation_date: string;
    readonly last_login_date: string;
    readonly is_active: string;
}

/** PaginatedTableUserSerializer */
export interface PaginatedTableUser {
    readonly id: string;
    readonly assignee: string;
    readonly sales_manager: string;
    readonly total_insights: string;
    readonly total_opportunity_value: string;
}

export interface PaginatedTimingStatusesList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: TimingStatuses[];
}

export interface PaginatedWebsiteReadList {
    /** @example 123 */
    count?: number;
    next?: string | null;
    previous?: string | null;
    results?: WebsiteRead[];
}

export interface PasswordChange {
    /** @maxLength 128 */
    old_password: string;
    /** @maxLength 128 */
    new_password1: string;
    /** @maxLength 128 */
    new_password2: string;
}

/** Serializer for requesting a password reset e-mail. */
export interface PasswordResetConfirm {
    /** @maxLength 128 */
    new_password1: string;
    /** @maxLength 128 */
    new_password2: string;
    uid: string;
    token: string;
}

/**
 * Serializer for creating/updating demographics.
 *
 * For now, the following many-to-many fields are excluded:
 *     - Part of
 *     - Postal codes
 *     - Phone area codes
 */
export interface PatchedDemographicWrite {
    /** @maxLength 200 */
    name?: string;
    readonly demographic_type_id?: number | null;
    /** @maxLength 2 */
    country_code?: string;
    /** @maxLength 2 */
    state_code?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,5})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,3}(?:\.\d{0,5})?$
     */
    longitude?: string | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    elevation?: number | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    population?: number | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    num_households?: number | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    median_income?: number | null;
    /**
     * @format int64
     * @min -9223372036854776000
     * @max 9223372036854776000
     */
    land_area?: number | null;
    /**
     * @format int64
     * @min -9223372036854776000
     * @max 9223372036854776000
     */
    water_area?: number | null;
    /** @format date-time */
    last_updated?: string;
}

/** Request Serializer for EmailGenerationOutput model */
export interface PatchedEmailGenerationOutputPostRequest {
    /**
     * EmailGenerationInput
     * The inputs from the Email Generation Input Form used to generate these Outputs
     */
    readonly input_id?: number | null;
    /** Team associated with the email generation output */
    readonly team_id?: number | null;
    /** Opportunity associated with the email generation output */
    readonly opportunity_id?: number | null;
}

/** Write Serializer class for the IndicatorGroup model. */
export interface PatchedIndicatorGroupWrite {
    readonly id?: number;
    indicator?: number;
    indicator_tags?: number[];
    initiative_types?: number[];
    content_type?: string;
    /**
     * @format int64
     * @min 0
     * @max 4294967295
     */
    object_id?: number;
}

/** Serializer class for writing (create / update) Insight instances. */
export interface PatchedInsightWrite {
    readonly id?: number;
    /** Opportunity containing the insight */
    opportunity?: number;
    /** Report containing the insight */
    report?: number;
    /** Relevant summary of the insight */
    summary?: string | null;
    /** stage of the insight visible to the user in the timeline */
    timing_status?: number | null;
    /** Internal delivery status for the insight */
    delivery_status?: DeliveryStatusEnum;
    /** Target owners associated to the insight */
    target_owners?: number[];
    /** Demographic grouping associated to the insight */
    demographics?: number[];
    /** Contacts associated with the opportunity owner of the insight */
    contacts?: number[];
    /** Drinking water system associated to the insight */
    drinking_water_systems?: number[];
    /** Facilities associated to the insight */
    facilities?: number[];
    /** Competitors associated to the insight */
    competitors?: number[];
    /** Consultants associated to the insight */
    consultants?: number[];
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    team_customer_tags?: number[];
}

/** Serializer class for the Notes model. */
export interface PatchedNotes {
    readonly id?: number;
    notes?: string | null;
    insight?: number | null;
    source?: number | null;
}

/** Write Serializer class for the Opportunity ActionsTaken model. */
export interface PatchedOpportunityActionsTakenWrite {
    readonly id?: number;
    opportunity?: number | null;
    actions?: number | null;
    /** Shallow Serializer class for the Notes model with just notes. */
    notes?: ShallowNotes;
    /**
     * Date the action was taken
     * @format date
     */
    action_date?: string | null;
    /**
     * Contact name or company
     * @maxLength 200
     */
    contact?: string | null;
    /** Team associated with Opportunity Actions Taken */
    team?: number | null;
}

/**
 * Serializer for updating Opportunity instances. The difference between this and the
 * OpportunityCreateSerializer is that this serializer does not accept the team parameter,
 * preventing the bug where the team is changed when updating an opportunity. (DEV-3279)
 */
export interface PatchedOpportunityPartialUpdate {
    /** The owner of the opportunity */
    owner?: number | null;
    /** @maxLength 200 */
    name?: string;
    /**
     *
     *         Sales team members (assignees) manually assigned to an opportunity.
     *
     *         Entries in this relationship are set/unset by adding/removing assignees on an opportunity
     *         via the single opportunity page.
     *
     *         The logic to manage the auto assignees relationship also factors in the manual assignees
     *         set in this relationship.
     *
     */
    readonly assignees?: number[];
    /** Opportunity importance status */
    important?: boolean;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    opportunity_value?: OpportunityValueWrite;
    /** Set to true if Rep/Distributor is marked as a third party assignee */
    other_assignee?: boolean;
}

export interface PatchedOpportunityRead {
    readonly id?: number;
    /**
     * Watrhub ID unique identifier ID
     * @maxLength 11
     */
    whid?: string;
    readonly assignees?: Profile[];
    readonly insights?: InsightInOpportunity[];
    readonly report_date?: string;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
    readonly opportunity_value?: OpportunityValueRead;
    /** Set to true if Rep/Distributor is marked as a third party assignee */
    other_assignee?: boolean;
    readonly feedback?: AggregateFeedbackRead;
    readonly crm_sync_status?: string;
    readonly is_intelligence_feeds?: boolean;
    readonly contacts?: ShallowContactRead[];
    /** @maxLength 200 */
    name?: string;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team?: ShallowTeam;
    /** Opportunity importance status */
    important?: boolean;
    readonly sales_stage?: string;
    readonly last_logged_activity?: string;
    owner?: OwnerInOpportunity;
    readonly opportunity_legacy_insight_type?: string;
    readonly opportunity_rank?: string;
}

/** Serializer class for the Opportunity Sales Stage model. */
export interface PatchedOpportunitySalesStageWrite {
    readonly id?: number;
    opportunity?: number | null;
    sales_stage?: number | null;
    /** Serializer class for the Notes model. */
    notes?: Notes;
    author?: number | null;
    /** @format date-time */
    stage_date?: string | null;
    /** @format date-time */
    readonly last_updated?: string;
}

/**
 * Serializer for writing (creating, updating) of Report instances. Nested team
 * is represented simply by an id.
 */
export interface PatchedReportWrite {
    readonly id?: number;
    /**
     * Date the report was delivered
     * @format date
     */
    date?: string;
    /** Status of delivery for the report */
    status?: StatusC44Enum;
    /** Additional notes on the report */
    notes?: string | null;
    /** Team who owns the report */
    team?: number;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
}

/**
 * Serializer class for the Source model.
 * There is nothing fancy going on, so this can be used for all CRUD methods.
 * Insight is represented as an integer in all cases, since we would virtually
 * never need to get the full insight from a source (and in the case when we do,
 * a second API query can be used).
 * website is excluded, as this is a field that will need integration between
 * target surfacer, crawler, and intelligence reports. source_type and display_date are also
 * excluded for the first versions.
 */
export interface PatchedSource {
    readonly id?: number;
    /** @maxLength 255 */
    title?: string;
    /**
     * @format uri
     * @maxLength 600
     */
    url?: string;
    /** @format date */
    date?: string;
    extract?: string;
    insight?: number;
    /**
     * Page number in the source document
     * @format int64
     * @min 0
     * @max 4294967295
     */
    page_number?: number | null;
}

/** User model w/o password */
export interface PatchedUserDetails {
    /** ID */
    readonly pk?: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username?: string;
    /**
     * Email address
     * @format email
     */
    readonly email?: string;
    /** @maxLength 30 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
}

export interface PatchedWebsiteWrite {
    /**
     * The url of the crawler seed
     * @format uri
     * @maxLength 200
     */
    url?: string;
    owners?: number[];
    /** State/Province Tag */
    tags?: number[];
}

export enum PlanTypeEnum {
    Elite = "Elite",
    Premium = "Premium",
    Essentials = "Essentials",
}

/** Serializer for PopulationConstraint. */
export interface PopulationConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    /**
     * Maximum population value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    maximum?: number | null;
    /**
     * Minimum population value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    minimum?: number | null;
}

export interface Product {
    readonly id: number;
    /**
     * Name of product as it appears in sidebar menu
     * @maxLength 200
     */
    name?: string;
    /** DASHBOARD product type requires path */
    product_type?: ProductTypeEnum;
    /**
     * Full URL path for DASHBOARD product type
     * @maxLength 200
     */
    path?: string;
    /**
     * Higher ranks appear first
     * @min -2147483648
     * @max 2147483647
     */
    rank?: number | null;
}

export enum ProductTypeEnum {
    INTELLIGENCE_REPORTS = "INTELLIGENCE_REPORTS",
    INTELLIGENCE_REPORTS_CUSTOM_DASHBOARD = "INTELLIGENCE_REPORTS_CUSTOM_DASHBOARD",
    INDICATOR_FEEDS = "INDICATOR_FEEDS",
    INTELLIGENCE_FEEDS = "INTELLIGENCE_FEEDS",
    DASHBOARD = "DASHBOARD",
    DOCUMENT_COMPARE = "DOCUMENT_COMPARE",
}

export interface Profile {
    readonly id: number;
    user: User;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    default_excluded_tags: TagRead[];
    readonly permissions: string;
}

/** Serializer for ProjectBudgetConstraint. */
export interface ProjectBudgetConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    /**
     * Maximum project budget value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    maximum?: number | null;
    /**
     * Minimum project budget value for the constraint used to determine insight rank
     * @format int64
     * @min 0
     * @max 4294967295
     */
    minimum?: number | null;
}

export enum RankEnum {
    PriorityHigh = "Priority - High",
    PriorityMedium = "Priority - Medium",
    PriorityLow = "Priority - Low",
    Feeds = "Feeds",
}

export enum ReactionEnum {
    Like = "like",
    Dislike = "dislike",
    Bullseye = "bullseye",
    AddToActionList = "add_to_action_list",
    AddToCrm = "add_to_crm",
    AddToFutureList = "add_to_future_list",
    Hide = "hide",
}

/**
 * Serializer for writing (creating, updating) of Report instances. Nested team
 * is represented simply by an id.
 */
export interface ReportWrite {
    readonly id: number;
    /**
     * Date the report was delivered
     * @format date
     */
    date: string;
    /** Status of delivery for the report */
    status: StatusC44Enum;
    /** Additional notes on the report */
    notes?: string | null;
    /** Team who owns the report */
    team: number;
    /** Set to true if opportunity is soft deleted by a user */
    is_deleted?: boolean;
}

export interface RestAuthDetail {
    readonly detail: string;
}

export interface SalesStage {
    readonly id: number;
    /** @maxLength 100 */
    stage: string;
    /** @maxLength 100 */
    stage_milestone: string;
    readonly label: string;
}

/** Serializer class for SearchPlan, specific to SearchPlanListAPIView */
export interface SearchPlanRead {
    /** @format date-time */
    readonly last_updated: string;
    readonly link: string;
    readonly product_name: string;
    readonly team_name: string;
}

/** Serializer class for a single Search Plan. */
export interface SearchPlanSummaryRead {
    /**
     * Shallow serializer for Profile instances. Will not include team,
     * default_excludent_tags or permissions.
     */
    account_manager: ShallowProfile;
    added_by: User;
    readonly comments: Comment[];
    /** @format date-time */
    readonly date_created: string;
    /** The delivery schedule of the client */
    delivery_cadence?: DeliveryCadenceEnum | BlankEnum;
    readonly intelligence_scopes: IntelligenceScope[];
    /** @format date-time */
    readonly last_updated: string;
    /**
     * Insights (max)
     * Total number of maximum expected insights
     * @format int64
     * @min 0
     * @max 4294967295
     */
    maximum_insights?: number | null;
    /**
     * Insights (min)
     * Total number of minimum expected insights
     * @format int64
     * @min 0
     * @max 4294967295
     */
    minimum_insights?: number | null;
    /** The Citylitics plan type selected by the client. (temporary choice field until we decide if we want to use the Tier model) */
    plan_type?: PlanTypeEnum | BlankEnum;
    product: Product;
    /** Serializer for Subscription. */
    subscription: Subscription;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    updated_by: User;
    /** @format uuid */
    uuid?: string;
}

/**
 * Serializer for reading of SearchRecord instances
 *
 * This serializer should be used only for read methods, not create/modify methods.
 */
export interface SearchRecordRead {
    readonly id: number;
    profile: Profile;
    /** @format date */
    date_range_start: string;
    /** @format date */
    date_range_end?: string | null;
    readonly included_tags: TagRead[];
    readonly excluded_tags: TagRead[];
    /** @format date-time */
    readonly when: string;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
    indices: string;
    queries: string;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    number_of_results?: number;
    batch: BatchSearchRecord;
}

/**
 * Serializer for reading of Contact instances.
 *
 * This serializer should only be used for read methods, as it does not contain
 * all fields necessary for creating and editing. Reverse foreign key relationships
 * are not provided.
 */
export interface ShallowContactRead {
    readonly id: number;
    /** @maxLength 11 */
    whid?: string;
    /** @maxLength 200 */
    first_name: string;
    /** @maxLength 200 */
    last_name: string;
    /** @maxLength 200 */
    position: string;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string | null;
    /**
     * @format email
     * @maxLength 254
     */
    email_alternate?: string | null;
    phone_primary: string;
    /**
     * To add a phone number, us this format +11111111111. To add a phone number with an extension, use this format +11111111111x111
     * @maxLength 128
     */
    phone_secondary?: string | null;
    company: Company;
    /** Serializer used for nested representation of Owners. */
    owner: ShallowOwnerRead;
    /** @format date */
    last_manual_verification_date: string;
    /**
     * Linkedin URL
     * @format uri
     * @maxLength 200
     */
    linkedin?: string | null;
}

/**
 * Serializer for reading of Demographic instances, to be used nested. Only contains
 * main fields useful for searching and overview.
 *
 * This serializer should only be used for read methods, not create/modify methods.
 */
export interface ShallowDemographicRead {
    readonly id: number;
    readonly name: string;
    readonly demographic_type: string;
    readonly country_code: string;
    readonly state_code: string;
}

/**
 * Serializer for reading of DrinkingWaterSystem instances.
 *
 * This serializer should only be used for read methods, as it does not contain
 * all fields necessary for creating and editing. Reverse foreign key relationships
 * are not provided.
 */
export interface ShallowDrinkingWaterSystemRead {
    readonly id: number;
    readonly name: string;
    readonly public_water_system_id: string;
    readonly system_type: SystemTypeEnum;
    readonly population_served: number;
    readonly state_code: string;
    /** @format date-time */
    readonly last_updated: string;
    readonly owner_type: string;
    readonly active: boolean;
}

/** Serializer for IndicatorCategory instances. */
export interface ShallowIndicatorCategory {
    readonly id: number;
    /**
     * Category
     * @maxLength 100
     */
    value: string;
}

/** Shallow Serializer class for the Notes model with just notes. */
export interface ShallowNotes {
    notes?: string | null;
}

/** Serializer used for nested representation of Owners. */
export interface ShallowOwnerRead {
    readonly id: number;
    /** The unique identifier of the owner */
    readonly whid: string;
    readonly legal_name: string;
    readonly state_code: string;
}

/**
 * Shallow serializer for Profile instances. Will not include team,
 * default_excludent_tags or permissions.
 */
export interface ShallowProfile {
    readonly id: number;
    user: User;
}

/**
 * Serializer for reading of Report instances. Nested team is represented using
 * a nested serializer.
 * This serializer should be used only for read methods, not create/modify methods.
 */
export interface ShallowReportRead {
    readonly id: number;
    /**
     * Date the report was delivered
     * @format date
     */
    readonly date: string;
    /** Status of delivery for the report */
    readonly status: StatusC44Enum;
    /**
     * Shallow serializer for Team instances. Will not include users
     * (profiles) within the team.
     */
    team: ShallowTeam;
}

/**
 * Shallow serializer for Team instances. Will not include users
 * (profiles) within the team.
 */
export interface ShallowTeam {
    readonly id: number;
    readonly whid: string;
    /**
     * Team name cannot contain the following characters: <, >, #, %, {, }, |, \, ^, ~, [, ], `
     * @maxLength 1024
     * @pattern ^[^<>"#%{}|\\^~\[\]`/]*$
     */
    name?: string | null;
    is_deleted?: boolean;
    readonly number_of_reports: string;
    dashboard_links: DashboardLink[];
    readonly status: string;
}

export interface ShallowWastewaterFacility {
    readonly id: number;
    readonly whid: string;
    readonly owner: number;
    readonly facility_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
     */
    readonly design_flow: string;
}

export interface ShallowWastewaterFacilityRead {
    readonly id: number;
    readonly facility_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,3})?$
     */
    readonly design_flow: string;
    readonly npdes_id: string;
}

/** List Serializer class for the SharedOpportunity model. */
export interface SharedOpportunitiesInFeed {
    /** @format uuid */
    uuid?: string;
    /**
     * @format uri
     * @maxLength 200
     */
    link?: string | null;
}

/** Serializer class for a single SharedOpportunity. */
export interface SharedOpportunitiesRead {
    /** @format uuid */
    uuid?: string;
    readonly opportunities: string;
}

/** Write Serializer class for the SharedOpportunity model. */
export interface SharedOpportunitiesWrite {
    readonly added_by: number;
    /** @format uuid */
    readonly uuid: string;
    indicator_group?: number | null;
    /** @format uri */
    readonly link: string;
    opportunities: number[];
    /** Team associated with the SharedOpportunities */
    team?: number | null;
    report?: number | null;
}

/** Loads all team names */
export interface SidebarLayoutAllTeams {
    readonly id: number;
    /**
     * Team name cannot contain the following characters: <, >, #, %, {, }, |, \, ^, ~, [, ], `
     * @maxLength 1024
     * @pattern ^[^<>"#%{}|\\^~\[\]`/]*$
     */
    name?: string | null;
}

/** Loads whoami information */
export interface SidebarLayoutProfile {
    user: User;
    /** Loads all team names */
    team: SidebarLayoutSingleTeam;
}

/** Loads all team names */
export interface SidebarLayoutSingleTeam {
    readonly id: number;
    /**
     * Team name cannot contain the following characters: <, >, #, %, {, }, |, \, ^, ~, [, ], `
     * @maxLength 1024
     * @pattern ^[^<>"#%{}|\\^~\[\]`/]*$
     */
    name?: string | null;
    dashboard_links: DashboardLink[];
    products: Product[];
    readonly status: string;
    tiers: Tier[];
}

/** Serializer for verifying a signup link. */
export interface SignupLinkVerification {
    uid: string;
    token: string;
}

/** SingleReportPageFiltersSerializer */
export interface SingleReportPageFilters {
    readonly indicator_filter: string[];
    readonly opportunity_owner_filter: string[];
    readonly population_filter: string;
    readonly state_filter: string[];
    readonly status_filter: string[];
}

/** Serializer for Indicator instances. */
export interface SingleReportPageIndicator {
    readonly id: number;
    /**
     * Indicator
     * Indicator type i.e. (Corrosion Issues, Recurring Main Breaks)
     * @maxLength 100
     */
    value: string;
    readonly category: string;
    readonly industry: string;
    readonly category_id: number;
    readonly industry_id: number;
}

/** IndicatorGroup Serializer. */
export interface SingleReportPageIndicatorGroups {
    readonly id: number;
    /** Serializer for Indicator instances. */
    indicator: SingleReportPageIndicator;
    indicator_tags: SingleReportPageIndicatorTags[];
    initiative_types: SingleReportPageInitiativeTypes[];
}

/** Serializer for IndicatorTag instance. */
export interface SingleReportPageIndicatorTags {
    /**
     * Indicator Tag
     * @maxLength 100
     */
    value: string;
}

/** Serializer for InitiativeType instance. */
export interface SingleReportPageInitiativeTypes {
    /**
     * Initiative Type
     * @maxLength 100
     */
    value: string;
}

/** SingleReportPageInsightsSerializer */
export interface SingleReportPageInsights {
    readonly id: number;
    /** Project status within the project stage the insight is in */
    status?: Status54FEnum | NullEnum | null;
    readonly opportunity_owner: ShallowOwnerRead;
    indicator_groups: SingleReportPageIndicatorGroups[];
    /** Relevant summary of the insight */
    summary?: string | null;
    /** Opportunity containing the insight */
    readonly opportunity_id: number;
    total_population: number;
    readonly opportunity_updated: boolean;
    /** List of Team Customer Tag Names */
    readonly team_customer_tags: string[];
    /** Internal delivery status for the insight */
    delivery_status?: DeliveryStatusEnum;
    readonly feedback: AggregateFeedbackRead;
    readonly crm_sync_status: string;
}

/**
 * Serializer class for the Source model.
 * There is nothing fancy going on, so this can be used for all CRUD methods.
 * Insight is represented as an integer in all cases, since we would virtually
 * never need to get the full insight from a source (and in the case when we do,
 * a second API query can be used).
 * website is excluded, as this is a field that will need integration between
 * target surfacer, crawler, and intelligence reports. source_type and display_date are also
 * excluded for the first versions.
 */
export interface Source {
    readonly id: number;
    /** @maxLength 255 */
    title: string;
    /**
     * @format uri
     * @maxLength 600
     */
    url: string;
    /** @format date */
    date: string;
    extract?: string;
    insight: number;
    /**
     * Page number in the source document
     * @format int64
     * @min 0
     * @max 4294967295
     */
    page_number?: number | null;
}

/** Serializer for SourceTimeframeConstraint. */
export interface SourceTimeframeConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    /**
     * Max Month
     * Maximum number of months for the constraint used to determine insight rank
     * @min 3
     * @max 12
     */
    maximum?: number | null;
}

export interface State {
    /** @maxLength 3 */
    code: string;
    /** @maxLength 200 */
    name: string;
}

/** Serializer for StateConstraint. */
export interface StateConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    states: State[];
}

export enum Status54FEnum {
    Violation = "Violation",
    Identified = "Identified",
    CustomerComplaint = "Customer Complaint",
    Advisory = "Advisory",
    News = "News",
    PlanEstablished = "Plan Established",
    BudgetaryEstimates = "Budgetary Estimates",
    Assessment = "Assessment",
    FeasibilityStudy = "Feasibility Study",
    Pilot = "Pilot",
    FundingRequested = "Funding Requested",
    FundingApproved = "Funding Approved",
    OutToBid = "Out to Bid",
    ContractAwarded = "Contract Awarded",
    DesignStarted = "Design Started",
    ConstructionStarted = "Construction Started",
    InitiativeIdentified = "Initiative Identified",
}

export enum StatusC44Enum {
    InProgress = "In Progress",
    SelfReviewed = "Self-reviewed",
    PeerReviewed = "Peer-reviewed",
    Delivered = "Delivered",
}

/** Serializer for Subscription. */
export interface Subscription {
    added_by: User;
    /** Basic Facility Information */
    basic_facility?: boolean;
    bid_check?: boolean;
    competitors: SubscriptionCompany[];
    consultants: SubscriptionCompany[];
    readonly contacts: SubscriptionContact[];
    /** @format date-time */
    readonly date_created: string;
    /** @format date-time */
    readonly last_updated: string;
    /** Owner Office Location */
    owner_office?: boolean;
    /** History of Permit Violations */
    permit_violation?: boolean;
    project_budget?: boolean;
    updated_by: User;
    priority_owners: SubscriptionOwner[];
}

/** Serializer for competitors/consultants (Company). */
export interface SubscriptionCompany {
    /** @maxLength 200 */
    name: string;
    readonly segment: string;
    /**
     * @format uri
     * @maxLength 200
     */
    url: string;
}

/** Serializer for SubscriptionContact. */
export interface SubscriptionContact {
    readonly contact_department: string;
    readonly contact_level: string;
    /**
     * @format int64
     * @min 0
     * @max 4294967295
     */
    rank?: number | null;
}

/** Serializer for priority owners (Owner). */
export interface SubscriptionOwner {
    /** @maxLength 200 */
    legal_name: string;
    /** @maxLength 2 */
    state_code: string;
}

export enum SystemTypeEnum {
    Community = "Community",
    TransientNonCommunity = "Transient Non-Community",
}

export interface TagRead {
    readonly id: number;
    /** @maxLength 100 */
    value: string;
    /** @maxLength 200 */
    description?: string | null;
    is_deleted?: boolean;
    readonly tag_type: TagType;
}

export interface TagType {
    /** @maxLength 100 */
    name: string;
}

/**
 * Serializer for Profiles for use within a Team serializer. Will
 * not include the user's team.
 */
export interface TeamNestedProfile {
    readonly id: number;
    user: User;
}

export interface Tier {
    /**
     * Tier name
     * Name for the tier like Tier_1
     * @maxLength 255
     */
    name?: string | null;
}

/** Serializer for TimingStatus. */
export interface TimingStatus {
    readonly id: number;
    readonly value: string;
}

/** Serializer for TimingStatusConstraint. */
export interface TimingStatusConstraint {
    /** Requirement Type */
    constraint_type?: ConstraintTypeEnum | BlankEnum;
    filter_type?: FilterTypeEnum | BlankEnum;
    readonly has_profiles: boolean;
    statuses: TimingStatus[];
}

/** Serializer for TimingStatuses. */
export interface TimingStatuses {
    readonly status: string;
    readonly stage: string;
    readonly id: number;
}

export enum ToneEnum {
    BusinessPersuasive = "Business Persuasive",
    Informative = "Informative",
    SolutionOriented = "Solution Oriented",
    FormalProfessional = "Formal Professional",
}

export interface User {
    readonly id: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string;
    /** @maxLength 30 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email?: string;
    /**
     * Staff status
     * Designates whether the user can log into this admin site.
     */
    is_staff?: boolean;
    readonly user_type: string;
}

/** User model w/o password */
export interface UserDetails {
    /** ID */
    readonly pk: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @maxLength 150
     * @pattern ^[\w.@+-]+$
     */
    username: string;
    /**
     * Email address
     * @format email
     */
    readonly email: string;
    /** @maxLength 30 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
}

export interface UserName {
    /** @maxLength 30 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
}

export interface WebsiteInOwner {
    readonly id: number;
    /**
     * The url of the crawler seed
     * @format uri
     * @maxLength 200
     */
    url: string;
    /** State/Province Tag */
    tags?: number[];
}

/** Serializer for reading Websites. Owners are nested to show only IDs and WHIDs. */
export interface WebsiteRead {
    readonly id: number;
    /**
     * The url of the crawler seed
     * @format uri
     * @maxLength 200
     */
    url: string;
    readonly owners: ShallowOwnerRead[];
    readonly tags: TagRead[];
}

export interface WebsiteWrite {
    /**
     * The url of the crawler seed
     * @format uri
     * @maxLength 200
     */
    url?: string;
    owners?: number[];
    /** State/Province Tag */
    tags?: number[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = "application/json",
    FormData = "multipart/form-data",
    UrlEncoded = "application/x-www-form-urlencoded",
    Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = "";
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: "same-origin",
        headers: {},
        redirect: "follow",
        referrerPolicy: "no-referrer",
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(
            typeof value === "number" ? value : `${value}`
        )}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => "undefined" !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join("&");
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : "";
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === "object" || typeof input === "string")
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === "object" && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ""}${path}${
                queryString ? `?${queryString}` : ""
            }`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { "Content-Type": type }
                        : {}),
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === "undefined" || body === null
                        ? null
                        : payloadFormatter(body),
            }
        ).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Citylitics API Documentation
 * @version 0.0.0
 *
 * Click on 'Try it out' to interact with our APIs
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    api = {
        /**
         * @description Pass the current status of feature flags, if no user_id provided send all feature flags as response, otherwise send the ones which have a user_id Need to add the condition to return user specific flags if a user_id is passed in the params
         *
         * @tags feature_flags
         * @name FeatureFlagsRetrieve
         * @request GET:/api/feature_flags/
         * @secure
         */
        featureFlagsRetrieve: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/feature_flags/`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description View to handle creation of a new link URL route: /api/links/link/ Allowed methods: POST: bucket: (required) bucket name string path: (required) path to file within the bucket
         *
         * @tags links
         * @name LinksLinkCreate
         * @request POST:/api/links/link/
         * @secure
         */
        linksLinkCreate: (data: GCSLink, params: RequestParams = {}) =>
            this.request<GCSLink, any>({
                path: `/api/links/link/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags logging
         * @name LoggingInfoCreate
         * @request POST:/api/logging/info/
         * @secure
         */
        loggingInfoCreate: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/logging/info/`,
                method: "POST",
                secure: true,
                ...params,
            }),

        /**
         * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
         *
         * @tags rest-auth
         * @name RestAuthLoginCreate
         * @request POST:/api/rest-auth/login/
         * @secure
         */
        restAuthLoginCreate: (data: Login, params: RequestParams = {}) =>
            this.request<Login, any>({
                path: `/api/rest-auth/login/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
         *
         * @tags rest-auth
         * @name RestAuthLogoutCreate
         * @request POST:/api/rest-auth/logout/
         * @secure
         */
        restAuthLogoutCreate: (params: RequestParams = {}) =>
            this.request<RestAuthDetail, any>({
                path: `/api/rest-auth/logout/`,
                method: "POST",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         *
         * @tags rest-auth
         * @name RestAuthPasswordChangeCreate
         * @request POST:/api/rest-auth/password/change/
         * @secure
         */
        restAuthPasswordChangeCreate: (
            data: PasswordChange,
            params: RequestParams = {}
        ) =>
            this.request<RestAuthDetail, any>({
                path: `/api/rest-auth/password/change/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
         *
         * @tags rest-auth
         * @name RestAuthPasswordResetCreate
         * @request POST:/api/rest-auth/password/reset/
         * @secure
         */
        restAuthPasswordResetCreate: (
            data: CustomPasswordReset,
            params: RequestParams = {}
        ) =>
            this.request<CustomPasswordReset, any>({
                path: `/api/rest-auth/password/reset/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
         *
         * @tags rest-auth
         * @name RestAuthPasswordResetConfirmCreate
         * @request POST:/api/rest-auth/password/reset/confirm/
         * @secure
         */
        restAuthPasswordResetConfirmCreate: (
            data: PasswordResetConfirm,
            params: RequestParams = {}
        ) =>
            this.request<PasswordResetConfirm, any>({
                path: `/api/rest-auth/password/reset/confirm/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags rest-auth
         * @name RestAuthSignupLinkVerificationCreate
         * @request POST:/api/rest-auth/signup-link/verification/
         * @secure
         */
        restAuthSignupLinkVerificationCreate: (
            data: SignupLinkVerification,
            params: RequestParams = {}
        ) =>
            this.request<SignupLinkVerification, any>({
                path: `/api/rest-auth/signup-link/verification/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
         *
         * @tags rest-auth
         * @name RestAuthUserRetrieve
         * @request GET:/api/rest-auth/user/
         * @secure
         */
        restAuthUserRetrieve: (params: RequestParams = {}) =>
            this.request<UserDetails, any>({
                path: `/api/rest-auth/user/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
         *
         * @tags rest-auth
         * @name RestAuthUserUpdate
         * @request PUT:/api/rest-auth/user/
         * @secure
         */
        restAuthUserUpdate: (data: UserDetails, params: RequestParams = {}) =>
            this.request<UserDetails, any>({
                path: `/api/rest-auth/user/`,
                method: "PUT",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
         *
         * @tags rest-auth
         * @name RestAuthUserPartialUpdate
         * @request PATCH:/api/rest-auth/user/
         * @secure
         */
        restAuthUserPartialUpdate: (
            data: PatchedUserDetails,
            params: RequestParams = {}
        ) =>
            this.request<UserDetails, any>({
                path: `/api/rest-auth/user/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of actions used in the log progress modal.
         *
         * @tags target_reports
         * @name Actions
         * @summary Get All Actions
         * @request GET:/api/target_reports/actions/
         * @secure
         */
        actions: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedActionsList, any>({
                path: `/api/target_reports/actions/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all actions taken
         *
         * @tags target_reports
         * @name TargetReportsActionsTakenList
         * @summary Get opportunity actions taken instances
         * @request GET:/api/target_reports/actions_taken/
         * @secure
         */
        targetReportsActionsTakenList: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Opportunity ID to filter on. */
                opportunity?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedOpportunityActionsTakenReadList, any>({
                path: `/api/target_reports/actions_taken/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsActionsTakenCreate
         * @summary Create an opportunity actions taken
         * @request POST:/api/target_reports/actions_taken/
         * @secure
         */
        targetReportsActionsTakenCreate: (
            data: OpportunityActionsTakenWrite,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityActionsTakenWrite, any>({
                path: `/api/target_reports/actions_taken/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns an opportunity actions taken
         *
         * @tags target_reports
         * @name TargetReportsActionsTakenRetrieve
         * @summary Get an opportunity actions taken
         * @request GET:/api/target_reports/actions_taken/{id}/
         * @secure
         */
        targetReportsActionsTakenRetrieve: (id: number, params: RequestParams = {}) =>
            this.request<OpportunityActionsTakenRead, any>({
                path: `/api/target_reports/actions_taken/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update an existing note with the fields required. No fields are actually required in the request body.
         *
         * @tags target_reports
         * @name TargetReportsActionsTakenPartialUpdate
         * @summary Partially update an opportunity actions taken
         * @request PATCH:/api/target_reports/actions_taken/{id}/
         * @secure
         */
        targetReportsActionsTakenPartialUpdate: (
            id: number,
            data: PatchedOpportunityActionsTakenWrite,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityActionsTakenWrite, any>({
                path: `/api/target_reports/actions_taken/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the opportunity actions taken instance
         *
         * @tags target_reports
         * @name TargetReportsActionsTakenDestroy
         * @summary Delete an opportunity actions taken
         * @request DELETE:/api/target_reports/actions_taken/{id}/
         * @secure
         */
        targetReportsActionsTakenDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/actions_taken/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a list of Insights for the All Insights page only
         *
         * @tags target_reports
         * @name TargetReportsAllInsightsListRead
         * @summary GET Insights for All Insights page
         * @request GET:/api/target_reports/all_insights/
         * @secure
         */
        targetReportsAllInsightsListRead: (
            query?: {
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                opportunity_updated?: boolean;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** @format date */
                report_date_after?: string;
                /** @format date */
                report_date_before?: string;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedAllInsightsPageInsightsList, any>({
                path: `/api/target_reports/all_insights/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the given Team
         *
         * @tags target_reports
         * @name TargetReportsAllInsightsFilters
         * @summary GET filters for All Insights page
         * @request GET:/api/target_reports/all_insights/{team_id}/filters/
         * @secure
         */
        targetReportsAllInsightsFilters: (teamId: string, params: RequestParams = {}) =>
            this.request<AllInsightsPageFilters, any>({
                path: `/api/target_reports/all_insights/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the Opportunity IDs for the given Team
         *
         * @tags target_reports
         * @name TargetReportsAllInsightsOpportunities
         * @summary GET list of Opportunity IDs for All Insights page
         * @request GET:/api/target_reports/all_insights/opportunities/
         * @secure
         */
        targetReportsAllInsightsOpportunities: (params: RequestParams = {}) =>
            this.request<AllInsightsPageInsights, any>({
                path: `/api/target_reports/all_insights/opportunities/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Return a dictionary of delivery statuses (key/value pairs)
         *
         * @tags target_reports
         * @name TargetReportsDeliveryStatusesRetrieve
         * @request GET:/api/target_reports/delivery_statuses/
         * @secure
         */
        targetReportsDeliveryStatusesRetrieve: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/delivery_statuses/`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Create new Comparison model after comparing two documents via Draftable API
         *
         * @tags target_reports
         * @name TargetReportsDocumentCompareCreate
         * @summary Create new Document Comparison
         * @request POST:/api/target_reports/document_compare/
         * @secure
         */
        targetReportsDocumentCompareCreate: (
            data: {
                /**
                 * Left side document
                 * @format binary
                 */
                left_side_document: File;
                /**
                 * Right side document
                 * @format binary
                 */
                right_side_document: File;
                /** Team ID */
                team_id: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ComparisonResponse, void>({
                path: `/api/target_reports/document_compare/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: "json",
                ...params,
            }),

        /**
         * @description Fetch document comparison by using the Dratable API and looking up by identifier
         *
         * @tags target_reports
         * @name TargetReportsDocumentCompareRetrieve
         * @summary Fetch document comparison by identifier
         * @request GET:/api/target_reports/document_compare/{identifier}/
         * @secure
         */
        targetReportsDocumentCompareRetrieve: (
            identifier: string,
            query: {
                /** Team ID */
                team_id: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ComparisonResponse, any>({
                path: `/api/target_reports/document_compare/${identifier}/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Create an EmailGenerationInput instance and return its id
         *
         * @tags target_reports
         * @name TargetReportsEmailGenerationInputFormCreate
         * @summary Create EmailGenerationInput model
         * @request POST:/api/target_reports/email_generation_input_form/
         * @secure
         */
        targetReportsEmailGenerationInputFormCreate: (
            data: EmailGenerationInputPayload,
            params: RequestParams = {}
        ) =>
            this.request<EmailGenerationInputPostResponse, any>({
                path: `/api/target_reports/email_generation_input_form/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Retrieve the last EmailGenerationInput instance by user_id and return all of the information
         *
         * @tags target_reports
         * @name TargetReportsEmailGenerationInputFormRetrieve
         * @summary Retrieve last EmailGenerationInput model by user_id
         * @request GET:/api/target_reports/email_generation_input_form/{team_id}/
         * @secure
         */
        targetReportsEmailGenerationInputFormRetrieve: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<EmailGenerationInputPayload, any>({
                path: `/api/target_reports/email_generation_input_form/${teamId}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Create an EmailGenerationOutput instance and return its id
         *
         * @tags target_reports
         * @name TargetReportsEmailGenerationOutputFormCreate
         * @summary Create EmailGenerationOutput model
         * @request POST:/api/target_reports/email_generation_output_form/
         * @secure
         */
        targetReportsEmailGenerationOutputFormCreate: (
            data: EmailGenerationOutputPostRequest,
            params: RequestParams = {}
        ) =>
            this.request<EmailGenerationOutputPostResponse, void>({
                path: `/api/target_reports/email_generation_output_form/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Update an EmailGenerationOutput instance and return its id
         *
         * @tags target_reports
         * @name TargetReportsEmailGenerationOutputFormUpdate
         * @summary Update EmailGenerationOutput model
         * @request PATCH:/api/target_reports/email_generation_output_form/{id}/
         * @secure
         */
        targetReportsEmailGenerationOutputFormUpdate: (
            id: string,
            data: PatchedEmailGenerationOutputPostRequest,
            params: RequestParams = {}
        ) =>
            this.request<EmailGenerationOutputPostResponse, void>({
                path: `/api/target_reports/email_generation_output_form/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Update user feedback associated to an opportunity and team
         *
         * @tags target_reports
         * @name TargetReportsFeedbackCreate
         * @summary Update user feedback
         * @request POST:/api/target_reports/feedback/
         * @secure
         */
        targetReportsFeedbackCreate: (
            data: FeedbackWrite,
            params: RequestParams = {}
        ) =>
            this.request<FeedbackWrite, any>({
                path: `/api/target_reports/feedback/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all indicator categories.
         *
         * @tags target_reports
         * @name TargetReportsIndicatorCategoriesListRead
         * @summary Get indicator categories
         * @request GET:/api/target_reports/indicator_categories/
         * @secure
         */
        targetReportsIndicatorCategoriesListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedShallowIndicatorCategoryList, any>({
                path: `/api/target_reports/indicator_categories/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single IndicatorCategory instance, with the list property `indicators` containing all indicators (id and value, not description).
         *
         * @tags target_reports
         * @name TargetReportsIndicatorCategoriesDetailRead
         * @summary Get an indicator category object
         * @request GET:/api/target_reports/indicator_categories/{id}/
         * @secure
         */
        targetReportsIndicatorCategoriesDetailRead: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<DeepIndicatorCategory, any>({
                path: `/api/target_reports/indicator_categories/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of Insights for the Indicator Feed page only
         *
         * @tags target_reports
         * @name TargetReportsIndicatorFeedListRead
         * @summary GET Insights for Indicator Feed page
         * @request GET:/api/target_reports/indicator_feed/
         * @secure
         */
        targetReportsIndicatorFeedListRead: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                indicator_group_id?: number;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                opportunity_updated?: boolean;
                /** Which field to use when ordering the results. */
                ordering?: string;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedIndicatorFeedPageInsightsList, any>({
                path: `/api/target_reports/indicator_feed/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the given Indicator Feed
         *
         * @tags target_reports
         * @name TargetReportsIndicatorFeedFilters
         * @summary GET filters for Indicator Feed page
         * @request GET:/api/target_reports/indicator_feed/{indicator_group_id}/filters/
         * @secure
         */
        targetReportsIndicatorFeedFilters: (
            indicatorGroupId: string,
            params: RequestParams = {}
        ) =>
            this.request<IndicatorFeedPageFilters, any>({
                path: `/api/target_reports/indicator_feed/${indicatorGroupId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the Opportunity IDs for the given Indicator Feed
         *
         * @tags target_reports
         * @name TargetReportsIndicatorFeedOpportunities
         * @summary GET list of Opportunity IDs for Indicator Feed page
         * @request GET:/api/target_reports/indicator_feed/opportunities/
         * @secure
         */
        targetReportsIndicatorFeedOpportunities: (params: RequestParams = {}) =>
            this.request<IndicatorFeedPageInsights, any>({
                path: `/api/target_reports/indicator_feed/opportunities/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsList
         * @request GET:/api/target_reports/indicator_groups/
         * @secure
         */
        targetReportsIndicatorGroupsList: (
            query?: {
                content_type?: string;
                /** Number of results to return per page. */
                limit?: number;
                object_id?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedIndicatorGroupList, any>({
                path: `/api/target_reports/indicator_groups/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsCreate
         * @request POST:/api/target_reports/indicator_groups/
         * @secure
         */
        targetReportsIndicatorGroupsCreate: (
            data: IndicatorGroupWrite,
            params: RequestParams = {}
        ) =>
            this.request<IndicatorGroupWrite, any>({
                path: `/api/target_reports/indicator_groups/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsRetrieve
         * @request GET:/api/target_reports/indicator_groups/{id}/
         * @secure
         */
        targetReportsIndicatorGroupsRetrieve: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<IndicatorGroup, any>({
                path: `/api/target_reports/indicator_groups/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsUpdate
         * @request PUT:/api/target_reports/indicator_groups/{id}/
         * @secure
         */
        targetReportsIndicatorGroupsUpdate: (
            id: number,
            data: IndicatorGroupWrite,
            params: RequestParams = {}
        ) =>
            this.request<IndicatorGroupWrite, any>({
                path: `/api/target_reports/indicator_groups/${id}/`,
                method: "PUT",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsPartialUpdate
         * @request PATCH:/api/target_reports/indicator_groups/{id}/
         * @secure
         */
        targetReportsIndicatorGroupsPartialUpdate: (
            id: number,
            data: PatchedIndicatorGroupWrite,
            params: RequestParams = {}
        ) =>
            this.request<IndicatorGroupWrite, any>({
                path: `/api/target_reports/indicator_groups/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsIndicatorGroupsDestroy
         * @request DELETE:/api/target_reports/indicator_groups/{id}/
         * @secure
         */
        targetReportsIndicatorGroupsDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/indicator_groups/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a paginated list of all indicators. Category name is represented as a property, rather than a nested object.
         *
         * @tags target_reports
         * @name TargetReportsIndicatorsListRead
         * @summary Get indicators
         * @request GET:/api/target_reports/indicators/
         * @secure
         */
        targetReportsIndicatorsListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedIndicatorList, any>({
                path: `/api/target_reports/indicators/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single indicator instance. The same fields are returned with the list view, the main use of this endpoint is to get the description of an indicator if needed.
         *
         * @tags target_reports
         * @name TargetReportsIndicatorsDetailRead
         * @summary Get an indicator
         * @request GET:/api/target_reports/indicators/{id}/
         * @secure
         */
        targetReportsIndicatorsDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<Indicator, any>({
                path: `/api/target_reports/indicators/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all initiative types
         *
         * @tags target_reports
         * @name TargetReportsInitiativeTypesListRead
         * @summary Get initiative types
         * @request GET:/api/target_reports/initiative_types/
         * @secure
         */
        targetReportsInitiativeTypesListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedInitiativeTypeList, any>({
                path: `/api/target_reports/initiative_types/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all insights. Nested many-to-many fields are not shown. Foreign key objects are represented only by pk. Use `target_reports_reports_insights_list` to get a fully-serialized list (using the same serializer as the insight detail view) for listing all insights in a report.
         *
         * @tags target_reports
         * @name TargetReportsInsightsListRead
         * @summary Get insights
         * @request GET:/api/target_reports/insights/
         * @secure
         */
        targetReportsInsightsListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Opportunity ID to filter on. */
                opportunity?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedDeepInsightList, any>({
                path: `/api/target_reports/insights/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Creates a new Insight with the specified field. Only `opportunity` and `report` are required. `stage`, `status`, and `timeline` must be one of the pre-defined choices from the insight choices view.
         *
         * @tags target_reports
         * @name TargetReportsInsightsCreate
         * @summary Create an insight
         * @request POST:/api/target_reports/insights/
         * @secure
         */
        targetReportsInsightsCreate: (data: InsightWrite, params: RequestParams = {}) =>
            this.request<DeepInsight, any>({
                path: `/api/target_reports/insights/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single insight based on insight id with optional team_id param for additional SPD data
         *
         * @tags target_reports
         * @name TargetReportsInsightsDetailRead
         * @summary Get a single insight
         * @request GET:/api/target_reports/insights/{id}/
         * @secure
         */
        targetReportsInsightsDetailRead: (
            id: number,
            query?: {
                /** Team ID */
                team_id?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<DeepInsight, any>({
                path: `/api/target_reports/insights/${id}/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Performs a partial update on the Insight instance with the specified fields. No fields are actually required to be specified, as long as the updated object does not violate any constraints. If `target_owners` is specified, the insight's owners will be completely replaced with the ones provided.
         *
         * @tags target_reports
         * @name TargetReportsInsightsPartialUpdate
         * @summary Partially update an insight
         * @request PATCH:/api/target_reports/insights/{id}/
         * @secure
         */
        targetReportsInsightsPartialUpdate: (
            id: number,
            data: PatchedInsightWrite,
            params: RequestParams = {}
        ) =>
            this.request<DeepInsight, any>({
                path: `/api/target_reports/insights/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the insight instance
         *
         * @tags target_reports
         * @name TargetReportsInsightsDestroy
         * @summary Delete an insight
         * @request DELETE:/api/target_reports/insights/{id}/
         * @secure
         */
        targetReportsInsightsDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/insights/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description List valid options for choice fields for an insight which are not implemented as foreign key relations. Can be used for populating drop-downs. Pulls choices directly from the Insight model, so as new choices are added there in the code, this view will update accordingly. Returns an object with the choices for choice fields (stage, status, timeline). Status choices depend on the stage (it was decided that this didn't need to be enforced at the database level), the keys of the `status` object are stages and the values lists of choices. Note that this view only has tests for permissions, as the functionality is straightforward and there would be no meaningful tests that didn't amount to reproducing the exact code.
         *
         * @tags target_reports
         * @name TargetReportsInsightsChoicesRetrieve
         * @summary Get allowed values for insight choice fields
         * @request GET:/api/target_reports/insights/choices/
         * @secure
         */
        targetReportsInsightsChoicesRetrieve: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/insights/choices/`,
                method: "GET",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a paginated list of all notes
         *
         * @tags target_reports
         * @name TargetReportsNotesList
         * @summary Get notes
         * @request GET:/api/target_reports/notes/
         * @secure
         */
        targetReportsNotesList: (
            query?: {
                /** Insight ID to filter on. */
                insight?: number;
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedNotesList, any>({
                path: `/api/target_reports/notes/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsNotesCreate
         * @summary Create a note
         * @request POST:/api/target_reports/notes/
         * @secure
         */
        targetReportsNotesCreate: (data: Notes, params: RequestParams = {}) =>
            this.request<Notes, any>({
                path: `/api/target_reports/notes/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single note
         *
         * @tags target_reports
         * @name TargetReportsNotesRetrieve
         * @summary Get a note
         * @request GET:/api/target_reports/notes/{id}/
         * @secure
         */
        targetReportsNotesRetrieve: (id: number, params: RequestParams = {}) =>
            this.request<Notes, any>({
                path: `/api/target_reports/notes/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update an existing note with the fields required. No fields are actually required in the request body.
         *
         * @tags target_reports
         * @name TargetReportsNotesPartialUpdate
         * @summary Partially update a note
         * @request PATCH:/api/target_reports/notes/{id}/
         * @secure
         */
        targetReportsNotesPartialUpdate: (
            id: number,
            data: PatchedNotes,
            params: RequestParams = {}
        ) =>
            this.request<Notes, any>({
                path: `/api/target_reports/notes/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the note instance
         *
         * @tags target_reports
         * @name TargetReportsNotesDestroy
         * @summary Delete a note
         * @request DELETE:/api/target_reports/notes/{id}/
         * @secure
         */
        targetReportsNotesDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/notes/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a paginated list of all opportunities.
         *
         * @tags target_reports
         * @name TargetReportsOpportunitiesListRead
         * @summary Get opportunities
         * @request GET:/api/target_reports/opportunities/
         * @secure
         */
        targetReportsOpportunitiesListRead: (
            query?: {
                /** Report ID to filter on. */
                report?: number;
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead[], any>({
                path: `/api/target_reports/opportunities/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Creates a new Opportunity with the specified fields. `name` and `state_code` are required and should be derived from the linked owner, the rest are optional but recommended. `team` should be derived from the report the opportunity is delivered in .
         *
         * @tags target_reports
         * @name TargetReportsOpportunitiesCreate
         * @summary Create an opportunity
         * @request POST:/api/target_reports/opportunities/
         * @secure
         */
        targetReportsOpportunitiesCreate: (
            data: OpportunityCreate,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/opportunities/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single opportunity instance
         *
         * @tags target_reports
         * @name TargetReportsOpportunitiesDetailRead
         * @summary Get a website
         * @request GET:/api/target_reports/opportunities/{id}/
         * @secure
         */
        targetReportsOpportunitiesDetailRead: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/opportunities/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Performs a partial update on the Opportunity instance with the specified fields. No fields are actually required to be specified, as long as the updated object does not violate any constraints. If `indicators` is specified, the opportunity's indicators will be completely replaced with the ones provided, in the specified order.
         *
         * @tags target_reports
         * @name TargetReportsOpportunitiesPartialUpdate
         * @summary Partially update an opportunity
         * @request PATCH:/api/target_reports/opportunities/{id}/
         * @secure
         */
        targetReportsOpportunitiesPartialUpdate: (
            id: number,
            data: PatchedOpportunityPartialUpdate,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/opportunities/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the opportunity instance
         *
         * @tags target_reports
         * @name TargetReportsOpportunitiesDestroy
         * @summary Delete an opportunity
         * @request DELETE:/api/target_reports/opportunities/{id}/
         * @secure
         */
        targetReportsOpportunitiesDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/opportunities/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_feedback/
         * @secure
         */
        targetReportsPaginatedTableListRead: (
            query?: {
                /** Multiple values may be separated by commas. */
                feedback_name?: string[];
                /** Number of results to return per page. */
                limit?: number;
                max_total_insights?: number;
                min_total_insights?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                search_text?: string;
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableFeedbackList, any>({
                path: `/api/target_reports/paginated_table/all_feedback/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_feedback/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableFeedback, any>({
                path: `/api/target_reports/paginated_table/all_feedback/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_feedback/items/
         * @secure
         */
        targetReportsPaginatedTableItems: (params: RequestParams = {}) =>
            this.request<PaginatedTableFeedback, any>({
                path: `/api/target_reports/paginated_table/all_feedback/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead2
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_indicators/
         * @secure
         */
        targetReportsPaginatedTableListRead2: (
            query?: {
                /** Multiple values may be separated by commas. */
                indicator?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** Multiple values may be separated by commas. */
                market?: string[];
                max_total_insights?: number;
                min_total_insights?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** Multiple values may be separated by commas. */
                rank?: string[];
                search_text?: string;
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableIndicatorList, any>({
                path: `/api/target_reports/paginated_table/all_indicators/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters2
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_indicators/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters2: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableIndicator, any>({
                path: `/api/target_reports/paginated_table/all_indicators/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems2
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_indicators/items/
         * @secure
         */
        targetReportsPaginatedTableItems2: (params: RequestParams = {}) =>
            this.request<PaginatedTableIndicator, any>({
                path: `/api/target_reports/paginated_table/all_indicators/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead3
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_owners/
         * @secure
         */
        targetReportsPaginatedTableListRead3: (
            query?: {
                /** Multiple values may be separated by commas. */
                fiscal_start?: string[];
                /** Number of results to return per page. */
                limit?: number;
                max_population?: number;
                max_total_insights?: number;
                min_population?: number;
                min_total_insights?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** Multiple values may be separated by commas. */
                owner?: string[];
                /** Multiple values may be separated by commas. */
                rank?: string[];
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableOwnerList, any>({
                path: `/api/target_reports/paginated_table/all_owners/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters3
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_owners/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters3: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableOwner, any>({
                path: `/api/target_reports/paginated_table/all_owners/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems3
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_owners/items/
         * @secure
         */
        targetReportsPaginatedTableItems3: (params: RequestParams = {}) =>
            this.request<PaginatedTableOwner, any>({
                path: `/api/target_reports/paginated_table/all_owners/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead4
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_team_insights/
         * @secure
         */
        targetReportsPaginatedTableListRead4: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/all_team_insights/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters4
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_team_insights/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters4: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/all_team_insights/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems4
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_team_insights/items/
         * @secure
         */
        targetReportsPaginatedTableItems4: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/all_team_insights/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead5
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_users/
         * @secure
         */
        targetReportsPaginatedTableListRead5: (
            query?: {
                /** Multiple values may be separated by commas. */
                assignee?: string[];
                /** Number of results to return per page. */
                limit?: number;
                max_total_insights?: number;
                min_total_insights?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** Multiple values may be separated by commas. */
                sales_manager?: string[];
                search_text?: string;
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableUserList, any>({
                path: `/api/target_reports/paginated_table/all_users/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters5
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_users/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters5: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableUser, any>({
                path: `/api/target_reports/paginated_table/all_users/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems5
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/all_users/items/
         * @secure
         */
        targetReportsPaginatedTableItems5: (params: RequestParams = {}) =>
            this.request<PaginatedTableUser, any>({
                path: `/api/target_reports/paginated_table/all_users/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead6
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/my_insights/
         * @secure
         */
        targetReportsPaginatedTableListRead6: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/my_insights/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters6
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/my_insights/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters6: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/my_insights/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems6
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/my_insights/items/
         * @secure
         */
        targetReportsPaginatedTableItems6: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/my_insights/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead7
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/priority_insights/
         * @secure
         */
        targetReportsPaginatedTableListRead7: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/priority_insights/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters7
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/priority_insights/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters7: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/priority_insights/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems7
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/priority_insights/items/
         * @secure
         */
        targetReportsPaginatedTableItems7: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/priority_insights/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead8
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_feedback/
         * @secure
         */
        targetReportsPaginatedTableListRead8: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                feedback?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/single_feedback/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters8
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_feedback/{team_id}/{feedback}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters8: (
            feedback: string,
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_feedback/${teamId}/${feedback}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems8
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_feedback/items/
         * @secure
         */
        targetReportsPaginatedTableItems8: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_feedback/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead9
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_indicator/
         * @secure
         */
        targetReportsPaginatedTableListRead9: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                indicator_id?: number;
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/single_indicator/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters9
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_indicator/{team_id}/{indicator_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters9: (
            indicatorId: string,
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_indicator/${teamId}/${indicatorId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems9
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_indicator/items/
         * @secure
         */
        targetReportsPaginatedTableItems9: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_indicator/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead10
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_owner/
         * @secure
         */
        targetReportsPaginatedTableListRead10: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                owner_id?: number;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/single_owner/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters10
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_owner/{team_id}/{owner_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters10: (
            ownerId: string,
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_owner/${teamId}/${ownerId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems10
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_owner/items/
         * @secure
         */
        targetReportsPaginatedTableItems10: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_owner/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead11
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_user/
         * @secure
         */
        targetReportsPaginatedTableListRead11: (
            query?: {
                /** @format date */
                display_date_after?: string;
                /** @format date */
                display_date_before?: string;
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                /** Which field to use when ordering the results. */
                ordering?: string;
                priority_insight?: boolean;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
                team_id?: number;
                user_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableInsightList, any>({
                path: `/api/target_reports/paginated_table/single_user/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters11
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_user/{team_id}/{user_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters11: (
            teamId: string,
            userId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_user/${teamId}/${userId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the item IDs for the given Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableItems11
         * @summary GET list of item IDs for Paginated Table
         * @request GET:/api/target_reports/paginated_table/single_user/items/
         * @secure
         */
        targetReportsPaginatedTableItems11: (params: RequestParams = {}) =>
            this.request<PaginatedTableInsight, any>({
                path: `/api/target_reports/paginated_table/single_user/items/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of items for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableListRead12
         * @summary GET items for Paginated Table
         * @request GET:/api/target_reports/paginated_table/team_activity/
         * @secure
         */
        targetReportsPaginatedTableListRead12: (
            query?: {
                /** Multiple values may be separated by commas. */
                email?: string[];
                is_active?: boolean;
                /** Number of results to return per page. */
                limit?: number;
                /** Multiple values may be separated by commas. */
                name?: string[];
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                search_text?: string;
                team_id?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedPaginatedTableTeamActivityList, any>({
                path: `/api/target_reports/paginated_table/team_activity/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the Paginated Table
         *
         * @tags target_reports
         * @name TargetReportsPaginatedTableFilters12
         * @summary GET filters for Paginated Table
         * @request GET:/api/target_reports/paginated_table/team_activity/{team_id}/filters/
         * @secure
         */
        targetReportsPaginatedTableFilters12: (
            teamId: string,
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTableTeamActivity, any>({
                path: `/api/target_reports/paginated_table/team_activity/${teamId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns all opportunities based on the Team ID
         *
         * @tags target_reports
         * @name TargetReportsOpportunityListRead
         * @summary Get All Opportunities (Not yet implemented)
         * @request GET:/api/target_reports/report_opportunities/
         * @secure
         */
        targetReportsOpportunityListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedOpportunityReadList, any>({
                path: `/api/target_reports/report_opportunities/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Using standard mixins, responses from write methods (POST/PUT/PATCH) will be serialized using the same serializer used to serialize the request data. In some cases, it is helpful to have the write response use a different serializer, for instance the one that would be used by a read request. For example, we want to POST the following data: { "date": "2020-01-01", "status": "In Progress", "notes": "Fun stuff", "team": 1 } but we want to get the following data back: { "date": "2020-01-01", "status": "In Progress", "notes": "Fun stuff", "team": { "id": 1 "name": "Cool people", } } Writes will happen using the serializer class returned by `.get_input_serializer_class()`, `.input_serializer_class`, `.get_serializer_class()`, or `.serializer_class` if any are not defined, in that order. Responses will use the serializer class returned by `.get_output_serializer_class()`, `.output_serializer_class`, `.get_serializer_class()`, or `.serializer_class` if any not defined, in that order.
         *
         * @tags target_reports
         * @name TargetReportsReportOpportunitiesCreate
         * @request POST:/api/target_reports/report_opportunities/
         * @secure
         */
        targetReportsReportOpportunitiesCreate: (
            data: OpportunityRead,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/report_opportunities/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single opportunity based on the Team ID
         *
         * @tags target_reports
         * @name TargetReportsOpportunityRetrieveRead
         * @summary Get Single Opportunity
         * @request GET:/api/target_reports/report_opportunities/{id}/
         * @secure
         */
        targetReportsOpportunityRetrieveRead: (
            id: number,
            query?: {
                /** Opportunity feed type to query corresponding next and previous id */
                feed_type?: string;
                /** Team ID */
                team_id?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/report_opportunities/${id}/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Using standard mixins, responses from write methods (POST/PUT/PATCH) will be serialized using the same serializer used to serialize the request data. In some cases, it is helpful to have the write response use a different serializer, for instance the one that would be used by a read request. For example, we want to POST the following data: { "date": "2020-01-01", "status": "In Progress", "notes": "Fun stuff", "team": 1 } but we want to get the following data back: { "date": "2020-01-01", "status": "In Progress", "notes": "Fun stuff", "team": { "id": 1 "name": "Cool people", } } Writes will happen using the serializer class returned by `.get_input_serializer_class()`, `.input_serializer_class`, `.get_serializer_class()`, or `.serializer_class` if any are not defined, in that order. Responses will use the serializer class returned by `.get_output_serializer_class()`, `.output_serializer_class`, `.get_serializer_class()`, or `.serializer_class` if any not defined, in that order.
         *
         * @tags target_reports
         * @name TargetReportsReportOpportunitiesUpdate
         * @request PUT:/api/target_reports/report_opportunities/{id}/
         * @secure
         */
        targetReportsReportOpportunitiesUpdate: (
            id: number,
            data: OpportunityRead,
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/report_opportunities/${id}/`,
                method: "PUT",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Partial update an Opportunity model
         *
         * @tags target_reports
         * @name TargetReportsOpportunityPatchUpdate
         * @summary Partial Update Single Opportunity
         * @request PATCH:/api/target_reports/report_opportunities/{id}/
         * @secure
         */
        targetReportsOpportunityPatchUpdate: (
            id: number,
            data: PatchedOpportunityRead,
            query?: {
                /** Team ID */
                team_id?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OpportunityRead, any>({
                path: `/api/target_reports/report_opportunities/${id}/`,
                method: "PATCH",
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of users for each reaction where users have left feedback for the given opportunity
         *
         * @tags target_reports
         * @name TargetReportsOpportunityFeedbackUsersRetrieve
         * @summary Get Opportunity Feedback Users
         * @request GET:/api/target_reports/report_opportunities/{id}/retrieve_feedback_users/
         * @secure
         */
        targetReportsOpportunityFeedbackUsersRetrieve: (
            id: number,
            query?: {
                /** Team ID */
                team_id?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OpportunityFeedbackUsers, any>({
                path: `/api/target_reports/report_opportunities/${id}/retrieve_feedback_users/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all reports
         *
         * @tags target_reports
         * @name TargetReportsReportsListRead
         * @summary Get reports
         * @request GET:/api/target_reports/reports/
         * @secure
         */
        targetReportsReportsListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Status to filter on. */
                status?:
                    | "Delivered"
                    | "In Progress"
                    | "Peer-reviewed"
                    | "Self-reviewed";
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedShallowReportReadList, any>({
                path: `/api/target_reports/reports/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Creates a new Report with the specified fields. All but `notes` are required, and `date` must be in `YYYY-MM-DD` format. `status` must be one of the valid report status choices, and `team` must be the primary key of an existing team. Allowed status choices are: - `In Progress` - `Self-reviewed` - `Peer-reviewed` - `Delivered`
         *
         * @tags target_reports
         * @name TargetReportsReportsCreate
         * @summary Create a report
         * @request POST:/api/target_reports/reports/
         * @secure
         */
        targetReportsReportsCreate: (data: ReportWrite, params: RequestParams = {}) =>
            this.request<DeepReportRead, any>({
                path: `/api/target_reports/reports/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single report instance
         *
         * @tags target_reports
         * @name TargetReportsReportsDetailRead
         * @summary Get a report
         * @request GET:/api/target_reports/reports/{id}/
         * @secure
         */
        targetReportsReportsDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<DeepReportRead, any>({
                path: `/api/target_reports/reports/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update an existing report with the fields required. No fields are actually required in the request body.
         *
         * @tags target_reports
         * @name TargetReportsReportsPartialUpdate
         * @summary Partially update a report
         * @request PATCH:/api/target_reports/reports/{id}/
         * @secure
         */
        targetReportsReportsPartialUpdate: (
            id: number,
            data: PatchedReportWrite,
            params: RequestParams = {}
        ) =>
            this.request<DeepReportRead, any>({
                path: `/api/target_reports/reports/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all Opportunity Sales Stages
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesList
         * @summary Get opportunity sales stage instances
         * @request GET:/api/target_reports/sales_stages/
         * @secure
         */
        targetReportsSalesStagesList: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Opportunity ID to filter on. */
                opportunity?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedOpportunitySalesStageReadList, any>({
                path: `/api/target_reports/sales_stages/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesCreate
         * @summary Create an Opportunity Sales Stage
         * @request POST:/api/target_reports/sales_stages/
         * @secure
         */
        targetReportsSalesStagesCreate: (
            data: OpportunitySalesStageWrite,
            params: RequestParams = {}
        ) =>
            this.request<OpportunitySalesStageWrite, any>({
                path: `/api/target_reports/sales_stages/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns an opportunity Sales Stage
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesRetrieve
         * @summary Get an opportunity Sales Stage
         * @request GET:/api/target_reports/sales_stages/{id}/
         * @secure
         */
        targetReportsSalesStagesRetrieve: (id: number, params: RequestParams = {}) =>
            this.request<OpportunitySalesStageRead, any>({
                path: `/api/target_reports/sales_stages/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update an existing note with the fields required. No fields are actually required in the request body.
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesPartialUpdate
         * @summary Partially update an opportunity Sales Stage
         * @request PATCH:/api/target_reports/sales_stages/{id}/
         * @secure
         */
        targetReportsSalesStagesPartialUpdate: (
            id: number,
            data: PatchedOpportunitySalesStageWrite,
            params: RequestParams = {}
        ) =>
            this.request<OpportunitySalesStageWrite, any>({
                path: `/api/target_reports/sales_stages/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the opportunity Sales Stage instance
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesDestroy
         * @summary Delete an opportunity Sales Stage
         * @request DELETE:/api/target_reports/sales_stages/{id}/
         * @secure
         */
        targetReportsSalesStagesDestroy: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/sales_stages/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a paginated list of all Sales Stages options
         *
         * @tags target_reports
         * @name TargetReportsSalesStagesOptionsList
         * @summary Get Sales Stage options
         * @request GET:/api/target_reports/sales_stages_options/
         * @secure
         */
        targetReportsSalesStagesOptionsList: (params: RequestParams = {}) =>
            this.request<SalesStage[], any>({
                path: `/api/target_reports/sales_stages_options/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all SearchPlans
         *
         * @tags target_reports
         * @name TargetReportsSearchPlanListRead
         * @summary Get SearchPlan list
         * @request GET:/api/target_reports/search_plan_list/
         * @secure
         */
        targetReportsSearchPlanListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedSearchPlanReadList, any>({
                path: `/api/target_reports/search_plan_list/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the requested Search Plan Summary
         *
         * @tags target_reports
         * @name TargetReportsSearchPlanSummaryRetrieve
         * @summary Retrieve the Search Plan Summary
         * @request GET:/api/target_reports/search_plan_summary/{uuid}/
         * @secure
         */
        targetReportsSearchPlanSummaryRetrieve: (
            uuid: string,
            params: RequestParams = {}
        ) =>
            this.request<SearchPlanSummaryRead, any>({
                path: `/api/target_reports/search_plan_summary/${uuid}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of SharedOpportunities for a given team, report, or IF
         *
         * @tags target_reports
         * @name TargetReportsSharedOpportunitiesListRead
         * @summary List SharedOpportunities
         * @request GET:/api/target_reports/shared_opportunities/
         * @secure
         */
        targetReportsSharedOpportunitiesListRead: (
            query?: {
                /** Indicator Group ID to filter on. */
                indicator_group?: number;
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Report ID to filter on. */
                report?: number;
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedSharedOpportunitiesInFeedList, any>({
                path: `/api/target_reports/shared_opportunities/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Create a SharedOpportunities instance with the given Opportunity IDs, etc.
         *
         * @tags target_reports
         * @name TargetReportsSharedOpportunitiesCreate
         * @summary Create SharedOpportunities
         * @request POST:/api/target_reports/shared_opportunities/
         * @secure
         */
        targetReportsSharedOpportunitiesCreate: (
            data: SharedOpportunitiesWrite,
            params: RequestParams = {}
        ) =>
            this.request<SharedOpportunitiesWrite, any>({
                path: `/api/target_reports/shared_opportunities/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a single SharedOpportunities instance based on the SharedOpportunities ID
         *
         * @tags target_reports
         * @name TargetReportsSharedOpportunitiesRetrieve
         * @summary Get Single SharedOpportunities
         * @request GET:/api/target_reports/shared_opportunities/{uuid}/
         * @secure
         */
        targetReportsSharedOpportunitiesRetrieve: (
            uuid: string,
            params: RequestParams = {}
        ) =>
            this.request<SharedOpportunitiesRead, any>({
                path: `/api/target_reports/shared_opportunities/${uuid}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_reports
         * @name TargetReportsSharedOpportunitiesDestroy
         * @request DELETE:/api/target_reports/shared_opportunities/{uuid}/
         * @secure
         */
        targetReportsSharedOpportunitiesDestroy: (
            uuid: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/api/target_reports/shared_opportunities/${uuid}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a list of Insights for the Single Report page only
         *
         * @tags target_reports
         * @name TargetReportsSingleReportPageListRead
         * @summary GET Insights for Single Report page
         * @request GET:/api/target_reports/single_report/
         * @secure
         */
        targetReportsSingleReportPageListRead: (
            query?: {
                /** Multiple values may be separated by commas. */
                indicator_groups?: string[];
                /** Number of results to return per page. */
                limit?: number;
                max_total_population?: number;
                min_total_population?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Multiple values may be separated by commas. */
                opportunity_owner?: string[];
                opportunity_updated?: boolean;
                /** Which field to use when ordering the results. */
                ordering?: string;
                report_id?: number;
                search_text?: string;
                /** Multiple values may be separated by commas. */
                state_code?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedSingleReportPageInsightsList, any>({
                path: `/api/target_reports/single_report/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the filters for the given Report
         *
         * @tags target_reports
         * @name TargetReportsSingleReportFilters
         * @summary GET filters for the Single Report page
         * @request GET:/api/target_reports/single_report/{report_id}/filters/
         * @secure
         */
        targetReportsSingleReportFilters: (
            reportId: string,
            params: RequestParams = {}
        ) =>
            this.request<SingleReportPageFilters, any>({
                path: `/api/target_reports/single_report/${reportId}/filters/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns the Opportunity IDs for the given Report
         *
         * @tags target_reports
         * @name TargetReportsSingleReportOpportunities
         * @summary GET list of Opportunity IDs for Single Report page
         * @request GET:/api/target_reports/single_report/opportunities/
         * @secure
         */
        targetReportsSingleReportOpportunities: (params: RequestParams = {}) =>
            this.request<SingleReportPageInsights, any>({
                path: `/api/target_reports/single_report/opportunities/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a paginated list of all sources
         *
         * @tags target_reports
         * @name TargetReportsSourcesListRead
         * @summary Get sources
         * @request GET:/api/target_reports/sources/
         * @secure
         */
        targetReportsSourcesListRead: (
            query?: {
                /** Insight ID to filter on. */
                insight?: number;
                /** Multiple values may be separated by commas. */
                insightIn?: number[];
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Opportunity ID to filter on. */
                opportunity?: number;
                /** Multiple values may be separated by commas. */
                opportunityIn?: number[];
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedSourceList, any>({
                path: `/api/target_reports/sources/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Creates a new source. All currently available fields are required except extract
         *
         * @tags target_reports
         * @name TargetReportsSourcesCreate
         * @summary Create a source
         * @request POST:/api/target_reports/sources/
         * @secure
         */
        targetReportsSourcesCreate: (data: Source, params: RequestParams = {}) =>
            this.request<Source, any>({
                path: `/api/target_reports/sources/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the source instance
         *
         * @tags target_reports
         * @name TargetReportsSourcesDestroy
         * @summary Delete a source
         * @request DELETE:/api/target_reports/sources/
         * @secure
         */
        targetReportsSourcesDestroy: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/sources/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Performs a partial update on the source. No fields are actually required.
         *
         * @tags target_reports
         * @name TargetReportsSourcesPartialUpdate
         * @summary Partially update a source
         * @request PATCH:/api/target_reports/sources/{id}/
         * @secure
         */
        targetReportsSourcesPartialUpdate: (
            id: number,
            data: PatchedSource,
            params: RequestParams = {}
        ) =>
            this.request<Source, any>({
                path: `/api/target_reports/sources/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Deletes the source instance. This is the method for removing a source from an insight.
         *
         * @tags target_reports
         * @name TargetReportsSourcesDestroy2
         * @summary Delete a source
         * @request DELETE:/api/target_reports/sources/{id}/
         * @secure
         */
        targetReportsSourcesDestroy2: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/sources/${id}/`,
                method: "DELETE",
                secure: true,
                ...params,
            }),

        /**
         * @description Create a SyncToCRM instance with the given opportunity ids
         *
         * @tags target_reports
         * @name TargetReportsSyncToCrmCreate
         * @summary Create SyncToCRM
         * @request POST:/api/target_reports/sync_to_crm/
         * @secure
         */
        targetReportsSyncToCrmCreate: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/target_reports/sync_to_crm/`,
                method: "POST",
                secure: true,
                ...params,
            }),

        /**
         * @description Returns a paginated list of all TeamCustomerTags
         *
         * @tags target_reports
         * @name TargetReportsTeamCustomerTagListRead
         * @summary Get TeamCustomerTags
         * @request GET:/api/target_reports/team_customer_tags/
         * @secure
         */
        targetReportsTeamCustomerTagListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Team ID to filter on. */
                team?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedBaseTeamCustomerTagList, any>({
                path: `/api/target_reports/team_customer_tags/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of timing statuses and stages used in the timeline modal.
         *
         * @tags target_reports
         * @name Timelines
         * @summary Get All Timing Statuses
         * @request GET:/api/target_reports/timing_statuses/
         * @secure
         */
        timelines: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedTimingStatusesList, any>({
                path: `/api/target_reports/timing_statuses/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags target_surfacer
         * @name TargetSurfacerBatchCreate
         * @request POST:/api/target_surfacer/batch/
         * @secure
         */
        targetSurfacerBatchCreate: (
            data: BatchSearchRecord,
            params: RequestParams = {}
        ) =>
            this.request<BatchSearchRecord, any>({
                path: `/api/target_surfacer/batch/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Get search records Returns a paginated list of all search records
         *
         * @tags target_surfacer
         * @name TargetSurfacerSearchRecordsList
         * @request GET:/api/target_surfacer/search_records/
         * @secure
         */
        targetSurfacerSearchRecordsList: (
            query?: {
                /** Search universe indices to filter on */
                indices?: string;
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Which field to use when ordering the results. */
                ordering?: string;
                /** Query text to filter on */
                queries?: string;
                /** Team ID to filter on. */
                team?: number;
                /** Profile ID to filter on */
                user?: number;
                /**
                 * When date to filter on (ex. 2021-02-04)
                 * @format date
                 */
                when?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedSearchRecordReadList, any>({
                path: `/api/target_surfacer/search_records/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Create a search record
         *
         * @tags target_surfacer
         * @name TargetSurfacerV2SearchCreate
         * @request POST:/api/target_surfacer/v2/search/
         * @secure
         */
        targetSurfacerV2SearchCreate: (data: BaseSearch, params: RequestParams = {}) =>
            this.request<BaseSearch, any>({
                path: `/api/target_surfacer/v2/search/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Get owners Returns a (paginated) list of all serialized Owner objects, with nested `legacy_owner_whids` and `websites`
         *
         * @tags universe
         * @name UniverseCompaniesListRead
         * @request GET:/api/universe/companies/
         * @secure
         */
        universeCompaniesListRead: (
            query?: {
                /** Filter by Contact ID */
                contact?: number;
                /** Number of results to return per page. */
                limit?: number;
                /** Company name. Filters company whose name string contains `name`. */
                name?: string;
                /** The initial index from which to return the results. */
                offset?: number;
                /** A search term. */
                search?: string;
                /** URL of a company website. */
                url?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedCompanyList, any>({
                path: `/api/universe/companies/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get contacts Returns a paginated list of all contacts, with appropriate filters applied.
         *
         * @tags universe
         * @name UniverseContactsListRead
         * @request GET:/api/universe/contacts/
         * @secure
         */
        universeContactsListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** A search term. */
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedShallowContactReadList, any>({
                path: `/api/universe/contacts/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get a contact Returns a single contact instance.
         *
         * @tags universe
         * @name UniverseContactDetailRead
         * @request GET:/api/universe/contacts/{id}/
         * @secure
         */
        universeContactDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<ShallowContactRead, any>({
                path: `/api/universe/contacts/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get demographics Returns a (paginated) list of all serialized Demographic objects
         *
         * @tags universe
         * @name UniverseDemographicsListRead
         * @request GET:/api/universe/demographics/
         * @secure
         */
        universeDemographicsListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Filter by Owner ID */
                owner?: number;
                /** A search term. */
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedDeepDemographicReadList, any>({
                path: `/api/universe/demographics/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get a demographic Returns a single serialized Demographic instance
         *
         * @tags universe
         * @name UniverseDemographicsDetailRead
         * @request GET:/api/universe/demographics/{id}/
         * @secure
         */
        universeDemographicsDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<DeepDemographicRead, any>({
                path: `/api/universe/demographics/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update a demographic. Many-to-many fields are currently not supported.
         *
         * @tags universe
         * @name UniverseDemographicsPartialUpdate
         * @request PATCH:/api/universe/demographics/{id}/
         * @secure
         */
        universeDemographicsPartialUpdate: (
            id: number,
            data: PatchedDemographicWrite,
            params: RequestParams = {}
        ) =>
            this.request<DeepDemographicRead, any>({
                path: `/api/universe/demographics/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Get drinking water systems Returns a paginated list of all serialized drinking water systems, with appropriate filters applied.
         *
         * @tags universe
         * @name UniverseDrinkingWaterSystemListRead
         * @request GET:/api/universe/drinking_water_systems/
         * @secure
         */
        universeDrinkingWaterSystemListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** DWS name. Filters drinking water systems whose name string contains `name`. */
                name?: string;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Filter by Owner ID */
                owner?: number;
                /** A search term. */
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedShallowDrinkingWaterSystemReadList, any>({
                path: `/api/universe/drinking_water_systems/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Search for legacy owner whids Returns a (paginated) list of serialized LegacyOwnerWhid objects matching filter criteria, with nested owner references.
         *
         * @tags universe
         * @name UniverseLegacyOwnerWhidsList
         * @request GET:/api/universe/legacy_owner_whids/
         * @secure
         */
        universeLegacyOwnerWhidsList: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Old WHID (geo or utility WHID) to filter on. */
                old_whid?: string;
                /** Owner ID to filter on. */
                owner?: number;
                /** Owner WHID to filter on. */
                owner_whid?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedLegacyOwnerWhidReadList, any>({
                path: `/api/universe/legacy_owner_whids/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Record a new legacy owner whid Creates a new `LegacyOwnerWhid` object with `old_whid`, pointing to the owner specified by `id`. Will raise `HTTP 400 Bad Request` in the event that old_whid is not a valid whid, and `HTTP 400 Bad Request` in the event that `owner` is not found in the Owner table.
         *
         * @tags universe
         * @name UniverseLegacyOwnerWhidsCreate
         * @request POST:/api/universe/legacy_owner_whids/
         * @secure
         */
        universeLegacyOwnerWhidsCreate: (
            data: LegacyOwnerWhidCreate,
            params: RequestParams = {}
        ) =>
            this.request<LegacyOwnerWhidCreate, any>({
                path: `/api/universe/legacy_owner_whids/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Get owners Returns a (paginated) list of all serialized Owner objects, with nested `legacy_owner_whids` and `websites`
         *
         * @tags universe
         * @name UniverseOwnersListRead
         * @request GET:/api/universe/owners/
         * @secure
         */
        universeOwnersListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** Legal name. Filters owners whose legal name string contains `name`. */
                name?: string;
                /** The initial index from which to return the results. */
                offset?: number;
                /** A search term. */
                search?: string;
                /** State code */
                state?: string;
                /** Owner WHID. Multiple values can be specified in a comma-separated list, with no spaces. */
                whid?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedOwnerList, any>({
                path: `/api/universe/owners/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get an owner Returns a single serialized Owner instance, with nested `legacy_owner_whids` and `websites`.
         *
         * @tags universe
         * @name UniverseOwnersDetailRead
         * @request GET:/api/universe/owners/{id}/
         * @secure
         */
        universeOwnersDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<Owner, any>({
                path: `/api/universe/owners/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get tags Returns a paginated list of all serialized tags, with appropriate filters applied.
         *
         * @tags universe
         * @name UniverseTagsListRead
         * @request GET:/api/universe/tags/
         * @secure
         */
        universeTagsListRead: (
            query?: {
                /** Filter by tag value. Performs an exact lookup. */
                value?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<TagRead[], any>({
                path: `/api/universe/tags/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get drinking water systems Returns a paginated list of all serialized drinking water systems, with appropriate filters applied.
         *
         * @tags universe
         * @name UniverseWastewaterFacilityListRead
         * @request GET:/api/universe/wastewater_facilities/
         * @secure
         */
        universeWastewaterFacilityListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Filter by Owner ID */
                owner?: number;
                /** A search term. */
                search?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedShallowWastewaterFacilityList, any>({
                path: `/api/universe/wastewater_facilities/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get websites Returns a paginated list of all serialized websites, with appropriate filters applied.
         *
         * @tags universe
         * @name UniverseWebsitesListRead
         * @request GET:/api/universe/websites/
         * @secure
         */
        universeWebsitesListRead: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
                /** Filter by `owners__whid` */
                owner_whid?: string;
                /** Filter by `tags__value` */
                tag?: string;
                /** Get only websites with url exactly `url`. This will be a list with exactly 0 or 1 elements. */
                url?: string;
                /** Softer lookup in the database, excluding the scheme and trailing slashes. This is the conflict check that is made when creating a new website. Generally, only one of `url` or `url_like` should be set. */
                url_like?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedWebsiteReadList, any>({
                path: `/api/universe/websites/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Create a website - Performs a similarity lookup in the database on the provided `<url>` without scheme and trailing slash, returning `HTTP 400` and an object with an array `conflicts` of objects conflicting website objects. - If the similarity lookup passes, creates a new Website object. Returns the new serialized object.
         *
         * @tags universe
         * @name UniverseWebsitesCreate
         * @request POST:/api/universe/websites/
         * @secure
         */
        universeWebsitesCreate: (data: WebsiteWrite, params: RequestParams = {}) =>
            this.request<WebsiteRead, any>({
                path: `/api/universe/websites/`,
                method: "POST",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Get a website Returns a single serialized website instance.
         *
         * @tags universe
         * @name UniverseWebsitesDetailRead
         * @request GET:/api/universe/websites/{id}/
         * @secure
         */
        universeWebsitesDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<WebsiteRead, any>({
                path: `/api/universe/websites/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Update a website. Can be used to update the URL field, or set the owner and tag linkages. If an `owners` or `tags` array of IDs in provided, the website's owner or tag set is completely replaced with the ones provided.
         *
         * @tags universe
         * @name UniverseWebsitesPartialUpdate
         * @request PATCH:/api/universe/websites/{id}/
         * @secure
         */
        universeWebsitesPartialUpdate: (
            id: number,
            data: PatchedWebsiteWrite,
            params: RequestParams = {}
        ) =>
            this.request<WebsiteRead, any>({
                path: `/api/universe/websites/${id}/`,
                method: "PATCH",
                body: data,
                secure: true,
                type: ContentType.Json,
                format: "json",
                ...params,
            }),

        /**
         * @description Minimalistic view to return the username of a user
         *
         * @tags user_data
         * @name UserDataGetUsernameRetrieve
         * @request GET:/api/user_data/get_username/{id}/
         * @secure
         */
        userDataGetUsernameRetrieve: (id: number, params: RequestParams = {}) =>
            this.request<UserName, any>({
                path: `/api/user_data/get_username/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of all products
         *
         * @tags user_data
         * @name UserDataSidebarLayoutAllProductsListRead
         * @summary GET all products
         * @request GET:/api/user_data/sidebar_layout/products/
         * @secure
         */
        userDataSidebarLayoutAllProductsListRead: (params: RequestParams = {}) =>
            this.request<Product[], any>({
                path: `/api/user_data/sidebar_layout/products/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a list of all teams a user can see
         *
         * @tags user_data
         * @name UserDataSidebarLayoutAllTeamsListRead
         * @summary GET Teams for the given user
         * @request GET:/api/user_data/sidebar_layout/teams/
         * @secure
         */
        userDataSidebarLayoutAllTeamsListRead: (params: RequestParams = {}) =>
            this.request<SidebarLayoutAllTeams[], any>({
                path: `/api/user_data/sidebar_layout/teams/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Returns a detail view for team by id
         *
         * @tags user_data
         * @name UserDataSidebarLayoutSingleTeamDetailRead
         * @summary GET team based on id (only if user can access it)
         * @request GET:/api/user_data/sidebar_layout/teams/{id}/
         * @secure
         */
        userDataSidebarLayoutSingleTeamDetailRead: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<SidebarLayoutSingleTeam, any>({
                path: `/api/user_data/sidebar_layout/teams/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get the logged in user profile Who Am I view to return information about the current logged in user, or a response with status 401 if the user is not logged in Returns a serialized object of the logged in user's `Profile`, or a response with status 401 if the user is not logged in
         *
         * @tags user_data
         * @name UserDataSidebarLayoutWhoamiRead
         * @summary GET the current user's user and team information
         * @request GET:/api/user_data/sidebar_layout/whoami/
         * @secure
         */
        userDataSidebarLayoutWhoamiRead: (params: RequestParams = {}) =>
            this.request<SidebarLayoutProfile, any>({
                path: `/api/user_data/sidebar_layout/whoami/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get teams Returns a paginated list of all teams. Nested profiles are not included.
         *
         * @tags user_data
         * @name UserDataTeamsListRead
         * @request GET:/api/user_data/teams/
         * @secure
         */
        userDataTeamsListRead: (params: RequestParams = {}) =>
            this.request<ShallowTeam[], any>({
                path: `/api/user_data/teams/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get a team Returns a single team. Nested profiles and users are included.
         *
         * @tags user_data
         * @name UserDataTeamsDetailRead
         * @request GET:/api/user_data/teams/{id}/
         * @secure
         */
        userDataTeamsDetailRead: (id: number, params: RequestParams = {}) =>
            this.request<FullTeam, any>({
                path: `/api/user_data/teams/${id}/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * No description
         *
         * @tags user_data
         * @name UserDataUsersList
         * @request GET:/api/user_data/users/
         * @secure
         */
        userDataUsersList: (
            query?: {
                /** Number of results to return per page. */
                limit?: number;
                /** The initial index from which to return the results. */
                offset?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<PaginatedProfileList, any>({
                path: `/api/user_data/users/`,
                method: "GET",
                query: query,
                secure: true,
                format: "json",
                ...params,
            }),

        /**
         * @description Get the logged in user profile Who Am I view to return information about the current logged in user, or a response with status 401 if the user is not logged in Returns a serialized object of the logged in user's `Profile`, or a response with status 401 if the user is not logged in
         *
         * @tags user_data
         * @name UserDataWhoamiRetrieve
         * @request GET:/api/user_data/whoami/
         * @secure
         */
        userDataWhoamiRetrieve: (params: RequestParams = {}) =>
            this.request<Profile, any>({
                path: `/api/user_data/whoami/`,
                method: "GET",
                secure: true,
                format: "json",
                ...params,
            }),
    };
}
