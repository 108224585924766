import { Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    useLocation,
    useParams,
    Link as RouterLink,
    useRouteMatch,
} from "react-router-dom";

import { StyledButton } from "components/atoms";
import { ActivityLogPanel } from "components/cdv/ActivityLogPanel";
import AddContactModal from "components/cdv/AddContactModal";
import AddDrinkingWaterSystemModal from "components/cdv/AddDrinkingWaterSystemModal";
import AddIndicatorModal from "components/cdv/AddIndicatorModal";
import AddIntelligenceModal from "components/cdv/AddIntelligenceModal";
import AddLocationModal from "components/cdv/AddLocationModal";
import AddSalesStageModal from "components/cdv/AddSalesStageModal";
import AddWastewaterFacilityModal from "components/cdv/AddWastewaterFacilityModal";
import { AIEmailAssistantButton } from "components/cdv/AIEmailAssistantButton";
import { AIEmailAssistantInputModal } from "components/cdv/AIEmailAssistantInputModal";
import CompetitorModal from "components/cdv/CompetitorModal";
import ConsultantModal from "components/cdv/ConsultantModal";
import ContactPanel from "components/cdv/ContactPanel";
import CreateInsightModal from "components/cdv/CreateInsightModal";
import CreateSourceModal from "components/cdv/CreateSourceModal";
import { DeliveryStatusModal } from "components/cdv/DeliveryStatusModal";
import EditDrinkingWaterSystemModal from "components/cdv/EditDrinkingWaterSystemModal";
import EditIndicatorModal from "components/cdv/EditIndicatorModal";
import EditInsightModal from "components/cdv/EditInsightModal";
import EditOpportunityModal from "components/cdv/EditOpportunityModal";
import EditOpportunityOwnerModal from "components/cdv/EditOpportunityOwnerModal";
import EditRemoveContactModal from "components/cdv/EditRemoveContactModal";
import EditSalesStageModal from "components/cdv/EditSalesStageModal";
import EditSourceModal from "components/cdv/EditSourceModal";
import EditWastewaterFacilityModal from "components/cdv/EditWastewaterFacilityModal";
import { IntelligenceNotesModal } from "components/cdv/IntelligenceNotesModal";
import { LogActivityModal } from "components/cdv/LogActivityModal";
import { OpportunityAssigneeModal } from "components/cdv/OpportunityAssigneeModal";
import OpportunityCard from "components/cdv/OpportunityCard";
import RemoveLocationModal from "components/cdv/RemoveLocationModal";
import RemoveOpportunityModal from "components/cdv/RemoveOpportunityModal";
import { SelectedInsightsButton } from "components/cdv/SelectedInsightsButton";
import ShareOpportunitiesModal from "components/cdv/ShareOpportunitiesModal";
import { SyncOpportunitiesToCRMModal } from "components/cdv/SyncOpportunitiesToCRMModal";
import FormattedTag from "components/cdv/TagsList/FormattedTag";
import TeamCustomerTagsModal from "components/cdv/TeamCustomerTagsModal";
import { TimelineModal } from "components/cdv/TimelineModal";
import { BreadcrumbProps } from "components/molecules/Breadcrumbs";
import SidebarLayout from "components/organisms/SidebarLayout";
import { IntelligenceFeedsTemplate } from "components/templates/IntelligenceFeedsTemplate";
import { FEED_TYPES } from "constants/feedTypes";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { isErrorDueTo404 } from "network";
import { IntelligenceReportsSingleOpportunityPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleOpportunity";
import {
    SingleOpportunityBodyType,
    useOpportunityBodyStyles,
} from "pages/OpportunityDetails/OpportunityBody";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetAllActions } from "reactQuery/hooks/useActions";
import { useGetFilteredActionsTaken } from "reactQuery/hooks/useActionsTaken";
import { useGetOpportunity } from "reactQuery/hooks/useOpportunity";
import { useGetTableItemIds } from "reactQuery/hooks/useTableData";
import {
    AllFeedbackSingleOpportunityPathname,
    AllIndicatorsSingleOpportunityPathname,
    AllInsightsSingleOpportunityPathname,
    AllOwnersSingleOpportunityPathname,
    AssignedInsightsSingleOpportunityPathname,
    MyInsightsSingleOpportunityPathname,
    PriorityInsightsSingleOpportunityPathname,
} from "routes/IntelligenceFeeds";
import { selectInsightsForActiveOpportunity } from "stores/insights/selectors";
import {
    fetchOpportunityActionsTakenByFilter,
    fetchOpportunityById,
    fetchOpportunitySalesStagesByFilter,
    setActiveOpportunity,
} from "stores/opportunities/actions";
import {
    activeOpportunityIdSelector,
    activeOpportunitySelector,
    isChangingOpportunitySalesStageSelector,
    isChangingOpportunityActionsTakenSelector,
    selectFetchErrorMap,
} from "stores/opportunities/selectors";
import { activeReportIdSelector, setActiveTeam } from "stores/reports/reportSlice";
import { toggleEditMode } from "stores/uiStore/actionTypes";
import { isEditModeActiveSelector } from "stores/uiStore/selectors";
import { fetchTeamById } from "stores/userStore/actionTypes";

import { allInsightsBreadcrumbs } from "../AllInsights";
import { myInsightsBreadcrumbs } from "../MyInsights";
import { priorityInsightsBreadcrumbs } from "../PriorityInsights";
import { singleAssigneeBreadcrumbs } from "../SingleAssignee";
import { singleFeedbackBreadcrumbs } from "../SingleFeedback";
import { singleIndicatorBreadcrumbs } from "../SingleIndicator";
import { singleOwnerBreadcrumbs } from "../SingleOwner";

export const TEST_IDS = {
    linearProgress: "linear-progress",
};

const SingleOpportunityBody = ({
    opportunityType,
    activeReportId,
    opportunity,
    teamId,
    opportunityId,
    isEditModeActive,
    toggleEditMode,
    activeInsights,
    viewType,
}: SingleOpportunityBodyType) => {
    const actionsTakenQuery = useGetFilteredActionsTaken({
        opportunityId: opportunityId,
        teamId: teamId,
    });
    const actionsTaken = actionsTakenQuery?.data?.results ?? [];

    const whoamiQuery = useGetWhoami();
    const isStaff = whoamiQuery.data?.user.is_staff ?? false;

    const [shareOpportunitiesModalVisibility, setShareOpportunitiesModalVisibility] =
        useState(false);
    const [
        SyncOpportunitiesToCRMModalVisibility,
        setSyncOpportunitiesToCRMModalVisibility,
    ] = useState(false);
    const [
        AIEmailAssistantInputModalVisibility,
        setAIEmailAssistantInputModalVisibility,
    ] = useState(false);
    const [
        AIEmailAssistantInputModalContactId,
        setAIEmailAssistantInputModalContactId,
    ] = useState();

    const styles = useOpportunityBodyStyles();
    const { pathname, search } = useLocation();
    const opportunityIdsQuery = useGetTableItemIds(viewType);
    const opportunityIds = opportunityIdsQuery.data ?? ([] as Number[]);
    let nextLink = null;
    let prevLink = null;

    let currentOpportunityIndex = 0;
    const rank = opportunity?.opportunity_rank
        ? opportunity?.opportunity_rank == "Priority - Medium"
            ? "Priority"
            : "Feeds"
        : null;

    useEffect(() => {}, [opportunityIds]);
    if (opportunityIds && opportunityIds.includes(opportunityId)) {
        currentOpportunityIndex = opportunityIds.indexOf(opportunityId);
        const nextId = opportunityIds[currentOpportunityIndex + 1];
        const prevId = opportunityIds[currentOpportunityIndex - 1];

        if (nextId) {
            nextLink = (pathname + search).replace(
                /(opportunity\/)(\d+)/,
                (match, p1) => p1 + nextId
            );
        }
        if (prevId) {
            prevLink = (pathname + search).replace(
                /(opportunity\/)(\d+)/,
                (match, p1) => p1 + prevId
            );
        }
    }

    const contacts = activeInsights.map((insight) => insight.contacts).flat();

    return (
        <div
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 25,
            }}
        >
            <ShareOpportunitiesModal
                handleClose={() => setShareOpportunitiesModalVisibility(false)}
                opportunityIds={[opportunity.id]}
                opportunityCount={1}
                reportId={
                    opportunityType === OpportunityTypeEnum.REPORT
                        ? activeReportId
                        : null
                }
                teamId={teamId}
                visible={shareOpportunitiesModalVisibility}
            />

            <SyncOpportunitiesToCRMModal
                handleClose={() => setSyncOpportunitiesToCRMModalVisibility(false)}
                singleOpportunityContext={{
                    opportunityOwner: opportunity.owner.legal_name,
                    stateCode: opportunity.owner.state_code,
                    indicatorValue:
                        opportunity.insights?.[0]?.indicator_groups?.[0]?.indicator
                            ?.value,
                    crmSyncStatus: opportunity.crm_sync_status,
                    opportunityId: opportunity.id,
                }}
                visible={SyncOpportunitiesToCRMModalVisibility}
                teamId={teamId}
            />

            <AIEmailAssistantInputModal
                opportunityId={opportunity.id}
                teamId={teamId}
                contacts={contacts}
                contactId={AIEmailAssistantInputModalContactId}
                setAIEmailAssistantInputModalVisibility={
                    setAIEmailAssistantInputModalVisibility
                }
                visible={AIEmailAssistantInputModalVisibility}
            />

            <div
                className={styles.bodyHeader}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div>
                    {isStaff && opportunityType !== OpportunityTypeEnum.REPORT ? (
                        <Button
                            href={`${IntelligenceReportsSingleOpportunityPathname}?opportunity_id=${opportunity.id}&team_id=${opportunity.team.id}`}
                            type="ghost"
                            target="_blank"
                        >
                            Original Opportunity
                        </Button>
                    ) : null}
                </div>

                <div className={styles.buttons}>
                    {rank ? <FormattedTag>{rank}</FormattedTag> : null}

                    <AIEmailAssistantButton
                        teamId={teamId}
                        onClick={() => {
                            setAIEmailAssistantInputModalVisibility(true);
                        }}
                    />
                    <SelectedInsightsButton
                        disabled={false}
                        shareOpportunitiesOnClick={() =>
                            setShareOpportunitiesModalVisibility(true)
                        }
                        teamId={teamId}
                        MUIButtonStyle={true}
                        syncOpportunitiesToCRMOnClick={() =>
                            setSyncOpportunitiesToCRMModalVisibility(true)
                        }
                    />

                    {isStaff && opportunityType === OpportunityTypeEnum.REPORT ? (
                        <StyledButton
                            variant="page-action"
                            handleClick={() => {
                                toggleEditMode();
                            }}
                        >
                            {isEditModeActive ? "Preview" : "Edit"}
                        </StyledButton>
                    ) : null}
                </div>
            </div>
            <div className={styles.wrapper}>
                {prevLink ? (
                    <>
                        <ChevronLeft style={{ fill: "grey", float: "right" }} />
                        <Link
                            className={styles.navigatePrev}
                            to={prevLink}
                            component={RouterLink}
                        >
                            VIEW PREVIOUS
                        </Link>
                    </>
                ) : null}

                {nextLink ? (
                    <>
                        <Link
                            className={styles.navigateNext}
                            to={nextLink}
                            component={RouterLink}
                        >
                            VIEW NEXT
                        </Link>
                        <ChevronRight style={{ fill: "grey", float: "right" }} />
                    </>
                ) : null}
            </div>
            <Grid container spacing={3} className={styles.bodyGrid}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                    <OpportunityCard
                        allowEdit={
                            opportunityType === OpportunityTypeEnum.INDICATOR
                                ? false
                                : true
                        }
                        opportunity={opportunity}
                        opportunityType={opportunityType}
                        reportId={activeReportId}
                        teamId={teamId}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                    <ActivityLogPanel
                        actionsTaken={actionsTaken}
                        assignees={opportunity?.assignees}
                        hasOtherAssignee={opportunity?.other_assignee ? true : false}
                    />
                    <ContactPanel
                        insights={opportunity?.insights}
                        opportunity={opportunity}
                        setAIEmailAssistantInputModalVisibility={
                            setAIEmailAssistantInputModalVisibility
                        }
                        setAIEmailAssistantInputModalContactId={
                            setAIEmailAssistantInputModalContactId
                        }
                        isEditModeActive={
                            opportunityType === OpportunityTypeEnum.REPORT
                                ? isEditModeActive
                                : false
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export const UnconnectedSingleOpportunity = ({
    activeOpportunityId,
    activeReportId,
    isEditModeActive,
    isChangingSalesStagesHistory,
    isChangingActionsTakenHistory,
    setActiveTeam,
    toggleEditMode,
    setActiveOpportunity,
    fetchOpportunityById,
    fetchOpportunitySalesStagesByFilter,
    fetchOpportunityActionsTakenByFilter,
    fetchOpportunitiesForReport,
    fetchTeamById,
    didFetchResultIn404,
    activeInsights,
}) => {
    const { search, pathname } = useLocation();
    const { opportunity_id: opportunityIdString } = useParams();
    const opportunityId = parseInt(opportunityIdString) || null;
    let { path: routePath } = useRouteMatch();
    const page = pathname.split("/")[2];
    const teamId = Number(new URLSearchParams(search).get("team_id"));
    const viewType =
        page === "all_insights"
            ? FEED_TYPES.ALL_TEAM_INSIGHTS
            : page === "priority"
            ? FEED_TYPES.PRIORITY_INSIGHTS
            : page === "all_indicators"
            ? FEED_TYPES.SINGLE_INDICATOR
            : page === "all_owners"
            ? FEED_TYPES.SINGLE_OWNER
            : page === "assigned_insights"
            ? FEED_TYPES.SINGLE_ASSIGNEE
            : page === "all_feedback"
            ? FEED_TYPES.SINGLE_FEEDBACK
            : page === "my_insights"
            ? FEED_TYPES.MY_INSIGHTS
            : null;

    let breadcrumbs: BreadcrumbProps[] = [];
    if (routePath === AllFeedbackSingleOpportunityPathname) {
        breadcrumbs = singleFeedbackBreadcrumbs();
    }
    if (routePath === AllOwnersSingleOpportunityPathname) {
        breadcrumbs = singleOwnerBreadcrumbs();
    }
    if (routePath === AssignedInsightsSingleOpportunityPathname) {
        breadcrumbs = singleAssigneeBreadcrumbs();
    }
    if (routePath === AllIndicatorsSingleOpportunityPathname) {
        breadcrumbs = singleIndicatorBreadcrumbs();
    }
    if (routePath === AllInsightsSingleOpportunityPathname) {
        breadcrumbs = allInsightsBreadcrumbs();
    }
    if (routePath === PriorityInsightsSingleOpportunityPathname) {
        breadcrumbs = priorityInsightsBreadcrumbs();
    }
    if (routePath === MyInsightsSingleOpportunityPathname) {
        breadcrumbs = myInsightsBreadcrumbs();
    }

    const opportunityQuery = useGetOpportunity({
        opportunityId: opportunityId,
        feedType: viewType,
        teamId: teamId,
    });
    const opportunity = opportunityQuery.data;
    const actionsQuery = useGetAllActions();
    const actionsData = actionsQuery.data?.results ?? null;
    breadcrumbs.push({ name: opportunity?.name, url: `${pathname}${search}` });

    const opportunityType =
        opportunity?.opportunity_legacy_insight_type === "IR"
            ? OpportunityTypeEnum.REPORT
            : opportunity?.opportunity_legacy_insight_type === "IF"
            ? OpportunityTypeEnum.INDICATOR
            : OpportunityTypeEnum.INDICATOR;

    useEffect(() => {
        if (opportunityType === OpportunityTypeEnum.REPORT) {
            if (activeOpportunityId && activeOpportunityId !== opportunityId) {
                setActiveOpportunity({
                    opportunityId: opportunityId,
                });
                fetchOpportunityById({ opportunityId: opportunityId, teamId: teamId });
            }
            if (!activeOpportunityId && opportunityId) {
                setActiveOpportunity({ opportunityId: opportunityId });
                fetchOpportunityById({ opportunityId: opportunityId, teamId: teamId });
            }
            if (activeOpportunityId) {
                fetchOpportunitySalesStagesByFilter({
                    opportunityId: activeOpportunityId,
                });
            }
        } else if (opportunityType === OpportunityTypeEnum.INDICATOR) {
            if (activeOpportunityId && activeOpportunityId !== opportunityId) {
                setActiveOpportunity({
                    opportunityId: opportunityId,
                });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                });
            }
            if (!activeOpportunityId && opportunityId) {
                setActiveOpportunity({ opportunityId: opportunityId });
                fetchOpportunityById({
                    opportunityId: opportunityId,
                    teamId: teamId,
                });
            }
        }
        setActiveTeam({ teamId: teamId });
        fetchTeamById(teamId);
    }, [
        activeOpportunityId,
        fetchOpportunityById,
        fetchTeamById,
        fetchOpportunitySalesStagesByFilter,
        fetchOpportunityActionsTakenByFilter,
        fetchOpportunitiesForReport,
        opportunityType,
        isChangingSalesStagesHistory,
        isChangingActionsTakenHistory,
        setActiveTeam,
        opportunityId,
        setActiveOpportunity,
        activeReportId,
        opportunity,
    ]);

    const notFoundMessage =
        didFetchResultIn404 || opportunityQuery.status === "error" ? (
            <p>Opportunity not found!</p>
        ) : null;
    const isCorrectOpportunitySelected = opportunity
        ? opportunityId === opportunity.id
        : false;

    const opportunityBody = !didFetchResultIn404 ? (
        isCorrectOpportunitySelected && teamId && opportunity ? (
            <SingleOpportunityBody
                opportunityType={opportunityType}
                activeReportId={activeReportId}
                opportunity={opportunity}
                teamId={teamId}
                viewType={viewType}
                opportunityId={opportunityId}
                isEditModeActive={isEditModeActive}
                toggleEditMode={toggleEditMode}
                activeInsights={activeInsights}
            />
        ) : (
            <LinearProgress data-testid={TEST_IDS.linearProgress} />
        )
    ) : null;
    const body = notFoundMessage ?? opportunityBody;

    return (
        <>
            {/* TODO: Hide modals behind isStaff flag to speed up loading for external users */}
            <IntelligenceNotesModal />
            <CompetitorModal />
            <LogActivityModal allActions={actionsData} opportunityId={opportunityId} />
            <AddIntelligenceModal />
            <AddLocationModal />
            <AddWastewaterFacilityModal />
            <AddIndicatorModal />
            <AddSalesStageModal />
            <CreateInsightModal />
            <AddContactModal />
            <EditRemoveContactModal />
            <CreateSourceModal />
            <AddDrinkingWaterSystemModal />
            <TimelineModal />
            <ConsultantModal />
            <EditDrinkingWaterSystemModal />
            <EditInsightModal />
            <EditIndicatorModal />
            <DeliveryStatusModal />
            <OpportunityAssigneeModal
                opportunity={opportunity}
                opportunityType={opportunityType}
            />
            <EditOpportunityOwnerModal />
            <EditOpportunityModal />
            <RemoveOpportunityModal />
            <EditSourceModal />
            {/* @ts-ignore */}
            <TeamCustomerTagsModal teamId={teamId} />
            <EditWastewaterFacilityModal />
            <EditSalesStageModal />
            <RemoveLocationModal />
            <SidebarLayout
                content={
                    <IntelligenceFeedsTemplate breadcrumbs={breadcrumbs}>
                        {body}
                    </IntelligenceFeedsTemplate>
                }
            />
        </>
    );
};

const mapStateToProps = (state, props) => {
    return {
        activeOpportunityId: activeOpportunityIdSelector(state),
        didFetchResultIn404: isErrorDueTo404(
            selectFetchErrorMap(state)[activeOpportunitySelector(state as never)]
        ),
        isEditModeActive: isEditModeActiveSelector(state),
        isChangingSalesStagesHistory: isChangingOpportunitySalesStageSelector(state),
        isChangingActionsTakenHistory: isChangingOpportunityActionsTakenSelector(state),
        activeReportId:
            activeReportIdSelector(state) ||
            parseInt(localStorage.getItem("activeReport")) ||
            null,
        activeInsights: selectInsightsForActiveOpportunity(state as never),
    };
};

const ConnectedSingleOpportunity = connect(mapStateToProps, {
    toggleEditMode,
    setActiveTeam,
    setActiveOpportunity,
    fetchOpportunityById,
    fetchTeamById,
    fetchOpportunitySalesStagesByFilter,
    fetchOpportunityActionsTakenByFilter,
})(UnconnectedSingleOpportunity);

export default ConnectedSingleOpportunity;
