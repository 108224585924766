import { Breadcrumbs, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { UseQueryResult } from "@tanstack/react-query";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { StyledButton } from "components/atoms";
import { ActivityLogPanel } from "components/cdv/ActivityLogPanel";
import { AIEmailAssistantButton } from "components/cdv/AIEmailAssistantButton";
import { AIEmailAssistantInputModal } from "components/cdv/AIEmailAssistantInputModal";
import ContactPanel from "components/cdv/ContactPanel";
import OpportunityCard from "components/cdv/OpportunityCard";
import SalesStagePanel from "components/cdv/SalesStagePanel";
import { SelectedInsightsButton } from "components/cdv/SelectedInsightsButton";
import ShareOpportunitiesModal from "components/cdv/ShareOpportunitiesModal";
import { SyncOpportunitiesToCRMModal } from "components/cdv/SyncOpportunitiesToCRMModal";
import { additionalColors } from "constants/colors";
import { formatDayHyphenShortenedMonthHyphenYear } from "constants/formatters";
import { OpportunityTypeEnum } from "constants/opportunityTypes";
import { IntelligenceReportsAllInsightsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import { IntelligenceReportsAllReportsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllReports";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";
import {
    DeepInsight,
    IndicatorGroup,
    OpportunityRead,
    ProductTypeEnum,
    ShallowTeam,
} from "reactQuery/hooks/apiTypes";
import { useGetAllInsightsOpportunities } from "reactQuery/hooks/pages/useAllInsights";
import { useGetIndicatorFeedOpportunities } from "reactQuery/hooks/pages/useIndicatorFeed";
import { useGetSingleTeam } from "reactQuery/hooks/pages/useSidebarLayout";
import { useGetSingleReportOpportunities } from "reactQuery/hooks/pages/useSingleReport";
import { useGetFilteredActionsTaken } from "reactQuery/hooks/useActionsTaken";
import {
    IndicatorFeedsAllFeedsPathname,
    IndicatorFeedsSingleFeedPathname,
} from "routes/IndicatorFeeds";
import { isIntelligenceFeedsRoute } from "utils/redirect";

export const useOpportunityBodyStyles = makeStyles((theme) => ({
    bodyHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
    },
    bodyHeaderLeft: {
        flexGrow: 1,
    },
    breadcrumbLink: {
        color: additionalColors.marineBlue,
    },
    bodyGrid: {
        paddingTop: theme.spacing(3),
    },
    wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        paddingTop: "16px",
        alignItems: "center",
    },
    navigateNext: {
        marginLeft: "30px",
        color: theme.palette.grey[500],
        fontFamily: "Montserrat",
    },
    navigatePrev: {
        marginRight: "30px",
        color: theme.palette.grey[500],
        fontFamily: "Montserrat",
    },
    buttons: {
        display: "flex",
        gap: "8px",
    },
}));

export interface SingleOpportunityBodyType {
    opportunityType: OpportunityTypeEnum;
    activeReportId: number;
    opportunity: OpportunityRead;
    teamId: number;
    viewType: string;
    opportunityId: number;
    isEditModeActive: boolean;
    toggleEditMode: any;
    activeInsights: DeepInsight[];
}
export interface OpportunityBodyType {
    opportunityType: OpportunityTypeEnum;
    indicatorGroupId: number;
    activeReportId: number;
    opportunity: OpportunityRead;
    team: ShallowTeam;
    viewType: string;
    opportunityId: string;
    indicatorGroupQuery: UseQueryResult<IndicatorGroup>;
    canEditReport: boolean;
    isEditModeActive: boolean;
    toggleEditMode: any;
    activeInsights: DeepInsight[];
}
export const OpportunityBody = ({
    opportunityType,
    indicatorGroupId,
    activeReportId,
    opportunity,
    team,
    viewType,
    opportunityId,
    indicatorGroupQuery,
    canEditReport,
    isEditModeActive,
    toggleEditMode,
    activeInsights,
}: OpportunityBodyType) => {
    const { pathname, search } = useLocation();

    const actionsTakenQuery = useGetFilteredActionsTaken({
        opportunityId: parseInt(opportunityId),
        teamId: team ? team.id : null,
    });
    const actionsTaken = actionsTakenQuery?.data?.results ?? [];

    const [prevNextEnabled, setPrevNextEnabled] = useState<boolean>(false);

    const allInsightsOpportunitiesQuery = useGetAllInsightsOpportunities({
        team_id: team?.id,
        enabled:
            opportunityType === OpportunityTypeEnum.REPORT &&
            viewType === "allInsights" &&
            !!team?.id
                ? canEditReport
                    ? prevNextEnabled
                    : true
                : false,
    });
    const singleReportOpportunitiesQuery = useGetSingleReportOpportunities({
        report_id: activeReportId,
        enabled:
            opportunityType === OpportunityTypeEnum.REPORT &&
            viewType === "intelligenceReports" &&
            !!activeReportId,
    });
    const indicatorFeedOpportunitiesQuery = useGetIndicatorFeedOpportunities({
        indicator_group_id: indicatorGroupId,
        enabled:
            opportunityType === OpportunityTypeEnum.INDICATOR && !!indicatorGroupId,
    });
    const opportunitiesQuery =
        opportunityType === OpportunityTypeEnum.REPORT && viewType === "allInsights"
            ? allInsightsOpportunitiesQuery
            : opportunityType === OpportunityTypeEnum.REPORT &&
              viewType === "intelligenceReports"
            ? singleReportOpportunitiesQuery
            : opportunityType === OpportunityTypeEnum.INDICATOR
            ? indicatorFeedOpportunitiesQuery
            : undefined;
    const opportunityIds = opportunitiesQuery.data ?? ([] as Number[]);

    const [shareOpportunitiesModalVisibility, setShareOpportunitiesModalVisibility] =
        useState(false);
    const [
        SyncOpportunitiesToCRMModalVisibility,
        setSyncOpportunitiesToCRMModalVisibility,
    ] = useState(false);
    const [
        AIEmailAssistantInputModalVisibility,
        setAIEmailAssistantInputModalVisibility,
    ] = useState(false);
    const [
        AIEmailAssistantInputModalContactId,
        setAIEmailAssistantInputModalContactId,
    ] = useState();

    let nextLink = null;
    let prevLink = null;
    let currentOpportunityIndex = 0;

    if (opportunityIds && opportunityIds.includes(parseInt(opportunityId))) {
        currentOpportunityIndex = opportunityIds.indexOf(parseInt(opportunityId));
        const nextId = opportunityIds[currentOpportunityIndex + 1];
        const prevId = opportunityIds[currentOpportunityIndex - 1];

        if (nextId) {
            nextLink = (pathname + search).replace(
                /(opportunity_id=)(\d+)/,
                (match, p1) => p1 + nextId
            );
        }
        if (prevId) {
            prevLink = (pathname + search).replace(
                /(opportunity_id=)(\d+)/,
                (match, p1) => p1 + prevId
            );
        }
    }

    const teamQuery = useGetSingleTeam(team.id);
    const teamWithProducts = teamQuery?.data;
    const indicatorFeedProductName = teamWithProducts?.products?.find(
        (product) => product.product_type === ProductTypeEnum.INDICATOR_FEEDS
    )?.name;
    const intelligenceReportsProductName = teamWithProducts?.products?.find(
        (product) => product.product_type === ProductTypeEnum.INTELLIGENCE_REPORTS
    )?.name;

    const isIntelligenceFeeds = isIntelligenceFeedsRoute();

    const contacts = activeInsights.map((insight) => insight.contacts).flat();

    const styles = useOpportunityBodyStyles();

    return (
        <div
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 25,
            }}
        >
            <ShareOpportunitiesModal
                handleClose={() => setShareOpportunitiesModalVisibility(false)}
                opportunityIds={[opportunity.id]}
                opportunityCount={1}
                reportId={
                    opportunityType === OpportunityTypeEnum.REPORT
                        ? activeReportId
                        : null
                }
                teamId={team?.id}
                visible={shareOpportunitiesModalVisibility}
            />

            <SyncOpportunitiesToCRMModal
                handleClose={() => setSyncOpportunitiesToCRMModalVisibility(false)}
                singleOpportunityContext={{
                    opportunityOwner: opportunity.owner.legal_name,
                    stateCode: opportunity.owner.state_code,
                    indicatorValue:
                        opportunity.insights?.[0]?.indicator_groups?.[0]?.indicator
                            ?.value,
                    crmSyncStatus: opportunity.crm_sync_status,
                    opportunityId: opportunity.id,
                }}
                visible={SyncOpportunitiesToCRMModalVisibility}
                teamId={team?.id}
                indicatorGroupId={indicatorGroupId}
            />

            <AIEmailAssistantInputModal
                opportunityId={opportunity.id}
                teamId={team?.id}
                contacts={contacts}
                contactId={AIEmailAssistantInputModalContactId}
                setAIEmailAssistantInputModalVisibility={
                    setAIEmailAssistantInputModalVisibility
                }
                visible={AIEmailAssistantInputModalVisibility}
            />

            <div className={styles.bodyHeader}>
                <div className={styles.bodyHeaderLeft}>
                    {opportunityType === OpportunityTypeEnum.REPORT ? (
                        viewType === "allInsights" ? (
                            <Breadcrumbs separator="/">
                                <Link
                                    className={styles.breadcrumbLink}
                                    to={`${IntelligenceReportsAllInsightsPathname}?team_id=${team?.id}`}
                                    component={RouterLink}
                                >
                                    All Insights
                                </Link>
                                <Typography color="textPrimary">
                                    {opportunity?.name}
                                </Typography>
                            </Breadcrumbs>
                        ) : viewType === "intelligenceReports" ? (
                            <Breadcrumbs separator="/">
                                <Link
                                    className={styles.breadcrumbLink}
                                    to={`${IntelligenceReportsAllReportsPathname}?team_id=${team?.id}`}
                                    component={RouterLink}
                                >
                                    {intelligenceReportsProductName}
                                </Link>
                                <Link
                                    className={styles.breadcrumbLink}
                                    to={`${IntelligenceReportsSingleReportPathname}?team_id=${team?.id}&report_id=${activeReportId}`}
                                    component={RouterLink}
                                >
                                    {formatDayHyphenShortenedMonthHyphenYear(
                                        opportunity?.report_date
                                    )}
                                </Link>
                                <Typography color="textPrimary">
                                    {opportunity?.name}
                                </Typography>
                            </Breadcrumbs>
                        ) : null
                    ) : opportunityType === OpportunityTypeEnum.INDICATOR ? (
                        <Breadcrumbs separator="/">
                            <Link
                                className={styles.breadcrumbLink}
                                to={`${IndicatorFeedsAllFeedsPathname}?team_id=${team?.id}`}
                                component={RouterLink}
                            >
                                {indicatorFeedProductName}
                            </Link>
                            {indicatorGroupQuery?.data?.indicator?.value && (
                                <Link
                                    className={styles.breadcrumbLink}
                                    to={`${IndicatorFeedsSingleFeedPathname}?team_id=${team?.id}&indicator_group_id=${indicatorGroupId}`}
                                    component={RouterLink}
                                >
                                    {indicatorGroupQuery.data.indicator.value}
                                </Link>
                            )}
                            <Typography color="textPrimary">
                                {`${opportunity?.owner?.legal_name}${
                                    opportunity?.owner?.state_code
                                        ? `, ${opportunity.owner.state_code}`
                                        : ""
                                }`}
                            </Typography>
                        </Breadcrumbs>
                    ) : null}
                </div>

                <div className={styles.buttons}>
                    <AIEmailAssistantButton
                        teamId={team?.id}
                        onClick={() => setAIEmailAssistantInputModalVisibility(true)}
                    />
                    <SelectedInsightsButton
                        disabled={false}
                        shareOpportunitiesOnClick={() =>
                            setShareOpportunitiesModalVisibility(true)
                        }
                        teamId={team?.id}
                        MUIButtonStyle={true}
                        syncOpportunitiesToCRMOnClick={() =>
                            setSyncOpportunitiesToCRMModalVisibility(true)
                        }
                    />
                    {canEditReport && opportunityType === OpportunityTypeEnum.REPORT ? (
                        <StyledButton
                            variant="page-action"
                            handleClick={() => {
                                toggleEditMode();
                            }}
                        >
                            {isEditModeActive ? "Preview" : "Edit"}
                        </StyledButton>
                    ) : null}
                </div>
            </div>
            <div className={styles.wrapper}>
                {canEditReport &&
                opportunityType === OpportunityTypeEnum.REPORT &&
                viewType === "allInsights" &&
                !prevNextEnabled &&
                !opportunitiesQuery.isSuccess ? (
                    <StyledButton
                        variant="add-primary"
                        handleClick={() => {
                            setPrevNextEnabled(true);
                        }}
                    >
                        Load Previous/Next
                    </StyledButton>
                ) : null}
                {prevLink ? (
                    <>
                        <ChevronLeft style={{ fill: "grey", float: "right" }} />
                        <Link
                            className={styles.navigatePrev}
                            to={prevLink}
                            component={RouterLink}
                        >
                            VIEW PREVIOUS
                        </Link>
                    </>
                ) : null}

                {nextLink ? (
                    <>
                        <Link
                            className={styles.navigateNext}
                            to={nextLink}
                            component={RouterLink}
                        >
                            VIEW NEXT
                        </Link>
                        <ChevronRight style={{ fill: "grey", float: "right" }} />
                    </>
                ) : null}
            </div>
            <Grid container spacing={3} className={styles.bodyGrid}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                    <OpportunityCard
                        allowEdit={
                            opportunityType === OpportunityTypeEnum.INDICATOR
                                ? false
                                : true
                        }
                        opportunity={opportunity}
                        opportunityType={opportunityType}
                        reportId={activeReportId}
                        teamId={team?.id}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                    {opportunityType === OpportunityTypeEnum.REPORT ? (
                        <>
                            {!isIntelligenceFeeds ? <SalesStagePanel /> : null}
                            <ActivityLogPanel
                                actionsTaken={actionsTaken}
                                assignees={opportunity?.assignees}
                                hasOtherAssignee={
                                    opportunity?.other_assignee ? true : false
                                }
                            />
                            <ContactPanel
                                insights={opportunity?.insights}
                                opportunity={opportunity}
                                setAIEmailAssistantInputModalVisibility={
                                    setAIEmailAssistantInputModalVisibility
                                }
                                setAIEmailAssistantInputModalContactId={
                                    setAIEmailAssistantInputModalContactId
                                }
                                isEditModeActive={isEditModeActive}
                            />
                        </>
                    ) : opportunityType === OpportunityTypeEnum.INDICATOR ? (
                        <>
                            <ActivityLogPanel
                                actionsTaken={actionsTaken}
                                assignees={opportunity?.assignees}
                                hasOtherAssignee={
                                    opportunity?.other_assignee ? true : false
                                }
                            />
                            <ContactPanel
                                insights={opportunity?.insights}
                                opportunity={opportunity}
                                setAIEmailAssistantInputModalVisibility={
                                    setAIEmailAssistantInputModalVisibility
                                }
                                setAIEmailAssistantInputModalContactId={
                                    setAIEmailAssistantInputModalContactId
                                }
                                isEditModeActive={false}
                            />
                        </>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    );
};
