import { Space, Tag, Button } from "antd";

import StatusTag from "components/atoms/StatusTag";
import { DeliveryStatusTableRowTag } from "components/cdv/DeliveryStatusTableRowTag";
import OpportunityFeedback from "components/molecules/OpportunityFeedback";
import { FEED_TYPES } from "constants/feedTypes";
import {
    SYNC_TO_CRM_ENABLED_TIER_NAME,
    useGetIsTierEnabledForTeam,
} from "reactQuery/hooks/pages/useSidebarLayout";

import { PaginatedTableInsightRecord } from "./types";
import { pathBuilder } from "./utils";

const expandableRowOptions = ({
    relevantId,
    expandedRowKeys,
    feedType,
    history,
    insightTagTitle,
    isStaff,
    styles,
    teamId,
    buttonParams,
}) => {
    const syncOpportunitiesEnabled = useGetIsTierEnabledForTeam({
        teamId: teamId,
        tierName: SYNC_TO_CRM_ENABLED_TIER_NAME,
    });

    return {
        expandedRowRender: (record: PaginatedTableInsightRecord) => {
            return (
                <Space
                    size={20}
                    align={"center"}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {insightTagTitle ? (
                            <Tag style={{ margin: "0 30px" }}>{insightTagTitle}</Tag>
                        ) : null}

                        {syncOpportunitiesEnabled && record.crm_sync_status ? (
                            <div style={{ margin: "0 30px" }}>
                                <StatusTag status={record.crm_sync_status} />
                            </div>
                        ) : null}

                        <div
                            className={[
                                styles.feedbackContainer,
                                "insights-feedback-container",
                            ].join(" ")}
                        >
                            <OpportunityFeedback
                                showAddToCRM={
                                    syncOpportunitiesEnabled !== undefined &&
                                    !syncOpportunitiesEnabled
                                }
                                allowFeedback={!isStaff}
                                opportunityId={record.opportunity_id}
                                teamId={teamId}
                                viewType={feedType}
                            />
                        </div>
                        {feedType === FEED_TYPES.SINGLE_REPORT && isStaff ? (
                            <div style={{ margin: "0 16px" }}>
                                <DeliveryStatusTableRowTag record={record} />
                            </div>
                        ) : undefined}
                    </div>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            localStorage.setItem("path", window.location.href);
                            const path = pathBuilder({
                                excludeQueryParams: buttonParams.excludeQueryParams,
                                id: record[buttonParams.dataKey],
                                includeAllQueryParams:
                                    buttonParams.includeAllQueryParams,
                                path: buttonParams.path,
                                queryParam: buttonParams.pathQueryParam,
                                setQueryParam: buttonParams.setQueryParam,
                            });
                            window.open(path, "_blank");
                        }}
                    >
                        VIEW
                    </Button>
                </Space>
            );
        },
        defaultExpandAllRows: true,
        expandedRowKeys: expandedRowKeys,
    };
};

export default expandableRowOptions;
