import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    PaginatedTableFilteredInfoType,
    paginatedInfoType,
    PaginatedTableSortedInfoType,
} from "types/additionalTypes";

interface queryParamBuilderTypes {
    excludeParams?: string[];
    filters?: Partial<PaginatedTableFilteredInfoType>;
    pagination?: paginatedInfoType;
    sorter?: Partial<PaginatedTableSortedInfoType>;
    searchParams?: URLSearchParams;
    startChar?: string;
    includeBaseParams?: boolean;
    includeExistingParams?: boolean;
    includeDefaultPagination?: boolean;
}

interface updateQueryTypes {
    includeBaseParams?: boolean;
    includeExistingParams?: boolean;
    updatedFilters?: Partial<PaginatedTableFilteredInfoType>;
    updatedPagination?: any;
    updatedSearchText?: string;
    updatedSorters?: Partial<PaginatedTableSortedInfoType>;
}

const baseParams = [
    "team_id",
    "user_id",
    "report_id",
    "indicator_group_id",
    "indicator_id",
    "owner_id",
    "opportunity_id",
    "feedback",
];

const defaultPagination = {
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50, 100],
};

export const queryParamBuilder = ({
    excludeParams = [],
    includeBaseParams = false,
    includeExistingParams = true,
    includeDefaultPagination = false,
    filters = {},
    pagination,
    searchParams,
    startChar = "?",
    sorter = {},
}: queryParamBuilderTypes) => {
    /*
    This function builds a query string based on the provided parameters
    */
    if (!searchParams) {
        // If no searchParams are provided, use the current URL search params
        searchParams = new URLSearchParams(window.location.search);
    }
    const newParams = new URLSearchParams();

    if (includeBaseParams) {
        // Adds base params to the newParams object, and eventually, to the new URL
        baseParams.forEach((key) => {
            if (searchParams.has(key) && !excludeParams.includes(key)) {
                newParams.set(key, searchParams.get(key));
            }
        });
    }

    if (includeExistingParams) {
        // Adds existing URL params to the newParams object, and eventually, to the new URL
        // This excludes ordering (added via the sorter param), baseParams, and excludeParams (both of which are added via their own params)
        if (Object.keys(filters).length === 0) {
            Array.from(searchParams.entries() as Iterable<[string, string]>).map(
                ([key, value]) =>
                    key !== "ordering" &&
                    !baseParams.includes(key) &&
                    !excludeParams.includes(key) &&
                    newParams.set(key, value)
            );
        }
        if (Object.keys(sorter).length === 0 && searchParams.has("ordering")) {
            // If the current URL has an ordering param, but the sorter param is not provided, add the ordering param to the new URL
            newParams.set("ordering", searchParams.get("ordering"));
        }
    }

    Object.entries(filters).forEach(([key, value]) => {
        // Adds filters to the newParams object, and eventually, to the new URL
        // Each filter is added as a key-value pair, where the value depends on the type of the filter
        if (key.endsWith("_updated")) {
            if (!excludeParams.includes(key) && value?.[0] !== undefined) {
                newParams.set(key, value);
            }
        } else if (Array.isArray(value) && value.length > 0) {
            if (
                (key.startsWith("date_") || key.endsWith("_date")) &&
                moment.isMoment(value[0]) &&
                !excludeParams.includes(key)
            ) {
                newParams.set(`${key}_after`, value[0].format("YYYY-MM-DD"));
            }
            if (
                (key.startsWith("date_") || key.endsWith("_date")) &&
                moment.isMoment(value[1]) &&
                !excludeParams.includes(key)
            ) {
                newParams.set(`${key}_before`, value[1].format("YYYY-MM-DD"));
            } else if (value[0]?.min !== undefined || value[0]?.max !== undefined) {
                if (value[0]?.min !== undefined && !excludeParams.includes(key)) {
                    newParams.set(`min_${key}`, value[0].min);
                }
                if (value[0]?.max !== undefined && !excludeParams.includes(key)) {
                    newParams.set(`max_${key}`, value[0].max);
                }
            } else if (!excludeParams.includes(key)) {
                const filteredValues = value.filter(
                    (item) => typeof item === "string" || typeof item === "number"
                );
                if (filteredValues.length) {
                    newParams.set(key, filteredValues.join(","));
                }
            }
        } else if (
            typeof value === "string" &&
            value !== "" &&
            !excludeParams.includes(key)
        ) {
            newParams.set(key, value);
        }
    });

    if (sorter?.field && sorter.order) {
        // Adds sorting to the newParams object, and eventually, to the new URL
        // descending ordering is prefixed with a "-"
        const orderPrefix = sorter.order === "descend" ? "-" : "";
        newParams.set("ordering", `${orderPrefix}${sorter.field}`);
    }

    if (
        includeDefaultPagination ||
        (pagination &&
            ((pagination.current !== undefined &&
                pagination.current !== defaultPagination.current) ||
                (pagination.pageSize !== undefined &&
                    pagination.pageSize !== defaultPagination.pageSize)))
    ) {
        // If includeDefaultPagination is true, or if the pagination param is provided and differs from the default pagination values, add pagination to the new URL
        const currentSizeDefault =
            includeDefaultPagination && searchParams.has("limit")
                ? parseInt(searchParams.get("limit"))
                : defaultPagination.pageSize;
        const currentPageDefault =
            includeDefaultPagination && searchParams.has("offset")
                ? Math.floor(
                      parseInt(searchParams.get("offset")) / currentSizeDefault
                  ) + 1
                : defaultPagination.current;

        const currentPage = pagination?.current ?? currentPageDefault;
        const pageSize = pagination?.pageSize ?? currentSizeDefault;
        const offset = pageSize * (currentPage - 1);
        newParams.set("limit", String(pageSize));
        newParams.set("offset", String(offset));
    }

    return newParams.toString() ? `${startChar}${newParams.toString()}` : "";
};

export const queryObjBuilder = (searchParams = null) => {
    /*
    This function builds an object with the following keys:
    - baseInfo: an object with base parameters (e.g. team_id, etc.)
    - filteredInfo: an object with filters (e.g. date_updated, indicator_id, etc.)
    - paginatedInfo: an object with pagination information (e.g. current page, page size)
    - searchText: a string with the search text
    - sortedInfo: an object with sorting information (e.g. columnKey, field, order)
    */
    let baseInfo = {};
    let filteredInfo = {};
    let paginatedInfo = {} as paginatedInfoType;
    let searchText = null;
    let sortedInfo = {};

    if (!searchParams) {
        searchParams = new URLSearchParams(window.location.search);
    }

    Array.from(searchParams.entries() as Iterable<[string, string]>).map(
        ([key, value]) => {
            if (key === "limit" || key === "offset") {
                if (Object.keys(paginatedInfo).length === 0) {
                    paginatedInfo = {
                        current: defaultPagination.current,
                        pageSize: defaultPagination.pageSize,
                    };
                }
                if (key === "limit") {
                    if (defaultPagination.pageSizeOptions.includes(parseInt(value))) {
                        paginatedInfo.pageSize = parseInt(value);
                    } else {
                        paginatedInfo.pageSize = defaultPagination.pageSize;
                        searchParams.set("limit", defaultPagination.pageSize);
                    }
                } else if (key === "offset") {
                    const limit =
                        searchParams.get("limit") ?? defaultPagination.pageSize;
                    const currentPage = Math.floor(parseInt(value) / limit) + 1;
                    paginatedInfo.current = currentPage;
                }
            } else if (key === "ordering") {
                const order = value[0] === "-" ? "descend" : "ascend";
                sortedInfo = {
                    columnKey: order === "ascend" ? value : value.slice(1),
                    field: order === "ascend" ? value : value.slice(1),
                    order,
                };
            } else if (key === "search_text") {
                if (value !== "") {
                    searchText = value;
                }
            } else if (/_(after|before)$/.test(key)) {
                const [_, dateKey, dateType] = key.match(/(.+?)_(after|before)$/);
                if (!filteredInfo[dateKey]) {
                    filteredInfo[dateKey] = [null, null];
                }
                filteredInfo[dateKey][dateType === "before" ? 1 : 0] = moment(value);
            } else if (/_updated$/.test(key)) {
                filteredInfo[key] = [value === "true" ? String(true) : String(false)];
            } else if (/^(min|max)_/.test(key)) {
                const keyCategory = key.match(/^(min|max)_/)[1];
                const keyName = key.substring(4);
                let existingValue = filteredInfo[keyName] || [{}];
                existingValue[0][keyCategory] = parseInt(value);
                filteredInfo[keyName] = existingValue;
            } else if (!baseParams.includes(key)) {
                filteredInfo[key] = value.split(",");
            } else if (baseParams.includes(key)) {
                baseInfo[key] = value;
            }
        }
    );

    return {
        baseInfo,
        filteredInfo,
        paginatedInfo,
        searchText,
        sortedInfo,
    };
};

export const useManagedQueryParams = () => {
    /*
    This hook manages filters, pagination, text, etc. including their states, as well as updating the URL with the new query parameters
    */
    const history = useHistory();

    const [filteredInfo, setFilteredInfo] = useState<
        Partial<PaginatedTableFilteredInfoType>
    >({});
    const [paginatedInfo, setPaginatedInfo] = useState<paginatedInfoType>({
        current: defaultPagination.current,
        pageSize: defaultPagination.pageSize,
    });
    const [searchTextValue, setSearchTextValue] = useState<string>(null);
    const [sortedInfo, setSortedInfo] = useState<Partial<PaginatedTableSortedInfoType>>(
        {}
    );

    const searchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const { filteredInfo, paginatedInfo, searchText, sortedInfo } =
            queryObjBuilder(searchParams);
        setFilteredInfo(filteredInfo);
        setPaginatedInfo(paginatedInfo);
        setSearchTextValue(searchText);
        setSortedInfo(sortedInfo);
    }, []);

    const updateQuery = ({
        includeBaseParams = true,
        includeExistingParams = true,
        updatedFilters,
        updatedPagination,
        updatedSearchText,
        updatedSorters,
    }: updateQueryTypes) => {
        const newQueryParams = queryParamBuilder({
            filters: {
                ...(updatedFilters ?? filteredInfo),
                search_text: updatedSearchText ?? searchTextValue,
            },
            includeBaseParams,
            includeExistingParams,
            pagination: updatedPagination ?? paginatedInfo,
            searchParams,
            sorter: updatedSorters ?? sortedInfo,
        });

        history.push({ search: newQueryParams });
        setFilteredInfo(updatedFilters ?? filteredInfo);
        setSearchTextValue(updatedSearchText ?? searchTextValue);
        setSortedInfo(updatedSorters ?? sortedInfo);
        setPaginatedInfo(updatedPagination ?? paginatedInfo);
    };

    return {
        defaultPagination,
        filteredInfo,
        paginatedInfo,
        searchTextValue,
        sortedInfo,
        updateQuery,
    };
};
