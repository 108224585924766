import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";

import StatusTag from "components/atoms/StatusTag";
import StyledButton from "components/atoms/StyledButton";
import { formatMonthDayCommaYear } from "constants/formatters";
import { IntelligenceReportsSingleReportPathname } from "pages/IntelligenceReports/IntelligenceReportsSingleReport";
import { IndicatorFeedsSingleFeedPathname } from "routes/IndicatorFeeds";
import { IndicatorFeedItem } from "types/additionalTypes";

import TagsList from "../TagsList";

const customStyles = (cardType: string) =>
    makeStyles({
        cardActions: {
            justifyContent: "space-between",
            padding: "16px",
            height: cardType === "indicator" ? "95px" : "auto",
        },
        exclusionsText: {
            marginLeft: "10px",
            boxDecorationBreak: "clone",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
        },
        indicatorContentText: {
            marginBottom: "10px",
        },
    });

const OverviewCard = ({
    cardType,
    date,
    id,
    feed,
    isStaff,
    status,
    teamId,
}: {
    cardType: string;
    id: string | number;
    date?: string;
    feed?: IndicatorFeedItem;
    isStaff?: boolean;
    status?: string;
    teamId: number;
}) => {
    const style = customStyles(cardType)();
    let actionText, content, title, href;

    if (cardType === "report") {
        href = `${IntelligenceReportsSingleReportPathname}?team_id=${teamId}&report_id=${id}`;

        const formattedDate = formatMonthDayCommaYear(date);
        const chip = isStaff && (
            <div
                style={{
                    float: "right",
                }}
            >
                <StatusTag status={status} />
            </div>
        );
        title = (
            <span>
                {formattedDate} {chip}
            </span>
        );
    } else if (cardType === "indicator") {
        href = `${IndicatorFeedsSingleFeedPathname}?team_id=${teamId}&indicator_group_id=${id}`;

        content = (
            <>
                <Typography className={style.indicatorContentText} variant="body2">
                    Feed of opportunities related to:
                </Typography>
                <TagsList
                    group={feed}
                    showTagsInitiativeTypes={false}
                    hasMarginBottom={false}
                    indicatorType="indicatorGroup"
                />
            </>
        );

        if (feed.exclusions?.length > 0) {
            actionText = (
                <>
                    <Typography variant="body2">Exclusions:</Typography>
                    <Typography className={style.exclusionsText} variant="caption">
                        {feed.exclusions.join(", ")}
                    </Typography>
                </>
            );
        }
    }

    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card raised={true}>
                {title && <CardHeader title={title} />}
                {content && <CardContent>{content}</CardContent>}
                <CardActions className={style.cardActions}>
                    {actionText && <div>{actionText}</div>}
                    <StyledButton
                        variant="add-primary"
                        component={RouterLink}
                        to={href}
                    >
                        View
                    </StyledButton>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default OverviewCard;
