import Text from "components/atoms/Text";
import { getNestedValue } from "utils/getNestedValue";

import HeaderIcons from "./HeaderIcons";
import { PaginatedTableCommonColumnProps } from "../types";
import { pathBuilder } from "../utils";

interface TextColumnProps extends PaginatedTableCommonColumnProps {
    clickParams?: any;
    displayTranslations?: any;
    valueKey?: string;
}

const textColumn = ({
    allowFiltering = true,
    allowSorting = true,
    clickParams,
    displayTranslations = {},
    filteredInfo,
    filters,
    history,
    isLoading,
    key,
    searchTextValue,
    sortedInfo,
    styles,
    title,
    valueKey,
}: TextColumnProps) => {
    const filterOptions = filters
        ? filters?.map((filter) => ({
              text: displayTranslations?.[filter] || filter,
              value: filter,
          }))
        : undefined;
    return {
        title: title,
        dataIndex: key,
        key: key,
        render: (_, record) => {
            const value = valueKey ? getNestedValue(record, valueKey) : record[key];
            if (value) {
                let displayValue = displayTranslations[value] || value;
                return (
                    <div
                        style={clickParams ? { cursor: "pointer" } : {}}
                        onClick={() => {
                            if (clickParams) {
                                const path = pathBuilder({
                                    excludeQueryParams: clickParams.excludeQueryParams,
                                    id: clickParams.dataKey
                                        ? record[key][clickParams.dataKey]
                                        : record[key].id,
                                    path: clickParams.path,
                                    queryParam: clickParams.queryParam,
                                    setQueryParam: clickParams.setQueryParam,
                                });
                                history.push(path);
                            }
                        }}
                    >
                        <Text searchTextValue={searchTextValue}>
                            {Array.isArray(displayValue)
                                ? displayValue.join(", ")
                                : displayValue}
                        </Text>
                    </div>
                );
            } else {
                return null;
            }
        },

        filters: isLoading ? undefined : allowFiltering ? filterOptions : null,
        filterSearch: false,
        filteredValue: filteredInfo?.[key] || null,
        filterIcon: (
            <HeaderIcons
                styles={styles}
                isLoading={isLoading}
                showFilteringIcon={true}
                showSortingIcon={true}
            />
        ),
        filterDropdown: isLoading ? <> </> : undefined,
        sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
        sorter: isLoading ? undefined : allowSorting ? () => 0 : null,
    };
};

export default textColumn;
