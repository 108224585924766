import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { additionalColors } from "constants/colors";

const editModalBodyStyles = makeStyles(() => ({
    fieldRow: {
        height: "3vh",
    },
    label: {
        color: additionalColors.body54,
    },
    contactInfo: {
        paddingTop: "30px",
    },
}));

export const EditModalBody = ({
    isLoading = false,
    isRemoveModeActive,
    removalMessage,
    items,
}) => {
    const classes = editModalBodyStyles();

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <div>
                    {isRemoveModeActive && <p>{removalMessage}</p>}
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {items &&
                            items.map((item, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        className={classes.fieldRow}
                                    >
                                        <Grid className={classes.label} item xs={3}>
                                            {item.label}
                                        </Grid>
                                        <Grid item xs={9}>
                                            {item.value}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </div>
            )}
        </>
    );
};
