import PDFView from "components/atoms/PDFView";
import { getColorForIndicator } from "components/cdv/TagsList/utils";
import PDFTag from "components/molecules/PDFTag";
import { additionalColors } from "constants/colors";
import { IndicatorGroup } from "reactQuery/hooks/apiTypes";
import { IndicatorFeedItem } from "types/additionalTypes";

const PDFTagsList = ({
    group,
    showTagsInitiativeTypes = true,
    indicatorType,
    tags,
}: {
    group?: IndicatorGroup | IndicatorFeedItem;
    showTagsInitiativeTypes?: boolean;
    indicatorType: string;
    tags?: string[];
}) => {
    const color = group?.indicator && getColorForIndicator(group.indicator);

    return (
        <PDFView
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
            }}
        >
            {indicatorType === "indicatorGroup" ? (
                <>
                    {group.indicator?.industry ? (
                        <PDFTag color={color} level={0}>
                            {group.indicator.industry}
                        </PDFTag>
                    ) : group.indicator?.category ? (
                        <PDFTag color={color} level={0}>
                            {group.indicator.category}
                        </PDFTag>
                    ) : null}

                    <PDFTag color={color} level={1}>
                        {group.indicator?.value}
                    </PDFTag>

                    {showTagsInitiativeTypes ? (
                        <>
                            {group.indicator_tags.map((indicator_tag, index) => {
                                return (
                                    <PDFTag key={index} color={color} level={2}>
                                        {indicator_tag?.value}
                                    </PDFTag>
                                );
                            })}
                            {group.initiative_types.map((initiative_type, index) => {
                                return (
                                    <PDFTag key={index} color={color} level={2}>
                                        {initiative_type?.value}
                                    </PDFTag>
                                );
                            })}
                        </>
                    ) : null}
                </>
            ) : indicatorType === "teamCustomerTags" ? (
                <>
                    {tags.map((tag: string, index: number) => {
                        return (
                            <PDFTag
                                key={index}
                                color={additionalColors.grey300}
                                level={0}
                            >
                                {tag}
                            </PDFTag>
                        );
                    })}
                </>
            ) : null}
        </PDFView>
    );
};

export default PDFTagsList;
