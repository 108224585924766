import { useMediaQuery } from "@material-ui/core";
import { Button, Input, Space } from "antd";
import { useEffect, useState, ReactNode, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Text, StyledButton } from "components/atoms";
import { SelectedInsightsButton } from "components/cdv/SelectedInsightsButton";
import ShareOpportunitiesModal from "components/cdv/ShareOpportunitiesModal";
import { SyncOpportunitiesToCRMModal } from "components/cdv/SyncOpportunitiesToCRMModal";
import { FEED_TYPES } from "constants/feedTypes";
import { PaginatedTableInsight } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { useManagedQueryParams } from "utils/useManagedQueryParams";

import expandableRowOptions from "./expandableRow";
import { getColumns } from "./getColumns";
import { StyledTable, useStyles } from "./styles";
import { CombinedTableInsightRecord, PaginatedTableInsightRecord } from "./types";
import { useTableDependencies } from "./useTableDependencies";

const PaginatedTable = ({
    contentId,
    feedType,
    teamId,
}: {
    contentId?: number;
    feedType: string;
    teamId: number;
}) => {
    const getWhoamiQuery = useGetWhoami();
    const isStaff = getWhoamiQuery.isSuccess
        ? getWhoamiQuery.data.user.is_staff
        : false;

    const history = useHistory();
    const [recordsCount, setRecordsCount] = useState<number>();
    const [searchDisplayValue, setSearchDisplayValue] = useState<string>(null);
    const searchTextValueRef = useRef<string>(null);
    const [selectedRows, setSelectedRows] = useState<CombinedTableInsightRecord[]>([]);
    const [shareOpportunitiesModalVisibility, setShareOpportunitiesModalVisibility] =
        useState<boolean>(false);
    const [
        SyncOpportunitiesToCRMModalVisibility,
        setSyncOpportunitiesToCRMModalVisibility,
    ] = useState<boolean>(false);
    const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);

    const styles = useStyles();

    const {
        defaultPagination,
        filteredInfo,
        paginatedInfo,
        searchTextValue,
        sortedInfo,
        updateQuery,
    } = useManagedQueryParams();
    searchTextValueRef.current = searchTextValue;

    const { dataQuery, filtersQuery, relevantId, tableOptions } = useTableDependencies({
        feedType,
        contentId,
        teamId,
        filtersEnabled,
    });
    const filters = filtersQuery?.data;
    const isLoadingFilters = filtersQuery?.isLoading;

    const data: PaginatedTableInsightRecord[] = (dataQuery.data?.results ?? []).map(
        (insight: PaginatedTableInsight) => ({
            key: insight.id,
            ...insight,
        })
    );
    const isLoadingData = dataQuery?.isLoading;

    useEffect(() => {
        if (dataQuery.isSuccess) {
            setRecordsCount(dataQuery.data?.count);
        }
    }, [dataQuery.data, setRecordsCount]);

    useEffect(() => {
        setSearchDisplayValue(searchTextValue);
    }, [searchTextValue]);

    const clearFilters = () => {
        updateQuery({
            updatedFilters: {},
        });
    };

    const clearSearch = () => {
        updateQuery({
            updatedSearchText: "",
        });
        setSearchDisplayValue(null);
    };

    const clearSelection = () => {
        setSelectedRows([]);
    };

    const clearSorters = () => {
        updateQuery({
            updatedSorters: {},
            includeExistingParams: false,
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (pagination.current !== paginatedInfo.current) {
            setSelectedRows([]);
        }
        updateQuery({
            updatedFilters: filters,
            updatedPagination: pagination,
            updatedSearchText: searchTextValueRef.current,
            updatedSorters: sorter,
        });
    };

    const onSelectChanged = (
        _newSelectedRowKeys: React.Key[],
        newSelectedRows: PaginatedTableInsightRecord[]
    ) => {
        setSelectedRows(newSelectedRows);
    };

    const search = (searchText: string) => {
        updateQuery({
            updatedSearchText: searchText || null,
        });
    };

    const expandedRowKeys =
        data && data.length > 0 ? data.map((record) => record.key) : [];
    const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const mobileColumns = ["state_code", "opportunity_owner"];

    const columns = getColumns({
        filteredInfo,
        filters,
        history,
        isLoadingFilters,
        searchTextValue,
        sortedInfo,
        styles,
        tableOptions,
    });

    return (
        <>
            <ShareOpportunitiesModal
                handleClose={() => setShareOpportunitiesModalVisibility(false)}
                opportunityIds={selectedRows.map((insight) => insight.opportunity_id)}
                opportunityCount={selectedRows.length}
                reportId={feedType === FEED_TYPES.SINGLE_REPORT ? relevantId : null}
                teamId={teamId}
                visible={shareOpportunitiesModalVisibility}
            />

            <SyncOpportunitiesToCRMModal
                handleClose={() => setSyncOpportunitiesToCRMModalVisibility(false)}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                visible={SyncOpportunitiesToCRMModalVisibility}
                teamId={teamId}
                feedType={feedType}
                indicatorGroupId={
                    feedType === FEED_TYPES.INDICATOR_FEED ? relevantId : null
                }
            />

            {filtersQuery.isSuccess ? (
                <>
                    {recordsCount !== undefined ? (
                        <div className={styles.filtersContainer}>
                            <Text>
                                {recordsCount === 1
                                    ? `${recordsCount} ${
                                          tableOptions.recordTitle ?? "record"
                                      } found.`
                                    : `${recordsCount} ${
                                          tableOptions.recordTitlePlural
                                              ? tableOptions.recordTitlePlural
                                              : tableOptions.recordTitle
                                              ? `${tableOptions.recordTitle}s`
                                              : "records"
                                      } found.`}
                            </Text>
                        </div>
                    ) : null}
                    <Space className={styles.filtersContainer}>
                        {tableOptions.bulkActions ? (
                            <>
                                <SelectedInsightsButton
                                    selectedCount={selectedRows.length}
                                    disabled={!selectedRows.length}
                                    teamId={teamId}
                                    shareOpportunitiesOnClick={() =>
                                        setShareOpportunitiesModalVisibility(true)
                                    }
                                    syncOpportunitiesToCRMOnClick={() =>
                                        setSyncOpportunitiesToCRMModalVisibility(true)
                                    }
                                />
                                <Button onClick={clearSelection}>
                                    Clear Selection
                                </Button>
                            </>
                        ) : null}
                        <Button onClick={clearFilters}>Clear Filters</Button>
                        <Button onClick={clearSorters}>Clear Sorters</Button>
                    </Space>
                    <Button
                        onClick={clearSearch}
                        style={{ float: "right", marginLeft: 5 }}
                    >
                        Clear Search
                    </Button>
                    <Input.Search
                        data-testid="search"
                        style={{ margin: "0 0 10px 0", width: 304, float: "right" }}
                        placeholder="Search by..."
                        enterButton
                        allowClear
                        onSearch={search}
                        onChange={(e) =>
                            e.target.value
                                ? setSearchDisplayValue(e.target.value)
                                : clearSearch()
                        }
                        value={searchDisplayValue}
                    />
                </>
            ) : feedType === FEED_TYPES.ALL_INSIGHTS && isStaff && !filtersEnabled ? (
                <div className={styles.loadTableFilters}>
                    <StyledButton
                        variant="add-primary"
                        handleClick={() => {
                            setFiltersEnabled(true);
                        }}
                    >
                        Load Table Filters
                    </StyledButton>
                </div>
            ) : isLoadingFilters ? (
                <Text>
                    <span className={styles.loading}>
                        Loading filters, sorting and search
                    </span>
                </Text>
            ) : null}
            <StyledTable
                key="opportunity"
                columns={
                    isSmallScreen
                        ? columns.filter((column) => mobileColumns.includes(column.key))
                        : columns
                }
                loading={{
                    spinning: isLoadingData,
                }}
                expandable={
                    tableOptions.expandableRow
                        ? expandableRowOptions({
                              relevantId,
                              expandedRowKeys,
                              feedType,
                              history,
                              insightTagTitle: tableOptions.insightTagTitle,
                              isStaff,
                              styles,
                              teamId,
                              buttonParams:
                                  tableOptions.clickParams?.expandableRowButton,
                          })
                        : {
                              expandedRowRender: () => <Space size={20}></Space>,
                              defaultExpandAllRows: true,
                              expandedRowKeys: expandedRowKeys,
                          }
                }
                dataSource={data}
                locale={{
                    emptyText:
                        !isLoadingData && tableOptions.emptyDataDisplay
                            ? tableOptions.emptyDataDisplay
                            : null,
                }}
                onChange={handleTableChange}
                tableLayout={"auto"}
                pagination={{
                    current: paginatedInfo.current ?? defaultPagination.current,
                    pageSize: paginatedInfo.pageSize ?? defaultPagination.pageSize,
                    pageSizeOptions: defaultPagination.pageSizeOptions,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    total: recordsCount,
                }}
                rowSelection={
                    tableOptions.bulkActions
                        ? {
                              selectedRowKeys: selectedRows.map(
                                  (insight) => insight.key
                              ),
                              type: "checkbox",
                              onChange: onSelectChanged,
                              renderCell(
                                  _checked: boolean,
                                  record: CombinedTableInsightRecord,
                                  _index: number,
                                  node: ReactNode
                              ) {
                                  return (
                                      <span
                                          data-testid={`row-selection-row-${record.key}`}
                                      >
                                          {node}
                                      </span>
                                  );
                              },
                          }
                        : null
                }
            />
        </>
    );
};

export default PaginatedTable;
