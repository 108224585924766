import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ReactNode } from "react";

import { additionalColors, brandColors } from "constants/colors";
import { serverUrl } from "network";

const buttonStyles = makeStyles((theme: any) => ({
    addPrimaryButton: {
        backgroundColor: brandColors.darkBlue,
        color: additionalColors.white,
        whiteSpace: "nowrap",
    },
    addSecondaryButton: {
        color: theme.palette.primary.main,
        whiteSpace: "nowrap",
    },
    editSecondaryButton: {
        border: "2px solid",
        color: additionalColors.grey999,
        whiteSpace: "nowrap",
    },
    editPrimaryButton: {
        color: additionalColors.white,
        backgroundColor: brandColors.lightBlue,
        whiteSpace: "nowrap",
    },
    pageActionButton: {
        color: brandColors.darkBlue,
        border: "1px solid",
        whiteSpace: "nowrap",
    },
    adminButton: {
        marginTop: "15px",
        width: "100%",
        whiteSpace: "nowrap",
    },
    collapseButton: {
        float: "right",
        border: `2px solid ${additionalColors.grey300}`,
        fontWeight: 600,
        whiteSpace: "nowrap",
    },
    logoutButton: {
        color: "white",
        whiteSpace: "nowrap",
    },
    loginButton: {
        width: "100%",
        whiteSpace: "nowrap",
    },
    default: {
        fontFamily: "Montserrat",
        float: "right",
        fontWeight: 600,
        whiteSpace: "nowrap",
    },
}));

interface BaseButtonProps extends Omit<ButtonProps, "component" | "to"> {
    children: ReactNode;
    className?: string;
    icon?: ReactNode;
    component?: any;
    to?: string;
}

const BaseButton = ({
    children,
    className = null,
    icon = null,
    ...rest
}: BaseButtonProps) => {
    const styles = buttonStyles();
    const joinedStyle = clsx(className ? className : "", styles.default);
    return (
        <Button className={joinedStyle} {...rest}>
            {icon ? (
                <div style={{ marginRight: "10px", display: "flex" }}>{icon}</div>
            ) : null}
            {children}
        </Button>
    );
};

export const StyledButton = ({
    children,
    className = null,
    handleClick = null,
    variant = null,
    icon = null,
    url = null,
    ...rest
}) => {
    const styles = buttonStyles();
    if (variant == "add-primary") {
        return (
            <BaseButton
                className={styles.addPrimaryButton}
                onClick={handleClick}
                variant="contained"
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "add-secondary") {
        return (
            <BaseButton
                className={styles.addSecondaryButton}
                onClick={handleClick}
                variant="contained"
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "edit-secondary") {
        return (
            <BaseButton
                className={styles.editSecondaryButton}
                onClick={handleClick}
                variant="outlined"
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "edit-primary") {
        return (
            <BaseButton
                className={styles.editPrimaryButton}
                onClick={handleClick}
                variant="contained"
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "admin-link") {
        return (
            <BaseButton
                className={styles.adminButton}
                onClick={handleClick}
                href={`${serverUrl}/${url}`}
                color="primary"
                variant="contained"
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "collapse-button") {
        return (
            <BaseButton
                className={styles.collapseButton}
                onClick={handleClick}
                color="primary"
                variant="outlined"
                icon={icon}
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "logout-button") {
        return (
            <BaseButton
                className={styles.logoutButton}
                onClick={handleClick}
                color="primary"
                variant="contained"
                icon={icon}
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "login-button") {
        return (
            <BaseButton
                className={styles.loginButton}
                onClick={handleClick}
                color="primary"
                variant="contained"
                icon={icon}
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else if (variant == "page-action") {
        return (
            <BaseButton
                className={styles.pageActionButton}
                onClick={handleClick}
                color="primary"
                variant="outlined"
                icon={icon}
                {...rest}
            >
                {children}
            </BaseButton>
        );
    } else
        return (
            <BaseButton
                className={className}
                onClick={handleClick}
                icon={icon}
                {...rest}
            >
                {children}
            </BaseButton>
        );
};

export default StyledButton;
